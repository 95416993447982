<div class="page px-5">

    <div class="page__headline"
         eciTranslate="additionalServices.title">
        Additional Service
    </div>

    <div class="page__filter mb-2">
        <div class="row">
            <span>Filter: </span>
            <div class="col-lg-3">
                <dx-tag-box [placeholder]="'additionalServices.table.headers.country' | translate"
                            [dataSource]="countriesStore"
                            [valueExpr]="'id'"
                            [displayExpr]="'name'"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedCountries"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <dx-tag-box [placeholder]="'additionalServices.table.headers.warehouses' | translate"
                            [dataSource]="warehouseStore"
                            [valueExpr]="'warehouseId'"
                            [displayExpr]="'name'"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedWarehouses"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <dx-tag-box [placeholder]="'additionalServices.table.headers.forwardAdditionalServices' | translate"
                            [dataSource]="forwardAdditionalServiceStore"
                            [valueExpr]="'value'"
                            [displayExpr]="'text'"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedForwardAdditionalServices"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="onRowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: false, paging: true }"
                          [rowAlternationEnabled]="false"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop">
                </dxo-column-chooser>
                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing *eciHasAnyAuthority="Privilege.WriteAdditionalService"
                             [allowUpdating]="true"
                             [allowDeleting]="true"
                             [allowAdding]="true"
                             [useIcons]="true"
                             mode="form">

                    <dxo-form [colCount]=1 [labelMode]="labelMode">
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="carrierProductId"></dxi-item>
                            <dxi-item dataField="warehouses"></dxi-item>
                            <dxi-item dataField="forwardAdditionalServices"></dxi-item>
                            <dxi-item dataField="returnAdditionalServices"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother" class="me-3"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary me-2"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary me-2"
                                            role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            caption="{{ 'additionalServices.table.headers.id' | translate }}"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            [setCellValue]="resetCarrierProductCell"
                            [width]="170"
                            dataField="countryId"
                            sortOrder="asc"
                            caption="{{ 'additionalServices.table.headers.country' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countriesStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierProductFilterExpression"
                            dataField="carrierProductId"
                            caption="{{ 'additionalServices.table.headers.carrierProduct' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateWarehouseFilterExpression"
                            [caption]="'additionalServices.table.headers.warehouses' | translate"
                            editCellTemplate="warehouseTagBoxEditor"
                            cellTemplate="warehousesCellTemplate"
                            dataField="warehouses">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="warehouseStore"
                                displayExpr="name"
                                valueExpr="warehouseId">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateForwardServiceFilterExpression"
                            [caption]="'additionalServices.table.headers.forwardAdditionalServices' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            editCellTemplate="forwardAdditionalServiceTagBoxEditor"
                            cellTemplate="forwardAdditionalServiceCellTemplate"
                            dataField="forwardAdditionalServices">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="forwardAdditionalServiceStore"
                                displayExpr="text"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'additionalServices.table.headers.returnAdditionalServices' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            [allowHeaderFiltering]="false"
                            [visible]="false"
                            dataField="returnAdditionalServices">
                    <dxo-lookup [dataSource]="returnAdditionalServiceStore"
                                displayExpr="text"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

                <div *dxTemplate="let cellInfo of 'warehouseTagBoxEditor'">
                    <dx-tag-box
                        [placeholder]="'additionalServices.table.headers.warehouses' | translate"
                        [dataSource]="warehouseStore"
                        [showSelectionControls]="true"
                        [value]="cellInfo.value"
                        [valueExpr]="'warehouseId'"
                        [displayExpr]="'name'"
                        (onValueChanged)="cellInfo.setValue($event.value)"
                        applyValueMode="useButtons"
                        [searchEnabled]="true">
                    </dx-tag-box>
                </div>

                <div *dxTemplate="let cellInfo of 'forwardAdditionalServiceTagBoxEditor'">
                    <dx-tag-box
                        [placeholder]="'additionalServices.table.headers.forwardAdditionalServices' | translate"
                        [dataSource]="forwardAdditionalServiceStore"
                        [showSelectionControls]="true"
                        [value]="cellInfo.value"
                        [valueExpr]="'value'"
                        [displayExpr]="'text'"
                        (onValueChanged)="cellInfo.setValue($event.value)"
                        applyValueMode="useButtons"
                        [searchEnabled]="true">
                    </dx-tag-box>
                </div>

                <div *dxTemplate="let cellInfo of 'forwardAdditionalServiceCellTemplate'">
                    <ul style="list-style-type: none;margin: 0">
                        <li *ngFor="let item of cellInfo.data['forwardAdditionalServices']">
                            <h5 style="margin: 0;font-size: 15px"><span class="badge rounded-pill bg-success">{{ item }}</span></h5>
                        </li>
                    </ul>
                </div>
                <div *dxTemplate="let cellInfo of 'warehousesCellTemplate'">
                    <ul style="list-style-type: none;margin: 0">
                        <li *ngFor="let item of cellInfo.data['warehouses']">
                            <h5 style="margin: 0;font-size: 15px"><span class="badge rounded-pill bg-secondary">{{ item }}</span></h5>
                        </li>
                    </ul>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
