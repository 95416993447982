<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="carrierDowntime.title">
        Carrier Downtime Configuration
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="rowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onEditingStart)="editRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="datasource"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [hoverStateEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"
                           [allowedPageSizes]="allowedPageSizes" [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteCarrierDowntime"
                    mode="form"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">
                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item dataField="startDate"></dxi-item>
                            <dxi-item dataField="endDate"></dxi-item>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            [caption]="'carrierDowntime.table.headers.id' | translate"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column dataField="carrierId"
                            [allowEditing]="editable"
                            [caption]="'carrierDowntime.table.headers.carrier' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="carrierStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="startDate" sortOrder="desc" dataType="datetime"
                            [caption]="'carrierDowntime.table.headers.startDate' | translate"
                            [setCellValue]="resetEndDateCell">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule
                        type="custom"
                        [reevaluate]="true"
                        [validationCallback]="startInTheFuture"
                        message="Downtime start must be in the future"
                    ></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="endDate" sortOrder="desc" dataType="datetime"
                            [caption]="'carrierDowntime.table.headers.endDate' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule
                        type="custom"
                        [reevaluate]="true"
                        [validationCallback]="endAfterStart"
                        message="Downtime end must be after downtime start"
                    ></dxi-validation-rule>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt"></dxi-button>
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>
