import { Component, OnInit } from '@angular/core';
import { CommonView } from '../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Privilege } from '../shared/models/privilege.enum';
import { ShipmentDirection } from '../shared/models/shipment-direction.model';
import { DxLookupDataSourceOptions, DxUtil } from '../shared/util/dx.util';
import { CarrierProductAvailability } from '../shared/models/carrier-product-availability.model';
import { CarrierProductsAvailabilityService } from './carrier-products-availability.service';
import { CarrierProductsService } from '../carrier-products/carrier-products.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { CountriesService } from '../countries/countries.service';
import { CarrierDeterminationService } from '../carrier-determination/carrier-determination.service';
import { CommonService } from '../shared/util/common.service';
import { Country } from '../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { CarrierProduct } from '../shared/models/carrier-product.model';

@Component({
    selector: 'kep-carrier-products-availability',
    templateUrl: './carrier-products-availability.component.html',
})
export class CarrierProductsAvailabilityComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    carrierProductsStore: CustomStore;
    countriesStore: CustomStore;
    warehousesStore: CustomStore;
    deliveryOptionsStore: CustomStore;
    forwardDeliveryOptionsStore: CustomStore;
    returnDeliveryOptionsStore: CustomStore;
    directions: ShipmentDirection[];
    salesChannelStore: CustomStore;
    deliveryOptionEditor;
    carrierProductEditor;
    selectedCarrierProductId: string;
    selectedDirection: string;

    resetCarrierProductCell = DxUtil.hookSetCellValue<CarrierProductAvailability>(rowData => {
        rowData.carrierProductId = null;
    });

    resetDeliveryOptionCell = DxUtil.hookSetCellValue<CarrierProductAvailability>(rowData => {});

    constructor(
        private carrierProductsAvailabilityService: CarrierProductsAvailabilityService,
        private carrierProductsService: CarrierProductsService,
        private warehouseService: WarehouseService,
        private countriesService: CountriesService,
        private carrierDeterminationService: CarrierDeterminationService,
        private commonService: CommonService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.carrierProductsAvailabilityService.getCarrierProductsAvailability();
        this.warehousesStore = this.warehouseService.getWarehouseAsCustomStore();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
        this.carrierProductsStore = this.carrierProductsService.getCarrierProductsAsCustomStore();
        this.deliveryOptionsStore = this.carrierDeterminationService.getDeliveryOptions();
        this.forwardDeliveryOptionsStore = this.carrierProductsService.getForwardDeliveryOptions();
        this.returnDeliveryOptionsStore = this.carrierProductsService.getReturnDeliveryOptions();
        this.salesChannelStore = this.commonService.getCustomSalesChannels();
        this.directions = this.commonService.getShipmentDirection();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
        this.selectedCarrierProductId = null;
        this.selectedDirection = null;
    }

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<CarrierProductAvailability>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierProductAvailability, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    getFilteredDeliveryOptions = (options: DxLookupDataSourceOptions<CarrierProductAvailability>) => {
        if (options.data == null) {
            return { store: this.deliveryOptionsStore };
        }

        const carrierProduct =
            DxUtil.fetchLookupObject<CarrierProductAvailability, CarrierProduct>(options, 'carrierProductId');
        const direction =
            DxUtil.fetchLookupObject<CarrierProductAvailability, ShipmentDirection>(options, 'direction');
        let storeToUse = this.deliveryOptionsStore;

        if (direction != null) {
            if (direction.name === 'FORWARD') {
                storeToUse = this.forwardDeliveryOptionsStore;
            }
            if (direction.name === 'RETURN') {
                storeToUse = this.returnDeliveryOptionsStore;
            }
        }

        const filter = carrierProduct ? DxFilter.or(
            DxFilter.equals('value', carrierProduct.forwardDeliveryOption),
            DxFilter.equals('value', carrierProduct.returnDeliveryOption),
        ) : null;

        return {
            store: storeToUse,
            filter,
        };
    };

    onEditorPreparing(e) {
        e.editorOptions.onValueChanged = data => {
            e.setValue(data.value);
            this.isDirty = true;
        };

        if (e.parentType === 'dataRow' && e.dataField === 'deliveryOption') {
            this.deliveryOptionEditor = e;
        }

        if (e.parentType === 'dataRow' && e.dataField === 'carrierProductId') {
            this.carrierProductEditor = e;
            e.editorOptions.onValueChanged = data => {
                e.setValue(data.value);
                this.isDirty = true;
                this.selectedCarrierProductId = data.value;
                this.updatedDeliveryOption();
            };
        }

        if (e.parentType === 'dataRow' && e.dataField === 'direction') {
            e.editorOptions.onValueChanged = data => {
                e.setValue(data.value);
                this.isDirty = true;
                this.selectedDirection = data.value;
                this.updatedDeliveryOption();
            };
        }
    }

    onEditingStart(e) {
        this.selectedCarrierProductId = e.data.carrierProductId;
        this.selectedDirection = e.data.direction;
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.id', filterOperation, filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    updatedDeliveryOption() {
        if (this.selectedCarrierProductId != null) {
            const carrierProduct = this.carrierProductEditor.lookup.items
                .filter(carrierProductItem => carrierProductItem.id === this.selectedCarrierProductId)[0];
            if (this.selectedDirection === 'FORWARD') {
                this.deliveryOptionEditor.setValue(carrierProduct.forwardDeliveryOption);
            }
            if (this.selectedDirection === 'RETURN') {
                this.deliveryOptionEditor.setValue(carrierProduct.returnDeliveryOption);
            }
        }

    }
}
