import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../shared/util/common.service';
import CustomStore from 'devextreme/data/custom_store';
import { firstValueFrom } from 'rxjs';
import { DxUtil } from '../shared/util/dx.util';

@Injectable()
export class PackagingMaterialsService {

    private readonly packagingMaterialsResourceUrl = 'api/packaging-materials';

    constructor(
        private http: HttpClient,
        private commonService: CommonService,
        private dxService: EciDxService,
    ) {
    }

    getPackagingMaterials(): DataSource {
        return this.commonService.getDataSource(this.packagingMaterialsResourceUrl);
    }

    getPackagingMaterialAsCustomStore(): CustomStore {
        return new CustomStore({
            key: 'id',
            load: loadOptions => this.dxService.query(this.packagingMaterialsResourceUrl, DxUtil.loadAll(loadOptions))
                .then(x => x.data),
            byKey: id => this.http.get(`${this.packagingMaterialsResourceUrl}/${id}`).toPromise(),
        });
    }

    getCustomPackageTypes(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>('/api/packaging-materials/packageTypes'))
                .then(data => data.map(value => {
                    return { value };
                })),
        });
    }

}
