import { DateUtil } from '../shared/util/date.util';
import { CommonView } from '../shared/util/common-view.util';
import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Privilege } from '../shared/models/privilege.enum';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { CptService } from './cpt.service';
import { CarriersService } from '../carriers/carriers.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { CountriesService } from '../countries/countries.service';
import { CarrierProductsService } from '../carrier-products/carrier-products.service';
import { Cpt } from '../shared/models/cpt.model';
import { Country } from '../shared/models/country.model';
import { Carrier } from '../shared/models/carrier.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { CarrierPercentage } from '../shared/models/carrier-percentage.model';

@Component({
    selector: 'kep-cpt',
    templateUrl: './cpt.component.html',
})
export class CptComponent extends CommonView {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    dataSource: DataSource;
    carrierStore: CustomStore;
    warehouseStore: CustomStore;
    countryStore: CustomStore;
    carrierProductStore: CustomStore;

    resetCarrierProductCell = DxUtil.hookSetCellValue<CarrierPercentage>(rowData => {
        rowData.carrierProductId = null;
    });

    constructor(
        private cptService: CptService,
        private carrierService: CarriersService,
        private warehouseService: WarehouseService,
        private countryService: CountriesService,
        private carrierProductService: CarrierProductsService,
    ) {
        super();
        this.dataSource = this.cptService.getCpt();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.countryStore = this.countryService.getCountriesAsCustomStore();
        this.carrierProductStore = this.carrierProductService.getCarrierProductsAsCustomStore();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    rowUpdate(e: DxRowUpdateEvent) {
        DxUtil.fillUpNewDataOnUpdate(e);

        e.newData.cptMonday = this.formatDate(e.newData.cptMonday);
        e.newData.cptTuesday = this.formatDate(e.newData.cptTuesday);
        e.newData.cptWednesday = this.formatDate(e.newData.cptWednesday);
        e.newData.cptThursday = this.formatDate(e.newData.cptThursday);
        e.newData.cptFriday = this.formatDate(e.newData.cptFriday);
        e.newData.cptSaturday = this.formatDate(e.newData.cptSaturday);
        e.newData.cptSunday = this.formatDate(e.newData.cptSunday);
    }

    rowInsert(e) {
        e.data.cptMonday = this.formatDate(e.data.cptMonday);
        e.data.cptTuesday = this.formatDate(e.data.cptTuesday);
        e.data.cptWednesday = this.formatDate(e.data.cptWednesday);
        e.data.cptThursday = this.formatDate(e.data.cptThursday);
        e.data.cptFriday = this.formatDate(e.data.cptFriday);
        e.data.cptSaturday = this.formatDate(e.data.cptSaturday);
        e.data.cptSunday = this.formatDate(e.data.cptSunday);
    }

    formatDate(date) {
        if (date === undefined || date === null) {
            return null;
        }
        if (typeof date === 'string') {
            return date;
        }
        return ('00' + date.getHours()).slice(-2)
            + ':' + ('00' + date.getMinutes()).slice(-2)
            + ':' + ('00' + date.getSeconds()).slice(-2);
    }

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<Cpt>) => {
        if (options.data == null) {
            return { store: this.carrierProductStore };
        }

        const country = DxUtil.fetchLookupObject<Cpt, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<Cpt, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductStore,
            filter,
        };
    };

    getFilteredCarriers = (options: DxLookupDataSourceOptions<Cpt>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<Cpt, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.carrier.id', filterOperation, filterValue];
    }

    cloneRow(e) {
        e.row.data.id = null;
        this.currentRowData = Object.assign({}, e.row.data);
        this.currentRowData.cptMonday = DateUtil.convertToDate(e.row.data.cptMonday);
        this.currentRowData.cptTuesday = DateUtil.convertToDate(e.row.data.cptTuesday);
        this.currentRowData.cptWednesday = DateUtil.convertToDate(e.row.data.cptWednesday);
        this.currentRowData.cptThursday = DateUtil.convertToDate(e.row.data.cptThursday);
        this.currentRowData.cptFriday = DateUtil.convertToDate(e.row.data.cptFriday);
        this.currentRowData.cptSaturday = DateUtil.convertToDate(e.row.data.cptSaturday);
        this.currentRowData.cptSunday = DateUtil.convertToDate(e.row.data.cptSunday);
        this.dataGrid.instance.addRow();
        this.currentRowData = null;
        // When cloning the first element of the grid, the editor does not open and a refresh is needed
        if (e.row.rowIndex === 0) {
            this.dataGrid.instance.refresh();
        }
    }
}
