<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="audits.title">
        Audits
    </div>

    <div class="row justify-content-center">

        <div class="col-sm-12">

            <dx-data-grid [allowColumnReordering]="true" [allowColumnResizing]="true" [cellHintEnabled]="true"
                          [dataSource]="dataSource" [hoverStateEnabled]="true" [showBorders]="true"
                          [showColumnLines]="true" [showRowLines]="true" [wordWrapEnabled]="true"
                          [columnAutoWidth]="true"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          (onRowUpdating)="rowUpdate($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)">

                <dxo-header-filter [visible]="false" [allowSearch]="false"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>

                <div *dxTemplate="let data of 'gridHeader'">
                </div>

                <dxi-column dataField="entity" [width]="300"
                            [caption]="'audits.table.headers.entity' | translate">
                </dxi-column>

                <dxi-column dataField="operation" [width]="300"
                            [caption]="'audits.table.headers.operation' | translate">
                </dxi-column>

                <dxi-column dataField="modifiedBy" [width]="300"
                            [caption]="'audits.table.headers.modifiedBy' | translate">
                </dxi-column>

                <dxi-column dataField="created" sortOrder="desc" dataType="datetime" [width]="300"
                            [caption]="'audits.table.headers.created' | translate">
                </dxi-column>

                <dxi-column dataField="payload"
                            [caption]="'audits.table.headers.payload' | translate">
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>
