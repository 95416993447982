import { Injectable } from '@angular/core';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class DeterminationRatioService {

    private readonly resourceUrl = 'api/determination-ratio';

    constructor(
        private dxService: EciDxService,
    ) { }

    getDeterminationRatios(): DataSource {
        return this.dxService.createEditableDataSource(this.resourceUrl);
    }
}
