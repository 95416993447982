import { CommonView } from '../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../shared/models/privilege.enum';
import { AuditsService } from './audits.service';

@Component({
    selector: 'kep-audits',
    templateUrl: './audits.component.html',
})
export class AuditsComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    dataSource: DataSource;

    constructor(private auditsService: AuditsService) {
        super();
        this.dataSource = this.auditsService.getAudits();
    }

    ngOnInit() {
    }

}
