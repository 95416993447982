
<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="backupShippingTypeAddresses.title">
        Backup Shipping Type Addresses
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="onRowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteBackupShippingTypeAddress"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true"
                    [useIcons]="true"
                    mode="form">

                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="warehouseId"></dxi-item>
                            <dxi-item dataField="originalCarrierId"></dxi-item>
                            <dxi-item dataField="originalCarrierProductId"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item dataField="backupCarrierId"></dxi-item>
                            <dxi-item dataField="backupCarrierProductId"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate" [(value)]="createAnother"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary" role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary" role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true" class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                            eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            caption="{{ 'backupShippingTypeAddresses.table.headers.id' | translate }}"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateWarehouseFilterExpression"
                            dataField="warehouseId"
                            [setCellValue]="resetWarehouseCell"
                            [editorOptions]="{ showClearButton: true }"
                            caption="{{ 'backupShippingTypeAddresses.table.headers.warehouse' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="warehouseStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateOriginalCarrierFilterExpression"
                            [setCellValue]="resetOriginalCarrierProductCell"
                            dataField="originalCarrierId"
                            caption="{{ 'backupShippingTypeAddresses.table.headers.originalCarrier' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="carrierStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateOriginalCarrierProductFilterExpression"
                            dataField="originalCarrierProductId"
                            [editorOptions]="{ showClearButton: true }"
                            caption="{{ 'backupShippingTypeAddresses.table.headers.originalCarrierProduct' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredOriginalCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateBackupCarrierFilterExpression"
                            [setCellValue]="resetBackupCarrierProductCell"
                            dataField="backupCarrierId"
                            caption="{{ 'backupShippingTypeAddresses.table.headers.backupCarrier' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="carrierStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateBackupCarrierProductFilterExpression"
                            dataField="backupCarrierProductId"
                            [editorOptions]="{ showClearButton: true }"
                            caption="{{ 'backupShippingTypeAddresses.table.headers.backupCarrierProduct' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredBackupCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>


                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>
