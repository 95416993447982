import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { DxUtil } from '../../shared/util/dx.util';
import moment from 'moment';

@Injectable()
export class CurrencyExchangeRatesService {

    private readonly currencyExchangeRatesResourceUrl = 'api/currency-exchange-rates';

    constructor(
        private dxService: EciDxService,
    ) {
    }

    getCurrencyExchangeRates(): DataSource {
        const url = this.currencyExchangeRatesResourceUrl;
        return this.dxService.createEditableDataSource(url, 'id', {
            dataSource: {
                load: loadOptions => {
                    this.formatYearMonthFilter(loadOptions.filter);
                    return loadOptions.take !== undefined ?
                        this.dxService.query(url, loadOptions) :
                        this.dxService.query(url, DxUtil.loadAll(loadOptions))
                            .then((response: any) => {
                                return {
                                    data: response?.data,
                                    totalCount: response?.totalCount,
                                };
                            });
                },
            },
        });
    }

    formatYearMonthFilter(filter: any) {
        if (Array.isArray(filter) && filter[0] === 'validFrom') {
            filter[2] = moment(filter[2]).format('YYYY-MM');
            return;
        }
        if (Array.isArray(filter) && Array.isArray(filter[0])) {
            filter.forEach(nestedFilter => this.formatYearMonthFilter(nestedFilter));
        }
    }

}
