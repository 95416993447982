export class DateUtil {
    static convertToDate(dateString: string): Date {
        if (dateString !== null) {
            const date = new Date();
            date.setHours(Number.parseInt(dateString.slice(0, 2)),
                Number.parseInt(dateString.slice(3, 5)),
                Number.parseInt(dateString.slice(6, 8)));
            return date;
        } else {
            return null;
        }
    }
}
