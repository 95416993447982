import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { ArticleBrickCodeRulesComponent } from './article-brick-code-rules.component';

export const ARTICLE_BRICK_CODE_RULES_ROUTE: Route = {
    path: 'article-brick-code-rules',
    component: ArticleBrickCodeRulesComponent,
    data: {
        roles: [],
        pageTitle: 'articleBrickCodeRules.title',
    },
    canActivate: [EciAuthGuard],
};
