import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../../shared/util/common.service';
import CustomStore from 'devextreme/data/custom_store';

@Injectable()
export class SendItLabelAndClosureConfiguration {

    constructor(
        private dxService: EciDxService,
        private commonService: CommonService,
    ) {
    }

    getSendItLabelAndClosureConfigurationData(): DataSource {
        return this.commonService.getDataSource('/api/send-it-label-and-closure');
    }

    getSalesChannels(): CustomStore {
        return this.commonService.getCustomSalesChannels();
    }
}
