import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/util/common.service';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class CarrierSurchargesService {

    private readonly resourceUrl = 'api/carrier-surcharges';

    constructor(
        private http: HttpClient,
        private commonService: CommonService,
    ) {
    }

    getCarrierSurcharges(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getDimensions(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>(`${this.resourceUrl}/dimensions`))
                .then(data => data.map(value => {
                    return { value };
                })),
        });
    }

}
