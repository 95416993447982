<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline">
        Carrier Products Availability
    </div>

    <div class="row justify-content-center">

        <div class="col-sm-11">

            <dx-data-grid (onInitNewRow)="initNewRow($event)"
                          (onRowUpdating)="rowUpdate($event)"
                          (onEditingStart)="onEditingStart($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [rowAlternationEnabled]="true"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WritePostcode"
                    mode="form"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">

                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="direction"></dxi-item>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="zones"></dxi-item>
                            <dxi-item dataField="carrierProductId"></dxi-item>
                            <dxi-item dataField="warehouseId"></dxi-item>
                            <dxi-item dataField="salesChannel"></dxi-item>
                            <dxi-item dataField="deliveryOption"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary" role="button"
                                            type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <dx-button (onClick)="resetFilter()" text="{{ 'shared.buttons.resetFilter' | translate }}">
                    </dx-button>
                </div>

                <dxi-column [allowEditing]="false"
                            [allowHeaderFiltering]="false"
                            [caption]="'carrierProductsAvailability.table.headers.id' | translate"
                            [visible]="false"
                            dataField="id">
                </dxi-column>

                <dxi-column [caption]="'carrierProductsAvailability.table.headers.direction' | translate"
                            [setCellValue]="resetDeliveryOptionCell"
                            dataField="direction">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="directions"
                                displayExpr="name"
                                valueExpr="name">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'carrierProductsAvailability.table.headers.salesChannel' | translate"
                            dataField="salesChannel">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="salesChannelStore"
                                displayExpr="text"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            [setCellValue]="resetCarrierProductCell"
                            sortOrder="asc"
                            calculateSortValue="countryName"
                            [caption]="'carrierProductsAvailability.table.headers.country' | translate"
                            dataField="countryId">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countriesStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateZoneFilterExpression"
                            [caption]="'carrierProducts.table.headers.zones' | translate"
                            editCellTemplate="zoneTagBoxEditor"
                            cellTemplate="zonesCellTemplate"
                            dataField="zones">
                    <dxo-lookup [dataSource]="getFilteredZones"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateWarehouseFilterExpression"
                            calculateSortValue="warehouseName"
                            [caption]="'carrierProductsAvailability.table.headers.warehouse' | translate"
                            dataField="warehouseId">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="warehousesStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierProductFilterExpression"
                            [setCellValue]="resetDeliveryOptionCell"
                            [caption]="'carrierProductsAvailability.table.headers.carrierProduct' | translate"
                            dataField="carrierProductId">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'carrierProductsAvailability.table.headers.deliveryOption' | translate"
                            dataField="deliveryOption">
                    <dxo-lookup [dataSource]="getFilteredDeliveryOptions"
                                displayExpr="text"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

                <div *dxTemplate="let cellInfo of 'zoneTagBoxEditor'">
                    <dx-tag-box
                        [placeholder]="'carrierProducts.table.headers.zones' | translate"
                        [dataSource]="filteredZones"
                        [showSelectionControls]="true"
                        [value]="cellInfo.value"
                        [valueExpr]="'id'"
                        [displayExpr]="'name'"
                        (onValueChanged)="cellInfo.setValue($event.value)"
                        applyValueMode="useButtons"
                        [searchEnabled]="true">
                    </dx-tag-box>
                </div>

                <div *dxTemplate="let cellInfo of 'zonesCellTemplate'">
                    <ul style="list-style-type: none;margin: 0">
                        <li *ngFor="let item of cellInfo.data['zones']">
                            <h5 style="margin: 0;font-size: 15px"><span class="badge rounded-pill bg-secondary">{{ getZoneName(item) }}</span></h5>
                        </li>
                    </ul>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
