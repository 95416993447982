import { CommonView } from '../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { CarriersService } from '../carriers/carriers.service';
import { ArticleServicesRulesService } from './article-services-rules.service';
import { DxLookupDataSourceOptions, DxUtil } from '../shared/util/dx.util';
import { Privilege } from '../shared/models/privilege.enum';
import { CountriesService } from '../countries/countries.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { ArticleServicesRule } from '../shared/models/article-services-rule.model';
import { Country } from '../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';

@Component({
    selector: 'kep-article-services-rule',
    templateUrl: './article-services-rules.component.html',
})
export class ArticleServicesRulesComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    selectedCountries = [];
    selectedWarehouses = [];

    possibleCountries: CustomStore;
    possibleWarehouses: CustomStore;

    carrierStore: CustomStore;
    warehouseStore: CustomStore;
    countriesStore: CustomStore;

    articleNumberPattern: any = /^\d+$/i;

    resetWarehouseCell = DxUtil.hookSetCellValue<ArticleServicesRule>(rowData => {
        rowData.warehouseId = null;
    });

    constructor(
        private articleServicesRulesService: ArticleServicesRulesService,
        private carrierService: CarriersService,
        private warehouseService: WarehouseService,
        private countriesService: CountriesService,
    ) {
        super();
        this.reloadFilter();
     }

    ngOnInit() {
        this.dataSource = this.articleServicesRulesService.getArticleServicesRules();

        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    getFilteredCarriers = (options: DxLookupDataSourceOptions<ArticleServicesRule>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<ArticleServicesRule, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    onFilterChange() {
        this.dataGrid.instance.clearFilter();
        this.reloadFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('warehouseId', 'filterValues', this.selectedWarehouses);
    }

    resetFilter(): void {
        super.resetFilter();

        this.reloadFilter();
        this.selectedCountries = [];
        this.selectedWarehouses = [];
    }

    reloadFilter() {
        this.possibleCountries = this.articleServicesRulesService.getPossibleCountries(this.selectedWarehouses);
        this.possibleWarehouses = this.articleServicesRulesService.getPossibleWarehouses(this.selectedCountries);
    }
}
