import { CommonView } from '../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Privilege } from '../shared/models/privilege.enum';
import { CarriersService } from './carriers.service';
import { CountriesService } from '../countries/countries.service';

@Component({
    selector: 'kep-carriers',
    templateUrl: './carriers.component.html',
    styleUrls: ['./carriers.component.scss'],
})
export class CarriersComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    countriesStore: CustomStore;

    constructor(
        private carriersService: CarriersService,
        private countriesService: CountriesService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.carriersService.getCarriers();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }
}
