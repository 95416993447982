import { Injectable } from '@angular/core';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class FallBackCarriersService {

    private readonly resourceUrl = 'api/fall-back-carriers';

    constructor(
        private dxService: EciDxService,
    ) { }

    getFallBackCarriers(): DataSource {
        return this.dxService.createEditableDataSource(this.resourceUrl);
    }
}
