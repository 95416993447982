import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { PostCodeComponent } from './post-code.component';

export const POST_CODE_ROUTE: Route = {
    path: 'post-code-lists/:listId',
    component: PostCodeComponent,
    data: {
        roles: [],
        pageTitle: 'postalCodeLists.title',
    },
    canActivate: [EciAuthGuard],
};
