import { Component, OnInit } from '@angular/core';
import { CommonView } from '../../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../../shared/models/privilege.enum';
import { DxRowUpdateEvent, DxUtil } from '../../shared/util/dx.util';
import { TrackingService } from '../tracking.service';
import { TrackingEvent } from '../../shared/models/tracking-event.model';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'kep-tracking-event',
    templateUrl: './tracking-event.component.html',
})
export class TrackingEventComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    labelMode = 'floating';
    eventCustomStore: CustomStore;
    subEventCustomStore: CustomStore;

    selectedEvents = [];
    selectedSubEvents = [];

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    constructor(
        private trackingService: TrackingService,
    ) {
        super();
    }

    resetFilter(): void {
        super.resetFilter();

        this.selectedEvents = [];
        this.selectedSubEvents = [];
    }

    ngOnInit() {
        this.dataSource = this.trackingService.getTrackingEvents();
        this.eventCustomStore = this.trackingService.getAllEvents();
        this.reloadFilter();
    }

    onRowUpdate(e: DxRowUpdateEvent<TrackingEvent>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    onFilterChange() {
        this.dataGrid.instance.clearFilter();
        this.reloadFilter();

        this.dataGrid.instance.columnOption('event', 'filterValues', this.selectedEvents);
        this.dataGrid.instance.columnOption('subEvent', 'filterValues', this.selectedSubEvents);
    }

    reloadFilter() {
        if (this.selectedEvents.length === 0) {
            this.subEventCustomStore = this.trackingService.getAllSubEvents();
        } else {
            this.subEventCustomStore = this.trackingService.getAllSubEventsForEvent(this.selectedEvents);
        }
    }
}
