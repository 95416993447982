import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { ArticleServicesRulesComponent } from './article-services-rules.component';

export const ARTICLE_SERVICES_RULES_ROUTE: Route = {
    path: 'article-services-rules',
    component: ArticleServicesRulesComponent,
    data: {
        roles: [],
        pageTitle: 'articleServicesRules.title',
    },
    canActivate: [EciAuthGuard],
};
