import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../../shared/util/common.service';

@Injectable()
export class BackupShippingTypeAddressesService {

    private readonly resourceUrl = '/api/backup-shipping-type-addresses';

    constructor(private httpClient: HttpClient,
                private dxService: EciDxService,
                private commonService: CommonService) {
    }

    createDataSource(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

}
