import { CommonView } from '../../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { ClosureJobsService } from './closure-jobs.service';
import { DxRowButtonClickEvent, DxRowUpdateEvent, DxUtil } from '../../shared/util/dx.util';
import { Privilege } from '../../shared/models/privilege.enum';
import { CarriersService } from '../../carriers/carriers.service';
import { ClosureJob } from '../../shared/models/closure-job.model';
import { EciToastService } from '@shared-components/shopsys-commons-ui';
import { Service } from '../../shared/models/label-service.model';
import { LabelServiceConfig } from '../label-service-config/label-service-config.service';

@Component({
    selector: 'kep-closure-jobs',
    templateUrl: './closure-jobs.component.html',
})
export class ClosureJobsComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    service: Service[];

    dataSource: DataSource;
    detailDataSources: { [jobId: string]: DataSource } = {};

    carrierStore: CustomStore;

    constructor(
        private closureJobsService: ClosureJobsService,
        private carrierService: CarriersService,
        private toastService: EciToastService,
        private labelServiceConfig: LabelServiceConfig,
    ) {
        super();
     }

    ngOnInit() {
        this.dataSource = this.closureJobsService.createDataSource();

        this.carrierStore = this.carrierService.getCarriersAsCustomStore();

        this.service = this.labelServiceConfig.getLabelService();
    }

    onInitNewRow(e: { data: Partial<ClosureJob> }) {
        e.data = {
            cronSchedule: '0 0/10 0 ? * * *',
            active: true,
        };
    }

    onRowUpdate(e: DxRowUpdateEvent<ClosureJob>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    onEditorPreparing(event) {
        super.onEditorPreparing(event);
        if (event.dataField === 'clientIdOverride') {
            event.editorOptions.onValueChanged = (args: any) => {
                event.setValue(args.value);
                if (args.value === '') {
                    args.component.option('value', null);
                }
            };
        }
    }

    createDetailDataSource(jobId: string): DataSource {
        if (this.detailDataSources[jobId] == null) {
            this.detailDataSources[jobId] = this.closureJobsService.createDetailDataSource(jobId);
        }

        return this.detailDataSources[jobId];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    executeJob = (e: DxRowButtonClickEvent<ClosureJob>) => {
        this.closureJobsService.executeClosureJob(e.row.data.id)
            .subscribe(() => this.toastService.translatedSuccessToast('closureJobs.table.toasts.executeSuccess'));

        e.event.preventDefault();
    };
}
