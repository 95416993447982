import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { Observable } from 'rxjs';

@Injectable()
export class ActiveSessionsService {

    private readonly resourceUrl = 'api/active-sessions';

    constructor(
        private http: HttpClient,
        private translateService: EciTenantAwareLanguageService,
        private dxService: EciDxService,
    ) {
    }

    getActiveSessions(): DataSource {
        return this.dxService.createEditableDataSource(`${this.resourceUrl}/get-all`);
    }

    revokeAllOtherSessions(): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}/revoke-all`, {});
    }

    saveCurrentSession(): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}/save-current`, {});
    }
}
