import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import CustomStore from 'devextreme/data/custom_store';
import { HttpClient } from '@angular/common/http';
import { DxUtil } from '../shared/util/dx.util';
import { CommonService } from '../shared/util/common.service';

@Injectable()
export class ArticleBrickCodeRulesService {

    private readonly resourceUrl = 'api/article-brick-code-rules';

    constructor(
        private http: HttpClient,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) { }

    getArticleBrickCodeRules(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getBricksAsCustomStore() {
        return this.getCustomStore('/api/classification/bricks');
    }

    isEmpty(value: any): boolean {
        return value === '' || value == null;
    }

    filterBrickCode(value: any): boolean {
        return value !== undefined && value.length < 3;
    }

    getPossibleCountries(warehouseIds: string[], carrierIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-countries?warehouseIds=` + warehouseIds.toString()
                + '&brickCodes=' + carrierIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getPossibleWarehouses(countryIds: string[], carrierIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-warehouses?countryIds=` + countryIds.toString()
                + '&brickCodes=' + carrierIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getPossibleGpcBricks(countryIds: string[], warehousesIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-brick-codes?countryIds=` + countryIds.toString()
                + '&warehouseIds=' + warehousesIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { brickCode: value[0], brickDescription: value[1] };
                })),
        });
    }

    private getCustomStore(url: string) {
        return new CustomStore({
            key: 'id',
            load: loadOptions => {
                let options = loadOptions;
                const searchValueIsEmpty = this.isEmpty(loadOptions.searchValue) && this.isEmpty(loadOptions.searchExpr);
                if (searchValueIsEmpty) {
                    loadOptions.searchExpr = null;
                } else {
                    loadOptions.take = 50; // lazy loading
                    loadOptions.skip = 0;
                }
                const filterBrickCode = this.filterBrickCode(loadOptions.filter);
                if (filterBrickCode) {
                    loadOptions.filter = null;
                }
                if (searchValueIsEmpty && !filterBrickCode) {
                    options = DxUtil.loadAll(loadOptions);
                }
                return this.dxService.query(url, options)
                    .then(x => x.data);
            },
            byKey: id => this.http.get(`${url}/${id}`).toPromise(),
        });
    }

}
