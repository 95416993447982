import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { EciLanguageHelper, EciNavbarComponent, EciPrincipalService } from '@shared-components/shopsys-commons-ui';
import { Privilege } from '../shared/models/privilege.enum';

@Component({
    selector: 'app-root',
    templateUrl: './main.component.html',
})
export class KepMainComponent implements OnInit {

    Privilege = Privilege;

    @ViewChild(EciNavbarComponent) navbar: EciNavbarComponent;

    constructor(
        public principal: EciPrincipalService,
        private languageHelper: EciLanguageHelper,
        private router: Router,
    ) { }

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.languageHelper.updateTitle(this.getPageTitle(this.router.routerState.snapshot.root));
            }
        });
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : 'eciprototypeApp';

        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }

        return title;
    }

}
