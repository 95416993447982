import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { DeterminationRatioComponent } from './determination-ratio.component';

export const DETERMINATION_RATIO_ROUTE: Route = {
    path: 'determination-ratio',
    component: DeterminationRatioComponent,
    data: {
        roles: [],
        pageTitle: 'determinationRatio.title',
    },
    canActivate: [EciAuthGuard],
};
