import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdditionalServicesService } from './additional-services.service';
import { CommonService } from '../shared/util/common.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
      AdditionalServicesService,
      CommonService,
  ],
})
export class AdditionalServicesModule { }
