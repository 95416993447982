import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { CommonService } from '../../shared/util/common.service';

@Injectable()
export class DeliveryOptionsRecalculationService {

    private readonly resourceUrl = 'api/delivery-options-recalculation';

    constructor(
        private commonService: CommonService,
    ) { }

    createDataSource(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

}
