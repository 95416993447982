import { Component, OnInit } from '@angular/core';
import { CommonView } from '../../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../../shared/models/privilege.enum';
import { CurrencyExchangeRatesService } from './currency-exchange-rates.service';

@Component({
    selector: 'kep-currency-exchange-rates',
    templateUrl: './currency-exchange-rates.component.html',
})
export class CurrencyExchangeRatesComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    constructor(
        private currencyExchangeRatesService: CurrencyExchangeRatesService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.currencyExchangeRatesService.getCurrencyExchangeRates();
    }

}
