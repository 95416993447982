import { Injectable } from '@angular/core';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../../shared/util/common.service';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class CarrierDowntimeService {

    constructor(
        private dxService: EciDxService,
        private commonService: CommonService,
    ) {
    }

    getCarrierDowntimes(): DataSource {
        return this.commonService.getDataSource('/api/carrier-downtime');
    }

}
