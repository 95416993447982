import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { HttpClient } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import { CommonService } from '../shared/util/common.service';

@Injectable()
export class ArticleServicesRulesService {

    private readonly resourceUrl = 'api/article-services-rules';

    constructor(
        private http: HttpClient,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) { }

    getArticleServicesRules(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getPossibleCountries(warehouseIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-countries?warehouseIds=` + warehouseIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getPossibleWarehouses(countryIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-warehouses?countryIds=` + countryIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

}
