import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CountriesService } from './countries.service';
import { CommonService } from '../shared/util/common.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule,
    ],
    providers: [
        CountriesService,
        CommonService,
    ],
})
export class CountriesModule {
}
