import { CommonView } from '../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { CarriersService } from '../carriers/carriers.service';
import { ArticleBrickCodeRulesService } from './article-brick-code-rules.service';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { CarrierProduct } from '../shared/models/carrier-product.model';
import { Privilege } from '../shared/models/privilege.enum';
import { CountriesService } from '../countries/countries.service';
import { CarrierProductsService } from '../carrier-products/carrier-products.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { Country } from '../shared/models/country.model';
import { Carrier } from '../shared/models/carrier.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { ArticleBrickCodeRule } from '../shared/models/article-brick-code-rule.model';
import { GpcBrick } from '../shared/models/product-classification.model';

@Component({
    selector: 'kep-article-brick-code-rule',
    templateUrl: './article-brick-code-rules.component.html',
})
export class ArticleBrickCodeRulesComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    selectedCountries = [];
    selectedWarehouses = [];
    selectedBrickCodes = [];

    possibleCountries: CustomStore;
    possibleWarehouses: CustomStore;
    possibleBrickCodes: CustomStore;

    carrierStore: CustomStore;
    carrierProductsStore: CustomStore;
    warehouseStore: CustomStore;
    countriesStore: CustomStore;
    classificationSegmentStore: CustomStore;
    classificationFamilyStore: CustomStore;
    classificationClassStore: CustomStore;
    classificationBrickStore: CustomStore;
    deliveryOptionStore: CustomStore;

    resetCarrierProductCell = DxUtil.hookSetCellValue<ArticleBrickCodeRule>(rowData => {
        rowData.carrierProductId = null;
    });

    resetWarehouseCell = DxUtil.hookSetCellValue<ArticleBrickCodeRule>(rowData => {
        rowData.warehouseId = null;
    });

    resetFamilyCellAndDependants = DxUtil.hookSetCellValue<ArticleBrickCodeRule>(rowData => {
        rowData.brickCode = null;
    });

    resetClassCellAndDependants = DxUtil.hookSetCellValue<ArticleBrickCodeRule>(rowData => {
        rowData.brickCode = null;
    });

    resetBrickCell = DxUtil.hookSetCellValue<ArticleBrickCodeRule>(rowData => {
        rowData.brickCode = null;
    });

    constructor(
        private articleBrickCodeRulesService: ArticleBrickCodeRulesService,
        private carrierService: CarriersService,
        private carrierProductsService: CarrierProductsService,
        private warehouseService: WarehouseService,
        private countriesService: CountriesService,
    ) {
        super();
     }

    ngOnInit() {
        this.dataSource = this.articleBrickCodeRulesService.getArticleBrickCodeRules();

        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.carrierProductsStore = this.carrierProductsService.getCarrierProductsAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
        this.classificationBrickStore = this.articleBrickCodeRulesService.getBricksAsCustomStore();
        this.deliveryOptionStore = this.carrierProductsService.getForwardDeliveryOptions();
        this.reloadFilter();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierProduct>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<ArticleBrickCodeRule>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<ArticleBrickCodeRule, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<ArticleBrickCodeRule, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    getFilteredCarriers = (options: DxLookupDataSourceOptions<ArticleBrickCodeRule>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<ArticleBrickCodeRule, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    onFilterChange() {
        this.dataGrid.instance.clearFilter();
        this.reloadFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('warehouseId', 'filterValues', this.selectedWarehouses);
        this.dataGrid.instance.columnOption('brickCode', 'filterValues', this.selectedBrickCodes);
    }

    resetFilter() {
        super.resetFilter();

        this.selectedBrickCodes = [];
        this.selectedCountries = [];
        this.selectedWarehouses = [];

        this.reloadFilter();
    }

    createBrickLabel(brick: GpcBrick) {
        if (brick) {
            return brick.brickCode + ' | ' + brick.brickDescription;
        }
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.id', filterOperation, filterValue];
    }

    validateForwardDeliveryOption(options: { value: string; data: CarrierProduct }): boolean {
        return options.data.forwardShipment === false || options.value != null;
    }

    reloadFilter() {
        this.possibleCountries = this.articleBrickCodeRulesService.getPossibleCountries(this.selectedWarehouses, this.selectedBrickCodes);
        this.possibleWarehouses = this.articleBrickCodeRulesService.getPossibleWarehouses(this.selectedCountries, this.selectedBrickCodes);
        this.possibleBrickCodes = this.articleBrickCodeRulesService.getPossibleGpcBricks(this.selectedCountries, this.selectedWarehouses);
    }
}
