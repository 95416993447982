import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SendItLabelAndClosureConfiguration } from './send-it-label-and-closure-configuration/send-it-label-and-closure-configuration.service';
import { LabelServiceConfig } from './label-service-config/label-service-config.service';
import { BackupShippingTypeAddressesService } from './backup-shipping-type-addresses/backup-shipping-type-addresses.service';
import { CommonService } from '../shared/util/common.service';
import { CarrierDowntimeService } from './carrier-downtime/carrier-downtime.service';
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule,
    ],
    providers: [
        SendItLabelAndClosureConfiguration,
        LabelServiceConfig,
        BackupShippingTypeAddressesService,
        CarrierDowntimeService,
        CommonService,
    ],
})
export class ConfigurationModule {
}
