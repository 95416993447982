<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="postalCodeLists.title">
        Postal Code Lists
    </div>

    <div class="row justify-content-center">

        <div class="col-sm-11">

            <dx-data-grid (onInitNewRow)="initRow($event)"
                          (onRowUpdating)="rowUpdate($event)"
                          (onEditingStart)="editRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [rowAlternationEnabled]="true"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WritePostcode"
                    mode="form"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">

                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="name"></dxi-item>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item dataField="warehouseId"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary" role="button"
                                            type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column [allowEditing]="false"
                            [allowHeaderFiltering]="false"
                            [caption]="'postalCodeLists.table.headers.id' | translate"
                            [visible]="false"
                            dataField="id">
                </dxi-column>

                <dxi-column [allowHeaderFiltering]="false"
                            [caption]="'postalCodeLists.table.headers.name' | translate"
                            dataField="name">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            [setCellValue]="resetCarrierCell"
                            sortOrder="asc"
                            calculateSortValue="countryName"
                            [caption]="'postalCodeLists.table.headers.country' | translate"
                            dataField="countryId">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countriesStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierFilterExpression"
                            [caption]="'postalCodeLists.table.headers.carrier' | translate"
                            calculateSortValue="carrierName"
                            sortOrder="asc"
                            dataField="carrierId">
                    <dxo-lookup [dataSource]="getFilteredCarriers"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateWarehouseFilterExpression"
                            dataField="warehouseId"
                            [editorOptions]="{ format: 'fixedPoint', showClearButton: true }"
                            [caption]="'postalCodeLists.table.headers.warehouse' | translate">
                    <dxo-lookup [dataSource]="warehouseStore"
                                valueExpr="id"
                                displayExpr="name">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column type="buttons" [width]="110"
                            [caption]="'postalCodeLists.table.headers.list' | translate">
                    <dxi-button
                        template="listButton">
                        <div *dxTemplate="let data of 'listButton'">
                            <dx-button
                                (onClick)="goToList(data)"
                                [width]="100"
                                stylingMode="outlined"
                                type="default"
                                text="List">
                            </dx-button>
                        </div>
                    </dxi-button>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>
</div>
