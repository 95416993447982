import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ZoneService } from './zone.service';
import { CommonService } from '../shared/util/common.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
      ZoneService,
      CommonService,
  ],
})
export class ZoneModule { }
