import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { LabelServiceConfigComponent } from './label-service-config.component';

export const LABEL_SERVICE_CONFIG_ROUTE: Route = {
    path: 'label-service-config',
    component: LabelServiceConfigComponent,
    data: {
        roles: [],
        pageTitle: 'label-service-config.title',
    },
    canActivate: [EciAuthGuard],
};
