import { Component, OnInit } from '@angular/core';
import { CommonView } from '../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { CarrierSurchargeRulesService } from './carrier-surcharge-rules.service';
import { CarrierPrice } from '../shared/models/carrier-price.model';
import { Privilege } from '../shared/models/privilege.enum';

@Component({
    selector: 'kep-carrier-surcharge-rules',
    templateUrl: './carrier-surcharge-rules.component.html',
})
export class CarrierSurchargeRulesComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    labelMode = 'floating';
    surchargeTypes: CustomStore;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    constructor(
        private surchargeRuleService: CarrierSurchargeRulesService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.surchargeRuleService.getCarrierSurchargeRules();
        this.surchargeTypes = this.surchargeRuleService.getSurchargeTypes();
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierPrice>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

}
