<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="sendItLabelAndClosureConfiguration.title">
        SendIT Label & Closure Configuration
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="rowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="datasource"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [hoverStateEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"
                           [allowedPageSizes]="allowedPageSizes" [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteSendItLabelAndClosure"
                    mode="form"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">
                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="sapShippingType"></dxi-item>
                            <dxi-item dataField="sendItShipper"></dxi-item>
                            <dxi-item dataField="sendItClientId"></dxi-item>
                            <dxi-item dataField="carrierSapId"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item dataField="salesChannel"></dxi-item>
                            <dxi-item dataField="sendItShippingType"></dxi-item>
                            <dxi-item dataField="destinationCountryId"></dxi-item>
                            <dxi-item dataField="description"></dxi-item>
                            <dxi-item dataField="ewmWarehouseId"></dxi-item>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            [caption]="'sendItLabelAndClosureConfiguration.table.headers.id' | translate"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column dataField="sapShippingType"
                            [caption]="'sendItLabelAndClosureConfiguration.table.headers.sapShippingType' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="sendItShipper"
                            [caption]="'sendItLabelAndClosureConfiguration.table.headers.sendItShipper' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="sendItClientId"
                            [caption]="'sendItLabelAndClosureConfiguration.table.headers.sendItClientId' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="carrierSapId"
                            [caption]="'sendItLabelAndClosureConfiguration.table.headers.carrierSapId' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="sendItShippingType"
                            [caption]="'sendItLabelAndClosureConfiguration.table.headers.sendItShippingType' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [caption]="'sendItLabelAndClosureConfiguration.table.headers.description' | translate"
                            dataField="description">
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            [editorOptions]="{ showClearButton: true }"
                            [caption]="'sendItLabelAndClosureConfiguration.table.headers.destinationCountry' | translate"
                            dataField="destinationCountryId">
                    <dxo-header-filter [dataSource]="countriesStore.key">
                    </dxo-header-filter>
                    <dxo-lookup [dataSource]="countriesStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="ewmWarehouseId"
                            [caption]="'sendItLabelAndClosureConfiguration.table.headers.ewmWarehouseId' | translate">
                </dxi-column>

                <dxi-column [caption]="'sendItLabelAndClosureConfiguration.table.headers.salesChannel' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            [width]="140"
                            dataField="salesChannel">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="salesChannelStore"
                                displayExpr="text"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt"></dxi-button>
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>
