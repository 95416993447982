<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="countries.title">
        Countries
    </div>

    <div class="row justify-content-center">

        <div class="col-sm-6">
            <dx-data-grid [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [wordWrapEnabled]="true"
                          [rowAlternationEnabled]="true"
                          (onRowUpdating)="rowUpdate($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteCountry"
                    mode="row"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            [caption]="'countries.table.headers.id' | translate"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column dataField="countryCode"
                            [allowFiltering]="false"
                            width="200"
                            alignment="right"
                            [caption]="'countries.table.headers.countryCode' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="name"
                            sortOrder="asc"
                            [caption]="'countries.table.headers.name' | translate">
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt"></dxi-button>
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>
