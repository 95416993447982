import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EciDxService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import DataSource from 'devextreme/data/data_source';
import { DxUtil } from './dx.util';
import CustomStore from 'devextreme/data/custom_store';
import { firstValueFrom } from 'rxjs';
import { ShipmentDirection } from '../models/shipment-direction.model';

const shipmentDirection: ShipmentDirection[] = [{
    'id': 1,
    'name': 'FORWARD',
},
    {
        'id': 2,
        'name': 'RETURN',
    }];

@Injectable()
export class CommonService {

    constructor(
        private http: HttpClient,
        private dxService: EciDxService,
        private translateService: EciTenantAwareLanguageService,
    ) {
    }

    getDataSource(resourceUrl: string): DataSource {
        return this.dxService.createEditableDataSource(resourceUrl, 'id', {
            dataSource: {
                load: loadOptions => loadOptions.take !== undefined ?
                    this.dxService.query(resourceUrl, loadOptions) : this.dxService.query(resourceUrl, DxUtil.loadAll(loadOptions))
                        .then((response: any) => {
                            return {
                                data: response?.data,
                                totalCount: response?.totalCount,
                            };
                        }),
            },
        });
    }

    getCustomSalesChannels(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>('/api/carrier-allocation/sales-channels'))
                .then(data => data.map(value => {
                    const text = this.translateService.get(`carrierDetermination._salesChannels.${value}`);
                    return { text, value };
                })),
        });
    }

    getShipmentDirection() {
        return shipmentDirection;
    }
}
