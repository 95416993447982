import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { WarehouseComponent } from './warehouse.component';

export const WAREHOUSE_ROUTE: Route = {
    path: 'warehouse',
    component: WarehouseComponent,
    data: {
        roles: [],
        pageTitle: 'warehouse.title',
    },
    canActivate: [EciAuthGuard],
};
