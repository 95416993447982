import { Component, OnInit } from '@angular/core';
import { CommonView } from '../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { ShipmentDirection } from '../shared/models/shipment-direction.model';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { CarrierPrice } from '../shared/models/carrier-price.model';
import { CarrierPricesService } from '../parcel-costs/carrier-prices/carrier-prices.service';
import { CountriesService } from '../countries/countries.service';
import { CarriersService } from '../carriers/carriers.service';
import { CarrierProductsService } from '../carrier-products/carrier-products.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { CommonService } from '../shared/util/common.service';
import { Country } from '../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { Carrier } from '../shared/models/carrier.model';
import { CarrierDeterminationService } from '../carrier-determination/carrier-determination.service';
import { CarrierSurchargesService } from './carrier-surcharges.service';
import { CarrierSurcharge } from '../shared/models/carrier-surcharge';
import { PostCodeListsService } from '../postcodelists/post-code-lists.service';
import { CarrierSurchargeRulesService } from '../carrier-surcharge-rules/carrier-surcharge-rules.service';
import { Privilege } from '../shared/models/privilege.enum';
import { Warehouse } from '../shared/models/warehouse.model';
import { Zone } from '../shared/models/zone.model';
import { ZoneService } from '../zone/zone.service';

@Component({
    selector: 'kep-carrier-surcharges',
    templateUrl: './carrier-surcharges.component.html',
})
export class CarrierSurchargesComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    labelMode = 'floating';
    countryStore: CustomStore;
    carrierStore: CustomStore;
    carrierProductsStore: CustomStore;
    warehouseStore: CustomStore;
    shipmentDirections: ShipmentDirection[];
    deliveryOptions: CustomStore;
    surchargeRuleStore: CustomStore;
    postCodeListStore: CustomStore;
    currencies: CustomStore;
    dimensionStore: CustomStore;
    selectedRuleType: string;
    zonesStore: CustomStore;
    filteredZones: CustomStore;
    zones: Zone[];

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    resetCarrierProductCell = DxUtil.hookSetCellValue<CarrierSurcharge>(rowData => {
        rowData.carrierProductId = null;
    });
    resetCarrierCarrierProductCell = DxUtil.hookSetCellValue<CarrierSurcharge>(rowData => {
        rowData.carrierId = null;
        rowData.carrierProductId = null;
    });

    resetDimensionsPostalCodeLimitCell = DxUtil.hookSetCellValue<CarrierSurcharge>(rowData => {
        rowData.dimensions = null;
        rowData.postCodeListId = null;
        rowData.limit = null;
    });

    disableCarrierProductCell = DxUtil.hookSetCellValue<CarrierSurcharge>(rowData => {
        rowData.carrierProductId = null;
        this.editableCarrierProduct = false;
    });

    disableLimitCell = DxUtil.hookSetCellValue<CarrierSurcharge>(rowData => {
        rowData.limit = null;
        this.editableLimit = false;
    });

    disablePercentageSurchargeCell = DxUtil.hookSetCellValue<CarrierSurcharge>(rowData => {
        rowData.percentageSurcharge = null;
        this.editablePercentageSurcharge = false;
        this.editableCurrency = true;
    });

    disableFixedSurchargeCell = DxUtil.hookSetCellValue<CarrierSurcharge>(rowData => {
        rowData.fixedSurcharge = null;
        rowData.localCurrency = null;
        this.editableFixedSurcharge = false;
        this.editableCurrency = false;
    });

    editableCarrierProduct = true;
    editableLimit = true;
    editablePostalCode = true;
    editableDimensions = true;
    editableFixedSurcharge = true;
    editablePercentageSurcharge = true;
    editableCurrency = true;
    editableActive = true;

    constructor(
        private carrierPricesService: CarrierPricesService,
        private countriesService: CountriesService,
        private carrierService: CarriersService,
        private carrierProductService: CarrierProductsService,
        private warehouseService: WarehouseService,
        private commonService: CommonService,
        private carrierDeterminationService: CarrierDeterminationService,
        private carrierSurchargesService: CarrierSurchargesService,
        private postCodeListService: PostCodeListsService,
        private surchargeRuleService: CarrierSurchargeRulesService,
        private zonesService: ZoneService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.carrierSurchargesService.getCarrierSurcharges();
        this.countryStore = this.countriesService.getCountriesAsCustomStore();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.carrierProductsStore = this.carrierProductService.getCarrierProductsAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.shipmentDirections = this.commonService.getShipmentDirection();
        this.currencies = this.carrierPricesService.getCustomCurrencies();
        this.deliveryOptions = this.carrierDeterminationService.getDeliveryOptions();
        this.postCodeListStore = this.postCodeListService.getPostcodeListsAsCustomStore();
        this.dimensionStore = this.carrierSurchargesService.getDimensions();
        this.surchargeRuleStore = this.surchargeRuleService.getCarrierSurchargeRulesAsCustomStore();
        this.zonesStore = this.zonesService.getZonesAsCustomStore();
        this.filteredZones = this.zonesStore;
        this.zonesService.getZonesAsPromise().then(zones => this.zones = zones);

        this.carrierProductValidation = this.carrierProductValidation.bind(this);
        this.limitValidation = this.limitValidation.bind(this);
        this.postCodeValidation = this.postCodeValidation.bind(this);
        this.dimensionsValidation = this.dimensionsValidation.bind(this);
        this.fixedValidation = this.fixedValidation.bind(this);
        this.percentageValidation = this.percentageValidation.bind(this);
        this.collectionWarehouseValidation = this.collectionWarehouseValidation.bind(this);
        this.activeValidation = this.activeValidation.bind(this);
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierPrice>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    onEditorPreparing(e) {
        e.editorOptions.onValueChanged = data => {
            e.setValue(data.value);
            this.isDirty = true;
        };

        if (e.parentType === 'dataRow' && e.dataField === 'surchargeRuleId') {
            const existingSurchargeRule = e.value;
            if (!!existingSurchargeRule) {
                this.selectedRuleType = e.lookup.items.find(item => item.id === e.value).type;
                this.makeNecessaryFieldsEditable();
            }
            e.editorOptions.onValueChanged = data => {
                e.setValue(data.value);
                this.isDirty = true;
                this.selectedRuleType = e.lookup.items.find(item => item.id === data.value).type;
                this.makeNecessaryFieldsEditable();
            };
        }
        if (e.parentType === 'dataRow' && e.dataField === 'countryId') {
            if (!!e.value) {
                this.filteredZones = this.getZonesByCountryId(e.value);
            }
            e.editorOptions.onValueChanged = data => {
                e.setValue(data.value);
                this.isDirty = true;
                this.filteredZones = this.getZonesByCountryId(data.value);
            };
    }
    }
        getZonesByCountryId(countryId: string): CustomStore {
            if (!countryId) {
                return this.zonesStore;
            }
            return this.zonesService.getZonesByCountryAsCustomStore(countryId);
        }

        getZoneName(zoneId: string): string {
            if (!zoneId) {
                return null;
            }
            return this.zones.find( zone => zone.id === zoneId).name;
        }

        calculateZoneFilterExpression(filterValue: string): string[] {
            return ['zones', 'contains', filterValue];
        }

        getFilteredZones = (options: DxLookupDataSourceOptions<CarrierPrice>) => {
            if (options.data == null) {
                return { store: this.zonesStore };
            }

            const country = DxUtil.fetchLookupObject<CarrierPrice, Country>(options, 'countryId');

            const filter = DxFilter.equals('country.id', country ? country.id : null);

            return {
                store: this.zonesStore,
                filter,
            };
        };

    initNewRow(e) {
        this.makeAllFieldsEditable();
        e.data = this.currentRowData;
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculatePostCodeListFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['postCodeList.id', filterOperation, filterValue];
    }

    calculateSurchargeRuleFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['surchargeRule.id', filterOperation, filterValue];
    }

    getFilteredCarriers = (options: DxLookupDataSourceOptions<CarrierSurcharge>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierSurcharge, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<CarrierSurcharge>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierSurcharge, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<CarrierSurcharge, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    getFilteredPostCodeLists = (options: DxLookupDataSourceOptions<CarrierSurcharge>) => {
        if (options.data == null) {
            return { store: this.postCodeListStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierSurcharge, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<CarrierSurcharge, Carrier>(options, 'carrierId');
        const warehouse = DxUtil.fetchLookupObject<CarrierSurcharge, Warehouse>(options, 'warehouseId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.or(
                carrier ? DxFilter.equals('carrier.id', carrier.id) : DxFilter.isNull('carrier.id'),
                DxFilter.isNull('carrier.id'),
            ),
            DxFilter.or(
                warehouse ? DxFilter.equals('warehouse.id', warehouse.id) : DxFilter.isNull('warehouse.id'),
                DxFilter.isNull('warehouse.id'),
            ),
        );

        return {
            store: this.postCodeListStore,
            filter,
        };
    };

    carrierProductValidation(e) {
        if (!e.value) {
            this.editableLimit = true;
        }
        return !(
            (!e.data.limit && !e.value && this.selectedRuleType === 'OVERSIZE_OVERWEIGHT') ||
            (!e.value && this.selectedRuleType === 'BULK')
        );
    }

    dimensionsValidation(e) {
        return !(!e.value && this.selectedRuleType === 'OVERSIZE_OVERWEIGHT');

    }

    limitValidation(e) {
        if (!e.value) {
            this.editableCarrierProduct = true;
        }
        return !(!e.data.carrierProductId && !e.value && this.selectedRuleType === 'OVERSIZE_OVERWEIGHT');
    }

    postCodeValidation(e) {
        return !(this.selectedRuleType === 'REMOTE_DESTINATION' && !e.value);
    }

    fixedValidation(e) {
        if (!e.value) {
            this.editablePercentageSurcharge = true;
            this.editableCurrency = false;
        }
        return !(!e.data.percentageSurcharge && !e.value);
    }

    currencyValidation(e) {
        return !(!!e.data.fixedSurcharge && !e.value);
    }

    percentageValidation(e) {
        if (!e.value) {
            this.editableFixedSurcharge = true;
        }
        return !(!e.data.fixedSurcharge && !e.value);
    }

    collectionWarehouseValidation(e) {
        return !(!e.value && this.selectedRuleType === 'COLLECTION');
    }

    activeValidation(e) {
        return !(!e.value && this.selectedRuleType === 'PEAK');
    }

    toAfterFrom(e) {
        if (!!e.data.activeFrom) {
            return e.value > Date.parse(e.data.activeFrom);
        } else {
            return true;
        }
    }

    makeAllFieldsEditable() {
        this.editableCarrierProduct = true;
        this.editableLimit = true;
        this.editablePostalCode = true;
        this.editableDimensions = true;
        this.editableFixedSurcharge = true;
        this.editablePercentageSurcharge = true;
        this.editableCurrency = true;
    }

    makeNecessaryFieldsEditable() {
        if (this.selectedRuleType === 'OVERSIZE_OVERWEIGHT') {
            this.editableCarrierProduct = true;
            this.editableLimit = true;
            this.editableDimensions = true;
        } else {
            this.editableLimit = false;
            this.editableDimensions = false;
        }
        if (this.selectedRuleType === 'REMOTE_DESTINATION') {
            this.editableCarrierProduct = true;
            this.editablePostalCode = true;
        } else {
            this.editablePostalCode = false;
        }
        this.editableActive = this.selectedRuleType === 'PEAK';
    }
}
