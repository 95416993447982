import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarrierDeterminationService } from './carrier-determination.service';
import { CarrierPercentageComponent } from './carrier-percentage/carrier-percentage.component';
import {
    DxChartModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxFormModule,
    DxListModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxPopupModule,
    DxCheckBoxModule,
    DxTagBoxModule, DxFileUploaderModule, DevExtremeModule
} from 'devextreme-angular';
import { TranslateModule } from '@ngx-translate/core';
import { ShopsysCommonsModule } from '@shared-components/shopsys-commons-ui';
import { CarrierPostCodeListComponent } from './carrier-post-code-list/carrier-post-code-list.component';
import { CommonService } from '../shared/util/common.service';

@NgModule({
    declarations: [CarrierPercentageComponent, CarrierPostCodeListComponent],
    imports: [
        CommonModule,
        RouterModule,
        DxDataGridModule,
        TranslateModule,
        ShopsysCommonsModule,
        DxChartModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxFormModule,
        DxListModule,
        DxButtonModule,
        DxPopupModule,
        DxCheckBoxModule,
        DxTagBoxModule,
        DxFileUploaderModule,
        DevExtremeModule,
    ],
    providers: [
        CarrierDeterminationService,
        CommonService,
    ],
})
export class CarrierDeterminationModule {
}
