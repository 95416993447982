import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CptService } from './cpt.service';
import { CommonService } from '../shared/util/common.service';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
    CptService,
    CommonService,
  ],
})
export class CptModule { }
