import { CommonView } from '../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from 'src/app/shared/models/privilege.enum';
import CustomStore from 'devextreme/data/custom_store';
import { DxLookupDataSourceOptions, DxUtil } from '../shared/util/dx.util';
import { CarrierProduct } from '../shared/models/carrier-product.model';
import { CarrierProductsService } from '../carrier-products/carrier-products.service';
import { CarriersService } from '../carriers/carriers.service';
import { CountriesService } from '../countries/countries.service';
import { Country } from '../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { WarehouseService } from '../warehouse/warehouse.service';
import { Router } from '@angular/router';
import { PostCodeListsService } from './post-code-lists.service';
import { PostCodeList } from '../shared/models/post-code-list.model';

@Component({
    selector: 'kep-search',
    templateUrl: './post-code-lists.component.html',
})
export class PostCodeListsComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100];

    carrierStore: CustomStore;
    countriesStore: CustomStore;
    warehouseStore: CustomStore;
    editableCarrier = false;

    resetCarrierCell = DxUtil.hookSetCellValue<PostCodeList>(rowData => {
        rowData.carrierId = null;
    });

    constructor(
        private postCodeListsService: PostCodeListsService,
        private carrierProductsService: CarrierProductsService,
        private carrierService: CarriersService,
        private countriesService: CountriesService,
        private warehouseService: WarehouseService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.postCodeListsService.getPostCodeLists();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
    }

    initRow(e) {
        this.editableCarrier = true;
        e.data = this.currentRowData;
    }

    editRow(e) {
        this.editableCarrier = e.data.carrierId == null;
    }

    goToList(e) {
        this.router.navigate([
            'post-code-lists',
            e.data.id,
            {
                title: e.data.name,
                country: e.data.countryId,
            },
        ]);
    }

    getFilteredCarriers = (options: DxLookupDataSourceOptions<CarrierProduct>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierProduct, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }
}
