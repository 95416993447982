import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbPopover, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { $as } from '../cron-schedule.helper';
import { CronSchedule } from '../models/cron-schedule';
import { CronScheduleMode } from '../models/cron-schedule-mode.enum';
import { CRON_SCHEDULE_WEEKDAYS } from '../models/cron-schedule-weekday.enum';

@Component({
    selector: 'kep-cron-schedule-editor',
    templateUrl: './cron-schedule-editor.component.html',
    styleUrls: ['./cron-schedule-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CronScheduleEditorComponent),
            multi: true,
        },
    ],
})
export class CronScheduleEditorComponent implements ControlValueAccessor {

    value = '0 0/10 0 ? * * *';
    cronSchedule: CronSchedule;

    CronScheduleMode = CronScheduleMode;
    CRON_SCHEDULE_WEEKDAYS = CRON_SCHEDULE_WEEKDAYS;
    $as = $as;

    onChange: (value: string) => void = () => { };
    onTouched: () => void = () => { };

    writeValue(value: string) {
        if (value != null) {
            this.value = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    emitChange() {
        this.onChange(this.value);
        this.onTouched();
    }

    onOpenClick(popover: NgbPopover) {

        this.cronSchedule = CronSchedule.fromCronString(this.value);

        popover.open();
    }

    onTabChange(event: NgbNavChangeEvent) {
        this.cronSchedule = CronSchedule.createDefaultInstanceByMode(event.nextId as CronScheduleMode);
    }

    onSetClick(popover: NgbPopover) {
        this.value = this.cronSchedule.toCronString();
        this.emitChange();

        popover.close();
    }

}
