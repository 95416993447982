<div class="page px-5">

    <div class="page__headline"
         eciTranslate="activeSessions.title">
        Additional Service
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="rowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: false, paging: true }"
                          [rowAlternationEnabled]="false"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop">
                </dxo-column-chooser>
                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>

                <div *dxTemplate="let data of 'gridHeader'">
                    <dx-button
                        stylingMode="contained"
                        text="Revoke all other sessions"
                        type="normal"
                        (onClick)="revokeAll()">
                    </dx-button>
                </div>

                <dxi-column dataField="id"
                            caption="{{ 'activeSessions.table.headers.id' | translate }}"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column dataField="lastUsed"
                            sortOrder="dec"
                            caption="{{ 'activeSessions.table.headers.lastUsed' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="usedIp"
                            caption="{{ 'activeSessions.table.headers.usedIp' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

            </dx-data-grid>
        </div>
    </div>
</div>
