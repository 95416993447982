import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarrierSurchargesComponent } from './carrier-surcharges.component';

export const CARRIER_SURCHARGES_ROUTE: Route = {
    path: 'carrier-surcharges',
    component: CarrierSurchargesComponent,
    data: {
        roles: [],
        pageTitle: 'carrierSurcharges.title',
    },
    canActivate: [EciAuthGuard],
};
