import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';

@Injectable()
export class AuditsService {

    private readonly resourceUrl = 'api/custom-audits';

    constructor(
        private http: HttpClient,
        private dxService: EciDxService,
    ) { }

    getAudits(): DataSource {
        return this.dxService.createEditableDataSource(this.resourceUrl);
    }
}
