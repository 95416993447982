import { DateUtil } from '../../shared/util/date.util';
import { CommonView } from '../../shared/util/common-view.util';
import { Component } from '@angular/core';
import { CarrierDeterminationService } from '../carrier-determination.service';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DxLookupDataSourceOptions, DxUtil } from '../../shared/util/dx.util';
import { CountriesService } from '../../countries/countries.service';
import { WarehouseService } from '../../warehouse/warehouse.service';
import { CarriersService } from '../../carriers/carriers.service';
import { Country } from '../../shared/models/country.model';
import { Carrier } from '../../shared/models/carrier.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { CarrierPercentage } from '../../shared/models/carrier-percentage.model';
import { CarrierProductsService } from '../../carrier-products/carrier-products.service';
import { Privilege } from '../../shared/models/privilege.enum';
import { ZoneService } from '../../zone/zone.service';
import { Zone } from '../../shared/models/zone.model';

@Component({
    selector: 'kep-carrier-percentage',
    templateUrl: './carrier-percentage.component.html',
    styleUrls: ['./carrier-percentage.component.scss'],
})
export class CarrierPercentageComponent extends CommonView {

    Privilege = Privilege;

    dataSource: DataSource;
    carriersStore: CustomStore;
    warehouseStore: CustomStore;
    countryStore: CustomStore;
    zoneStore: CustomStore;
    deliveryLocationStore: CustomStore;
    carrierProductsStore: CustomStore;
    salesChannelStore: CustomStore;
    editableColumns = false;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    selectedCountries = [];
    selectedZones = [];
    selectedWarehouses = [];
    selectedCarriers = [];

    possibleCountries: CustomStore;
    possibleZones: CustomStore;
    possibleWarehouses: CustomStore;
    possibleCarriers: CustomStore;
    filteredZones: CustomStore;
    zonesStore: CustomStore;
    zones: Zone[];

    resetCarrierProductCell = DxUtil.hookSetCellValue<CarrierPercentage>(rowData => {
        rowData.carrierProductId = null;
    });

    resetCarrierProductAndZoneCell = DxUtil.hookSetCellValue<CarrierPercentage>(rowData => {
        rowData.carrierProductId = null;
        rowData.zones = null;
    });

    constructor(
        private carrierDeterminationService: CarrierDeterminationService,
        private countryService: CountriesService,
        private zoneService: ZoneService,
        private warehouseService: WarehouseService,
        private carriersService: CarriersService,
        private carrierProductsService: CarrierProductsService,
    ) {
        super();
        this.dataSource = this.carrierDeterminationService.getCarrierRatio();
        this.countryStore = this.countryService.getCountriesAsCustomStore();
        this.zoneStore = this.zoneService.getZonesAsCustomStore();
        this.filteredZones = this.zonesStore;
        this.zoneService.getZonesAsPromise().then(zones => this.zones = zones);
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.carriersStore = this.carriersService.getCarriersAsCustomStore();
        this.deliveryLocationStore = this.carrierDeterminationService.getDeliveryOptions();
        this.carrierProductsStore = this.carrierProductsService.getCarrierProductsAsCustomStore();
        this.salesChannelStore = this.carrierDeterminationService.getSalesChannels();
        this.reloadFilter();
    }

    initRow(e) {
        this.editableColumns = true;

        e.data = this.currentRowData;
    }

    editRow() {
        this.editableColumns = false;
    }
    onEditorPreparing(e) {
        e.editorOptions.onValueChanged = data => {
            e.setValue(data.value);
            this.isDirty = true;
        };

        if (e.parentType === 'dataRow' && e.dataField === 'countryId') {
            if (!!e.value) {
                this.filteredZones = this.getZonesByCountryId(e.value);
            }
            e.editorOptions.onValueChanged = data => {
                e.setValue(data.value);
                this.isDirty = true;
                this.filteredZones = this.getZonesByCountryId(data.value);
            };
        }
    }

    rowInsert(e) {
        e.data.activeSlotStart = this.formatDate(e.data.activeSlotStart);
        e.data.activeSlotEnd = this.formatActiveSlotEnd(e.data.activeSlotEnd);
    }

    formatDate(date) {
        if (date === undefined) {
            return '00:00:00';
        }
        return ('00' + date.getHours()).slice(-2)
            + ':' + ('00' + date.getMinutes()).slice(-2)
            + ':' + ('00' + date.getSeconds()).slice(-2);
    }

    formatActiveSlotEnd(date) {
        if (date === undefined) {
            return '23:59:59';
        }
        return ('00' + date.getHours()).slice(-2)
            + ':' + ('00' + date.getMinutes()).slice(-2)
            + ':' + ('00' + date.getSeconds()).slice(-2);
    }

    getFilteredZones = (options: DxLookupDataSourceOptions<CarrierPercentage>) => {
        if (options.data == null) {
            return { store: this.zoneStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierPercentage, Country>(options, 'countryId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
        );

        return {
            store: this.zoneStore,
            filter,
        };
    };
    getZoneName(zoneId: string): string {
        if (!zoneId) {
            return null;
        }
        return this.zones.find( zone => zone.id === zoneId).name;
    }
    getZonesByCountryId(countryId: string): CustomStore {
        if (!countryId) {
            return this.zonesStore;
        }
        return this.zoneService.getZonesByCountryAsCustomStore(countryId);
    }
    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<CarrierPercentage>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierPercentage, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<CarrierPercentage, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    getFilteredCarriers = (options: DxLookupDataSourceOptions<CarrierPercentage>) => {
        if (options.data == null) {
            return { store: this.carriersStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierPercentage, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carriersStore,
            filter,
        };
    };

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateZoneFilterExpression(filterValue: string): string[] {
        return ['zones', 'contains', filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.id', filterOperation, filterValue];
    }

    resetFilter() {
        super.resetFilter();

        this.selectedCarriers = [];
        this.selectedCountries = [];
        this.selectedZones = [];
        this.selectedWarehouses = [];

        this.reloadFilter();
    }

    onFilterChange() {
        this.dataGrid.instance.clearFilter();
        this.reloadFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('zoneId', 'filterValues', this.selectedZones);
        this.dataGrid.instance.columnOption('warehouseId', 'filterValues', this.selectedWarehouses);
        this.dataGrid.instance.columnOption('carrierId', 'filterValues', this.selectedCarriers);
    }

    cloneRow(e) {
        e.row.data.id = null;
        this.currentRowData = Object.assign({}, e.row.data);
        this.currentRowData.activeSlotStart = DateUtil.convertToDate(e.row.data.activeSlotStart);
        this.currentRowData.activeSlotEnd = DateUtil.convertToDate(e.row.data.activeSlotEnd);
        this.dataGrid.instance.addRow();
        this.currentRowData = null;
        // When cloning the first element of the grid, the editor does not open and a refresh is needed
        if (e.row.rowIndex === 0) {
            this.dataGrid.instance.refresh();
        }
    }

    reloadFilter() {
        this.possibleCountries = this.carrierDeterminationService.getPossibleCountries(this.selectedWarehouses, this.selectedCarriers);
        this.possibleZones = this.carrierDeterminationService.getPossibleZones(this.selectedCountries);
        this.possibleWarehouses = this.carrierDeterminationService.getPossibleWarehouses(this.selectedCountries, this.selectedCarriers);
        this.possibleCarriers = this.carrierDeterminationService.getPossibleCarriers(this.selectedCountries, this.selectedWarehouses);
    }
}
