import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarrierPercentageComponent } from './carrier-percentage/carrier-percentage.component';

export const CARRIER_DETERMINATION_ROUTE: Route = {
    path: 'carrier-determination',
    component: CarrierPercentageComponent,
    data: {
        roles: [],
        pageTitle: 'carrierDetermination.title',
    },
    canActivate: [EciAuthGuard],
};
