import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { GeneralDeterminationQuantityLimitsComponent } from './general-determination-quantity-limits.component';

export const GENERAL_DETERMINATION_QUANTITY_LIMITS_ROUTE: Route = {
    path: 'carrier-determination/general-determination-quantity-limits',
    component: GeneralDeterminationQuantityLimitsComponent,
    data: {
        roles: [],
        pageTitle: 'generalDeterminationQuantityLimits.title',
    },
    canActivate: [EciAuthGuard],
};
