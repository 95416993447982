import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { EciDxService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { DxUtil } from '../shared/util/dx.util';
import { firstValueFrom } from 'rxjs';
import { CommonService } from '../shared/util/common.service';

@Injectable()
export class CarrierProductsService {

    private readonly resourceUrl = 'api/carrier-products';

    constructor(
        private http: HttpClient,
        private translateService: EciTenantAwareLanguageService,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) {
    }

    getCarrierProducts(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getCarrierProductsAsCustomStore(): CustomStore {
        return new CustomStore({
            key: 'id',
            load: loadOptions => this.dxService.query(this.resourceUrl, DxUtil.loadAll(loadOptions))
                .then(x => x.data),
            byKey: id => firstValueFrom(this.http.get(`${this.resourceUrl}/${id}`)),
        });
    }

    getForwardDeliveryOptions(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>(`${this.resourceUrl}/forward-delivery-options`))
                .then(data => data.map(value => {
                    const text = this.translateService.get(`carrierProducts._forwardDeliveryOptions.${value}`);
                    return { text, value };
                })),
        });
    }

    getHomeDeliveryOptions(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>(`${this.resourceUrl}/forward-delivery-options`))
                .then(data => data
                    .filter(value => value.includes('HOME'))
                    .map(value => {
                    const text = this.translateService.get(`carrierProducts._forwardDeliveryOptions.${value}`);
                    return { text, value };
                })),
        });
    }

    getReturnDeliveryOptions(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>(`${this.resourceUrl}/return-delivery-options`))
                .then(data => data.map(value => {
                    const text = this.translateService.get(`carrierProducts._returnDeliveryOptions.${value}`);
                    return { text, value };
                })),
        });
    }

    getPudoLocations(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>(`${this.resourceUrl}/carrier-pudo-locations`))
                .then(data => data.map(value => {
                    const text = this.translateService.get(`carrierProducts._pudoLocations.${value}`);
                    return { text, value };
                })),
        });
    }

}
