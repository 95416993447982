import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarrierDowntimeComponent } from './carrier-downtime.component';

export const CARRIER_DOWNTIME: Route = {
    path: 'carrier-downtime',
    component: CarrierDowntimeComponent,
    data: {
        roles: [],
        pageTitle: 'carrierDowntime.title',
    },
    canActivate: [EciAuthGuard],
};
