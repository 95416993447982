import { Component, OnInit } from '@angular/core';
import { CommonView } from '../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../shared/models/privilege.enum';
import { DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import CustomStore from 'devextreme/data/custom_store';
import { PackagingMaterialAvailabilityService } from './packaging-material-availability.service';
import { CarrierPrice } from '../shared/models/carrier-price.model';
import { WarehouseService } from '../warehouse/warehouse.service';
import { PackagingMaterialsService } from '../packaging-materials/packaging-materials.service';

@Component({
    selector: 'kep-packaging-materials',
    templateUrl: './packaging-material-availability.component.html',
})
export class PackagingMaterialAvailabilityComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    labelMode = 'floating';
    salesChannels: CustomStore;
    warehouseStore: CustomStore;
    packagingMaterialStore: CustomStore;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    constructor(
        private packagingMaterialAvailabilityService: PackagingMaterialAvailabilityService,
        private warehouseService: WarehouseService,
        private packagingMaterialService: PackagingMaterialsService,

    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.packagingMaterialAvailabilityService.getPackagingMaterialAvailability();
        this.salesChannels = this.packagingMaterialAvailabilityService.getSalesChannels();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.packagingMaterialStore = this.packagingMaterialService.getPackagingMaterialAsCustomStore();
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierPrice>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculatePackagingMaterialFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['packagingMaterial.id', filterOperation, filterValue];
    }

    getPackagingMaterialFull(item) {
        // "item" can be null
        return item && `${item.packagingMaterialNumber} - ${item.packagingMaterialName}`;
    }

}
