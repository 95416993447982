import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClosureJobsService } from './closure-jobs.service';
import { CommonService } from '../../shared/util/common.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
    ClosureJobsService,
    CommonService,
  ],
})
export class ClosureJobsModule { }
