<p (click)="onOpenClick(popover)" class="as-link">
    <kep-cron-schedule
        #popover="ngbPopover"
        [autoClose]="false"
        [ngbPopover]="popoverContent"
        [value]="value"
        container="body"
        placement="bottom-left"
        popoverClass="popover-xl"
        triggers="manual">
    </kep-cron-schedule>

    <i class="fas fa-pencil-alt ml-1"></i>
</p>

<ng-template #popoverContent>
    <ul ngbNav #nav="ngbNav" (navChange)="onTabChange($event)" [activeId]="cronSchedule.mode" class="nav-tabs">
        <li [ngbNavItem]="CronScheduleMode.Minutely">
            <a ngbNavLink>{{ 'shared.cronSchedule._modes.' + CronScheduleMode.Minutely | translate }}</a>
            <ng-template ngbNavContent>
                <div class="form-inline mt-2">
                    <span>{{ 'shared.cronSchedule.texts.every' | translate }}</span>
                    <input class="form-control small-input mx-2" [(ngModel)]="$as.minutely(cronSchedule).minutes">
                    <span>{{ 'shared.cronSchedule.texts.minutes' | translate }}</span>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="CronScheduleMode.Hourly">
            <a ngbNavLink>{{ 'shared.cronSchedule._modes.' + CronScheduleMode.Hourly | translate }}</a>
            <ng-template ngbNavContent>
                <div class="form-inline mt-2">
                    <span>{{ 'shared.cronSchedule.texts.every' | translate }}</span>
                    <input class="form-control small-input mx-2" [(ngModel)]="$as.hourly(cronSchedule).hours">
                    <span>{{ 'shared.cronSchedule.texts.hoursAtMinute' | translate }}</span>
                    <input class="form-control small-input ml-2" [(ngModel)]="$as.hourly(cronSchedule).minutes">
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="CronScheduleMode.Daily">
            <a ngbNavLink>{{ 'shared.cronSchedule._modes.' + CronScheduleMode.Daily | translate }}</a>
            <ng-template ngbNavContent>
                <div class="form-inline mt-2">
                    <span>{{ 'shared.cronSchedule.texts.every' | translate }}</span>
                    <input class="form-control small-input mx-2" [(ngModel)]="$as.daily(cronSchedule).days">
                    <span>{{ 'shared.cronSchedule.texts.daysAt' | translate }}</span>
                    <input class="form-control small-input mx-2" [(ngModel)]="$as.daily(cronSchedule).hours">
                    <span>:</span>
                    <input class="form-control small-input ml-2" [(ngModel)]="$as.daily(cronSchedule).minutes">
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="CronScheduleMode.Weekly">
            <a ngbNavLink>{{ 'shared.cronSchedule._modes.' + CronScheduleMode.Weekly | translate }}</a>
            <ng-template ngbNavContent>
                <div class="form-inline mt-2">
                    <span>{{ 'shared.cronSchedule.texts.every' | translate }}</span>
                    <select [(ngModel)]="$as.weekly(cronSchedule).weekday" class="custom-select mx-2">
                        <option *ngFor="let dayOfWeek of CRON_SCHEDULE_WEEKDAYS" [ngValue]="dayOfWeek">
                            {{ ('shared.cronSchedule._weekdays.' + dayOfWeek) | translate }}
                        </option>
                    </select>
                    <span>{{ 'shared.cronSchedule.texts.at' | translate }}</span>
                    <input class="form-control small-input mx-2" [(ngModel)]="$as.weekly(cronSchedule).hours">
                    <span>:</span>
                    <input class="form-control small-input ml-2" [(ngModel)]="$as.weekly(cronSchedule).minutes">
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="CronScheduleMode.Monthly">
            <a ngbNavLink>{{ 'shared.cronSchedule._modes.' + CronScheduleMode.Monthly | translate }}</a>
            <ng-template ngbNavContent>
                <div class="form-inline mt-2">
                    <span>{{ 'shared.cronSchedule.texts.every' | translate }}</span>
                    <input class="form-control small-input mx-2" [(ngModel)]="$as.monthly(cronSchedule).months">
                    <span>{{ 'shared.cronSchedule.texts.monthsOnThe' | translate }}</span>
                    <input class="form-control small-input ml-2" [(ngModel)]="$as.monthly(cronSchedule).day">
                    <span>. {{ 'shared.cronSchedule.texts.at' | translate }}</span>
                    <input class="form-control small-input mx-2" [(ngModel)]="$as.monthly(cronSchedule).hours">
                    <span>:</span>
                    <input class="form-control small-input ml-2" [(ngModel)]="$as.monthly(cronSchedule).minutes">
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="CronScheduleMode.Custom">
            <a ngbNavLink>{{ 'shared.cronSchedule._modes.' + CronScheduleMode.Custom | translate }}</a>
            <ng-template ngbNavContent>
                <div class="form-inline mt-2">
                    <span>{{ 'shared.cronSchedule.texts.custom' | translate }}:</span>
                    <input class="form-control mx-2" [(ngModel)]="$as.custom(cronSchedule).value">
                    <a href="https://www.freeformatter.com/cron-expression-generator-quartz.html" target="_blank">
                        <span>{{ 'shared.cronSchedule.editor.needHelp' | translate }}</span>
                        <i class="fas fa-external-link-alt ml-1"></i>
                    </a>
                </div>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <div class="mt-2">
        <button (click)="popover.close()" class="btn btn-secondary me-2" type="button">{{ 'shared.cronSchedule.editor.buttons.cancel' | translate }}</button>
        <button (click)="onSetClick(popover)" class="btn btn-success" type="button">{{ 'shared.cronSchedule.editor.buttons.set' | translate }}</button>
    </div>

</ng-template>
