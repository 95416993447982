<div class="page px-5">

    <div class="page__headline" eciTranslate="carrierSurcharges.title">
        Carrier Surcharges
    </div>

    <div class="row justify-content-center">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="onRowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: false, paging: true }"
                          [rowAlternationEnabled]="false"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop"></dxo-column-chooser>
                <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                <dxo-scrolling rowRenderingMode="virtual"></dxo-scrolling>
                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteCarrierSurcharge"
                    mode="form"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">
                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="zones"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item dataField="carrierProductId"></dxi-item>
                            <dxi-item dataField="deliveryOption"></dxi-item>
                            <dxi-item dataField="warehouseId"></dxi-item>
                            <dxi-item dataField="shipmentDirection"></dxi-item>
                            <dxi-item dataField="surchargeRuleId"></dxi-item>
                            <dxi-item dataField="dimensions"></dxi-item>
                            <dxi-item dataField="limit"></dxi-item>
                            <dxi-item dataField="postCodeListId"></dxi-item>
                            <dxi-item dataField="description"></dxi-item>
                            <dxi-item dataField="fixedSurcharge"></dxi-item>
                            <dxi-item dataField="localCurrency"></dxi-item>
                            <dxi-item dataField="percentageSurcharge"></dxi-item>
                            <dxi-item dataField="activeFrom"></dxi-item>
                            <dxi-item dataField="activeTo"></dxi-item>
                            <dxi-item dataField="validFrom"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother" class="me-3"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary me-2"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary me-2"
                                            role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-right">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            [caption]="'carrierSurcharges.table.headers.id' | translate"
                            [width]="120"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column dataField="countryId"
                            [fixed]="true"
                            [width]="120"
                            sortOrder="asc"
                            [setCellValue]="resetCarrierCarrierProductCell"
                            [caption]="'carrierSurcharges.table.headers.country' | translate"
                            [calculateFilterExpression]="calculateCountryFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countryStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateZoneFilterExpression"
                            [caption]="'carrierSurcharges.table.headers.zone' | translate"
                            editCellTemplate="zoneTagBoxEditor"
                            cellTemplate="zonesCellTemplate"
                            [fixed]="true"
                            [width]="100"
                            dataField="zones">
                    <dxo-lookup [dataSource]="getFilteredZones"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="carrierId"
                            [fixed]="true"
                            [width]="150"
                            [setCellValue]="resetCarrierProductCell"
                            [editorOptions]="{ showClearButton: true }"
                            [caption]="'carrierSurcharges.table.headers.carrier' | translate"
                            [calculateFilterExpression]="calculateCarrierFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarriers"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="carrierProductId"
                            [fixed]="true"
                            [width]="150"
                            [caption]="'carrierSurcharges.table.headers.carrierProduct' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            [allowEditing]="editableCarrierProduct"
                            [setCellValue]="disableLimitCell"
                            [calculateFilterExpression]="calculateCarrierProductFilterExpression">
                    <dxo-lookup [dataSource]="getFilteredCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                    <dxi-validation-rule
                        type="custom"
                        message="Required when using a oversize/overweight rule and not using a limit"
                        [validationCallback]="carrierProductValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                </dxi-column>

                <dxi-column [width]="100"
                            [caption]="'carrierSurcharges.table.headers.deliveryOption' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            dataField="deliveryOption">
                    <dxo-lookup [dataSource]="deliveryOptions"
                                displayExpr="value"
                                valueExpr="value"
                                [allowClearing]="true">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="warehouseId"
                            [fixed]="true"
                            [width]="150"
                            [caption]="'carrierSurcharges.table.headers.warehouse' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            [calculateFilterExpression]="calculateWarehouseFilterExpression">
                    <dxo-lookup [dataSource]="warehouseStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                    <dxi-validation-rule
                        type="custom"
                        message="Required when using a Collection rule"
                        [validationCallback]="collectionWarehouseValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                </dxi-column>

                <dxi-column [caption]="'carrierSurcharges.table.headers.shipmentDirection' | translate"
                            [fixed]="true"
                            [width]="100"
                            dataField="shipmentDirection">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="shipmentDirections"
                                displayExpr="name"
                                valueExpr="name">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [width]="150"
                            [caption]="'carrierSurcharges.table.headers.surchargeRule' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            [setCellValue]="resetDimensionsPostalCodeLimitCell"
                            dataField="surchargeRuleId"
                            [calculateFilterExpression]="calculateSurchargeRuleFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="surchargeRuleStore"
                                displayExpr="description"
                                valueExpr="id"
                                [allowClearing]="true">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'carrierSurcharges.table.headers.dimensions' | translate"
                            [width]="120"
                            [allowEditing]="editableDimensions"
                            [allowHeaderFiltering]="false"
                            editCellTemplate="dimensionTagBoxEditor"
                            cellTemplate="dimensionCellTemplate"
                            dataField="dimensions">
                    <dxo-lookup [dataSource]="dimensionStore"
                                displayExpr="value"
                                valueExpr="value">
                    </dxo-lookup>
                    <dxi-validation-rule
                        type="custom"
                        message="Required when  using a oversize/overweight rule"
                        [validationCallback]="dimensionsValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                </dxi-column>

                <dxi-column [allowHeaderFiltering]="false"
                            [width]="60"
                            [caption]="'carrierSurcharges.table.headers.limit' | translate"
                            [setCellValue]="disableCarrierProductCell"
                            [allowEditing]="editableLimit"
                            [editorOptions]="{ showClearButton: true }"
                            dataType="number"
                            dataField="limit">
                    <dxi-validation-rule
                        type="custom"
                        message="Required when using a oversize/overweight rule and not using a carrier product"
                        [validationCallback]="limitValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                </dxi-column>

                <dxi-column [width]="100"
                            [caption]="'carrierSurcharges.table.headers.postCodeList' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            [allowEditing]="editablePostalCode"
                            dataField="postCodeListId"
                            [calculateFilterExpression]="calculatePostCodeListFilterExpression">
                    <dxo-lookup [dataSource]="getFilteredPostCodeLists"
                                displayExpr="name"
                                valueExpr="id"
                                [allowClearing]="true">
                    </dxo-lookup>
                    <dxi-validation-rule
                        type="custom"
                        message="Required when using a PostCode surcharge rule"
                        [validationCallback]="postCodeValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                </dxi-column>

                <dxi-column [allowHeaderFiltering]="false"
                            [width]="100"
                            [caption]="'carrierSurcharges.table.headers.description' | translate"
                            dataField="description">
                </dxi-column>

                <dxi-column [allowHeaderFiltering]="false"
                            [width]="90"
                            [caption]="'carrierSurcharges.table.headers.fixedSurcharge' | translate"
                            [allowEditing]="editableFixedSurcharge"
                            [setCellValue]="disablePercentageSurchargeCell"
                            dataField="fixedSurcharge"
                            [editorOptions]="{ format: '#0.####', showClearButton: true, step: 0 }"
                            dataType="number">
                    <dxi-validation-rule
                        type="custom"
                        message="Required when not using percentage surcharge"
                        [validationCallback]="fixedValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                </dxi-column>

                <dxi-column [width]="90"
                            [caption]="'carrierSurcharges.table.headers.localCurrency' | translate"
                            [allowEditing]="editableCurrency"
                            dataField="localCurrency">
                    <dxi-validation-rule
                        type="custom"
                        message="Required when using fixed surcharge"
                        [validationCallback]="currencyValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                    <dxo-lookup [dataSource]="currencies"
                                displayExpr="value"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [width]="90"
                            [allowHeaderFiltering]="false"
                            [caption]="'carrierSurcharges.table.headers.percentageSurcharge' | translate"
                            [editorOptions]="{ format: '#0.##', showClearButton: true, step: 0 }"
                            [allowEditing]="editablePercentageSurcharge"
                            [setCellValue]="disableFixedSurchargeCell"
                            dataType="number"
                            dataField="percentageSurcharge">
                    <dxi-validation-rule
                        type="custom"
                        message="Required when not using fixed surcharge"
                        [validationCallback]="percentageValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                </dxi-column>

                <dxi-column [width]="100"
                            [caption]="'carrierSurcharges.table.headers.activeFrom' | translate"
                            [allowEditing]="editableActive"
                            dataField="activeFrom"
                            dataType="date"
                            format="yyyy-MM-dd"
                            [editorOptions]="{ dateSerializationFormat: 'yyyy-MM-dd' }"
                            sortOrder="desc">
                    <dxi-validation-rule
                        type="custom"
                        message="Required when using Peak surcharge rule"
                        [validationCallback]="activeValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                </dxi-column>

                <dxi-column [width]="100"
                            [caption]="'carrierSurcharges.table.headers.activeTo' | translate"
                            [allowEditing]="editableActive"
                            dataField="activeTo"
                            dataType="date"
                            format="yyyy-MM-dd"
                            [editorOptions]="{ dateSerializationFormat: 'yyyy-MM-dd' }"
                            sortOrder="desc">
                    <dxi-validation-rule
                        type="custom"
                        message="Required when using Peak surcharge rule"
                        [validationCallback]="activeValidation"
                        [reevaluate]="true">
                    </dxi-validation-rule>
                    <dxi-validation-rule
                        type="custom"
                        [reevaluate]="true"
                        [validationCallback]="toAfterFrom"
                        message="Active to must be after active from"
                    ></dxi-validation-rule>
                </dxi-column>

                <dxi-column [width]="100"
                            [caption]="'carrierSurcharges.table.headers.validFrom' | translate"
                            dataField="validFrom"
                            dataType="date"
                            format="yyyy-MM-dd"
                            [editorOptions]="{ dateSerializationFormat: 'yyyy-MM-dd' }"
                            sortOrder="desc">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

                <div *dxTemplate="let cellInfo of 'dimensionTagBoxEditor'">
                    <dx-tag-box
                        [placeholder]="'carrierSurcharges.table.headers.dimensions' | translate"
                        [dataSource]="dimensionStore"
                        [showSelectionControls]="true"
                        [value]="cellInfo.value"
                        [valueExpr]="'value'"
                        [displayExpr]="'value'"
                        (onValueChanged)="cellInfo.setValue($event.value)"
                        applyValueMode="useButtons"
                        [searchEnabled]="true">
                    </dx-tag-box>
                </div>

                <div *dxTemplate="let cellInfo of 'dimensionCellTemplate'">
                    <ul style="list-style-type: none;margin: 0">
                        <li *ngFor="let item of cellInfo.data['dimensions']">
                            <h5 style="margin: 0;font-size: 15px"><span class="badge rounded-pill bg-secondary">{{ item }}</span></h5>
                        </li>
                    </ul>
                </div>

                <div *dxTemplate="let cellInfo of 'zoneTagBoxEditor'">
                    <dx-tag-box
                        [placeholder]="'determinationRatio.table.headers.zone' | translate"
                        [dataSource]="filteredZones"
                        [showSelectionControls]="true"
                        [value]="cellInfo.value"
                        [valueExpr]="'id'"
                        [displayExpr]="'name'"
                        (onValueChanged)="cellInfo.setValue($event.value)"
                        applyValueMode="useButtons"
                        [searchEnabled]="true">
                    </dx-tag-box>
                </div>

                <div *dxTemplate="let cellInfo of 'zonesCellTemplate'">
                    <ul style="list-style-type: none;margin: 0">
                        <li *ngFor="let item of cellInfo.data['zones']">
                            <h5 style="margin: 0;font-size: 15px"><span class="badge rounded-pill bg-secondary">{{ getZoneName(item) }}</span></h5>
                        </li>
                    </ul>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
