import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { BackupShippingTypeAddressesComponent } from './backup-shipping-type-addresses.component';

export const BACKUP_SHIPPING_TYPE_ADDRESS_ROUTE: Route = {
    path: 'backup-shipping-type-addresses',
    component: BackupShippingTypeAddressesComponent,
    data: {
        roles: [],
        pageTitle: 'backupShippingTypeAddresses.title',
    },
    canActivate: [EciAuthGuard],
};
