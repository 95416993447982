import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { ZoneComponent } from './zone.component';

export const ZONE_ROUTE: Route = {
    path: 'zone',
    component: ZoneComponent,
    data: {
        roles: [],
        pageTitle: 'zone.title',
    },
    canActivate: [EciAuthGuard],
};
