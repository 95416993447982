import { Component, OnInit } from '@angular/core';
import { CommonView } from '../../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from 'src/app/shared/models/privilege.enum';
import CustomStore from 'devextreme/data/custom_store';
import { DxLookupDataSourceOptions, DxUtil } from '../../shared/util/dx.util';
import { PostCodeListsService } from '../../postcodelists/post-code-lists.service';
import { CarrierProductsService } from '../../carrier-products/carrier-products.service';
import { CarriersService } from '../../carriers/carriers.service';
import { CountriesService } from '../../countries/countries.service';
import { WarehouseService } from '../../warehouse/warehouse.service';
import { Country } from '../../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { CarrierDeterminationService } from '../carrier-determination.service';
import { Carrier } from '../../shared/models/carrier.model';
import { CarrierPostcode } from '../../shared/models/carrier-post-code-list.model';

@Component({
    selector: 'kep-search',
    templateUrl: './carrier-post-code-list.component.html',
})
export class CarrierPostCodeListComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100];

    postCodeListStore: CustomStore;
    carrierStore: CustomStore;
    countriesStore: CustomStore;
    homeDeliveryOptionsStore: CustomStore;
    warehouseStore: CustomStore;
    editableColumns = false;

    resetCarrierAndPostCodeListCell = DxUtil.hookSetCellValue<CarrierPostcode>(rowData => {
        rowData.carrierId = null;
        rowData.postCodeListId = null;
    });
    resetPostCodeListCell = DxUtil.hookSetCellValue<CarrierPostcode>(rowData => {
        rowData.postCodeListId = null;
    });

    constructor(
      private carrierDeterminationService: CarrierDeterminationService,
      private postCodeListsService: PostCodeListsService,
      private carrierProductsService: CarrierProductsService,
      private carrierService: CarriersService,
      private countriesService: CountriesService,
      private warehouseService: WarehouseService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.carrierDeterminationService.getCarrierPostCodes();
        this.postCodeListStore = this.postCodeListsService.getPostcodeListsAsCustomStore();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
        this.homeDeliveryOptionsStore = this.carrierProductsService.getHomeDeliveryOptions();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
    }

    initRow(e) {
        this.editableColumns = true;
        e.data = this.currentRowData;
    }

    editRow() {
        this.editableColumns = false;
    }

    getFilteredCarriers = (options: DxLookupDataSourceOptions<CarrierPostcode>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierPostcode, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    getFilteredPostCodeLists = (options: DxLookupDataSourceOptions<CarrierPostcode>) => {
        if (options.data == null) {
            return { store: this.postCodeListStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierPostcode, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<CarrierPostcode, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
          DxFilter.equals('country.id', country ? country.id : null),
          DxFilter.or(
              carrier ? DxFilter.equals('carrier.id', carrier.id) : DxFilter.isNull('carrier.id'),
              DxFilter.isNull('carrier.id'),
          ),
        );

        return {
            store: this.postCodeListStore,
            filter,
        };
    };

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculatePostCodeListFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['postCodeList.id', filterOperation, filterValue];
    }
}
