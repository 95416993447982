import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CountriesComponent } from './countries.component';

export const COUNTRIES_ROUTE: Route = {
    path: 'countries',
    component: CountriesComponent,
    data: {
        roles: [],
        pageTitle: 'countries.title',
    },
    canActivate: [EciAuthGuard],
};
