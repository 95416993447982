import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../shared/util/common.service';

@Injectable()
export class CarrierProductDistributionTimesService {

    private readonly resourceUrl = 'api/carrier-product-distribution-times';

    constructor(
        private dxService: EciDxService,
        private commonService: CommonService,
    ) { }

    getDistributionTimes(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

}
