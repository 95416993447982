import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ArticleServicesRulesService } from './article-services-rules.service';
import { CommonService } from '../shared/util/common.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
      ArticleServicesRulesService,
      CommonService,
  ],
})
export class ArticleServicesRulesModule { }
