import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarrierPricesComponent } from './carrier-prices.component';

export const CARRIER_PRICES_ROUTE: Route = {
    path: 'carrier-prices',
    component: CarrierPricesComponent,
    data: {
        roles: [],
        pageTitle: 'carrierPrices.title',
    },
    canActivate: [EciAuthGuard],
};
