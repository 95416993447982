import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { ClosureJobsComponent } from './closure-jobs.component';

export const CLOSURE_JOBS_ROUTE: Route = {
    path: 'closure-jobs',
    component: ClosureJobsComponent,
    data: {
        roles: [],
        pageTitle: 'closureJobs.title',
    },
    canActivate: [EciAuthGuard],
};
