export enum Privilege {
    ReadMasterData = 'READ_MASTERDATA',
    WriteCarrier = 'WRITE_CARRIER',
    WriteCarrierProduct = 'WRITE_CARRIER_PRODUCT',
    WriteClosureJob = 'WRITE_CLOSURE_JOB',
    WriteArticleRule = 'WRITE_ARTICLE_RULE',
    WritePackageMaterialDefinition = 'WRITE_PACKAGE_MATERIAL_DEFINITION',
    WriteCarrierAllocation = 'WRITE_CARRIER_ALLOCATION',
    WritePostcode = 'WRITE_POSTCODE',
    WriteSendItLabelAndClosure = 'WRITE_SEND_IT_LABEL_AND_CLOSURE',
    WriteLabelServiceConfig = 'WRITE_LABEL_SERVICE_CONFIG',
    WriteBackupShippingTypeAddress = 'WRITE_BACKUP_SHIPPING_TYPE_ADDRESS',
    WriteCountry = 'WRITE_COUNTRY',
    WriteWarehouse = 'WRITE_WAREHOUSE',
    WriteCpt = 'WRITE_CPT',
    WriteCarrierDistributionTimes = 'WRITE_CARRIER_PRODUCT_DISTRIBUTION_TIME',
    WriteArticleBrickCodeRules = 'WRITE_ARTICLE_BRICK_CODE_RULE',
    WriteArticleServicesRules = 'WRITE_ARTICLE_SERVICES_RULE',
    WriteAdditionalService = 'WRITE_ADDITIONAL_SERVICE',
    WriteTrackingEvent = 'WRITE_TRACKING_EVENT',
    WriteTrackingEventCodeMapping = 'WRITE_TRACKING_EVENT_CODE_MAPPING',
    WriteCarrierPrice = 'WRITE_CARRIER_PRICE',
    WriteCarrierDowntime = 'WRITE_CARRIER_DOWNTIME',
    ReadParcelCost = 'READ_PARCEL_COST',
    WritePackagingMaterial = 'WRITE_PACKAGING_MATERIAL',
    WritePackagingMaterialAvailability = 'WRITE_PACKAGING_MATERIAL_AVAILABILITY',
    WriteDeterminationRatio = 'WRITE_DETERMINATION_RATIO',
    WriteGeneralDeterminationQuantityLimits = 'WRITE_GENERAL_DETERMINATION_QUANTITY_LIMITS',
    WriteFallBackCarriers = 'WRITE_FALL_BACK_CARRIER',
    WriteCarrierSurcharge = 'WRITE_CARRIER_SURCHARGE',
    WriteCarrierSurchargeRule = 'WRITE_CARRIER_SURCHARGE_RULE',
}
