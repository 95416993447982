import { Component, OnInit } from '@angular/core';
import { CommonView } from '../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../shared/models/privilege.enum';
import { ActiveSessionsService } from './active-sessions.service';

@Component({
    selector: 'kep-active-sessions',
    templateUrl: './active-sessions.component.html',
})
export class ActiveSessionsComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    constructor(
        private activeSessionsService: ActiveSessionsService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.activeSessionsService.getActiveSessions();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    revokeAll() {
        this.activeSessionsService.revokeAllOtherSessions();
    }
}
