import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { FallBackCarriersComponent } from './fall-back-carriers.component';

export const FALL_BACK_CARRIERS_ROUTE: Route = {
    path: 'fall-back-carriers',
    component: FallBackCarriersComponent,
    data: {
        roles: [],
        pageTitle: 'fallBackCarriers.title',
    },
    canActivate: [EciAuthGuard],
};
