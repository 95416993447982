import { DxDataGridComponent } from 'devextreme-angular';
import { ViewChild, Component } from '@angular/core';
import { DialoguesComponent } from '../dialogues/dialogues.component';
import { DxUtil } from './dx.util';

@Component({
    template: '',
})
export class CommonView {

    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

    isSaving = false;
    createAnother = false;
    isDirty = false;

    currentRowData = null;

    constructor() {
        this.cloneRow = this.cloneRow.bind(this);
        this.delete = this.delete.bind(this);
    }

    save() {
        this.isSaving = true;
        this.dataGrid.instance.saveEditData().then(() => {
            this.currentRowData = null;
            this.isSaving = false;
            if (this.createAnother) {
                this.createAnother = false;
                this.dataGrid.instance.addRow();
            }
        });
    }

    cancel() {
        if (!this.isDirty) {
            this.createAnother = false;
            this.dataGrid.instance.cancelEditData();
        } else {
            DialoguesComponent.unsavedChangesDialogue().then(result => {
                if (result) {
                    this.currentRowData = null;
                    this.createAnother = false;
                    this.isDirty = false;
                    this.dataGrid.instance.cancelEditData();
                }
            });
        }
    }

    cloneRow(e) {
        e.row.data.id = null;
        this.currentRowData = e.row.data;
        this.dataGrid.instance.addRow();
        this.currentRowData = null;
        // When cloning the first element of the grid, the editor does not open and a refresh is needed
        if (e.row.rowIndex === 0) {
            this.dataGrid.instance.refresh();
        }
    }

    rowUpdate(e) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    delete(e) {
        DialoguesComponent.deleteDialog().then(result => {
            if (result) {
                this.createAnother = false;
                this.dataGrid.instance.deleteRow(e.row.rowIndex);
            }
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'gridHeader',
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: () => this.dataGrid.instance.refresh(),
                },
            });
    }

    onEditorPreparing(e) {
        e.editorOptions.onValueChanged = data => {
            e.setValue(data.value);
            this.isDirty = true;
        };
    }

    resetFilter() {
        this.dataGrid.instance.clearFilter();
    }
}

