import { CommonView } from '../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { CarriersService } from '../carriers/carriers.service';
import { ArticleRulesService } from './article-rules.service';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { Privilege } from '../shared/models/privilege.enum';
import { CarrierProductsService } from '../carrier-products/carrier-products.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { CountriesService } from '../countries/countries.service';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { ArticleRule } from '../shared/models/article-rule.model';
import { Country } from '../shared/models/country.model';
import { Carrier } from '../shared/models/carrier.model';
import { EciToastService } from '@shared-components/shopsys-commons-ui';

@Component({
    selector: 'kep-article-rules',
    templateUrl: './article-rules.component.html',
})
export class ArticleRulesComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    selectedCountries = [];
    selectedWarehouses = [];
    selectedCarriers = [];
    selectedCarrierProducts = [];

    possibleCountries: CustomStore;
    possibleWarehouses: CustomStore;
    possibleCarriers: CustomStore;
    possibleCarrierProducts: CustomStore;

    carrierStore: CustomStore;
    carrierProductsStore: CustomStore;
    warehouseStore: CustomStore;
    countriesStore: CustomStore;
    articleName: String;

    articleNumberPattern: any = /^\d+$/i;

    resetCarrierProductCell = DxUtil.hookSetCellValue<ArticleRule>(rowData => {
        rowData.carrierProductId = null;
    });

    resetWarehouseCell = DxUtil.hookSetCellValue<ArticleRule>(rowData => {
        rowData.warehouseId = null;
    });

    constructor(
        private articleRulesService: ArticleRulesService,
        private carrierService: CarriersService,
        private carrierProductsService: CarrierProductsService,
        private warehouseService: WarehouseService,
        private countriesService: CountriesService,
        private toastService: EciToastService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.articleRulesService.getArticleRules();

        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.carrierProductsStore = this.carrierProductsService.getCarrierProductsAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();

        this.reloadFilter();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    onRowUpdate(e: DxRowUpdateEvent<ArticleRule>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<ArticleRule>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<ArticleRule, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<ArticleRule, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    getFilteredCarriers = (options: DxLookupDataSourceOptions<ArticleRule>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<ArticleRule, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.carrier.id', filterOperation, filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.id', filterOperation, filterValue];
    }

    resetFilter(): void {
        super.resetFilter();

        this.reloadFilter();
        this.selectedCountries = [];
        this.selectedWarehouses = [];
        this.selectedCarriers = [];
        this.selectedCarrierProducts = [];
    }

    onFilterChange() {
        this.dataGrid.instance.clearFilter();
        this.reloadFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('warehouseId', 'filterValues', this.selectedWarehouses);
        this.dataGrid.instance.columnOption('carrierId', 'filterValues', this.selectedCarriers);
        this.dataGrid.instance.columnOption('carrierProductId', 'filterValues', this.selectedCarrierProducts);
    }

    reloadFilter() {
        this.possibleCountries = this.articleRulesService.getPossibleCountries(this.selectedWarehouses, this.selectedCarriers, this.selectedCarrierProducts);
        this.possibleWarehouses = this.articleRulesService.getPossibleWarehouses(this.selectedCountries, this.selectedCarriers, this.selectedCarrierProducts);
        this.possibleCarriers = this.articleRulesService.getPossibleCarriers(this.selectedCountries, this.selectedWarehouses, this.selectedCarrierProducts);
        this.possibleCarrierProducts = this.articleRulesService.getPossibleCarrierProducts(this.selectedCountries, this.selectedWarehouses,
                                                                                           this.selectedCarriers);
    }

    onEditorPreparing(event) {
        super.onEditorPreparing(event);
        this.articleName = '';
        const articleNumber = event.row.data != null ? event.row.data['articleNumber'] : '';
        if (event.dataField === 'articleNumber') {
            event.editorOptions.onValueChanged = (args: any) => {
                event.setValue(args.value);
                if ( args.value !== '') {
                    this.getArticleName(args.value, event);
                }
            };
        }

        if (event.dataField === 'articleName' && articleNumber !== '' && event.row.isNewRow === undefined) {

            if (event.editorOptions.value === '' && this.articleName != null) {
                this.getArticleName(articleNumber, event);
            }
        }
    }

    getArticleName(articleNumber, event) {
        this.articleRulesService.getArticleName(articleNumber).subscribe(
            data => {
                if (data.displayName == null || data.displayName === '') {
                    this.articleName = null;
                    this.toastService.translatedErrorToast('articleRules.errors.articleNotfound');
                } else {
                    this.articleName = articleNumber;
                    event.component.cellValue(event.row.rowIndex, 'articleName', data.displayName);
                }
            },
        );
    }

}
