import { custom } from 'devextreme/ui/dialog';

export class DialoguesComponent {

    static unsavedChangesDialogue() {
        const cancelDialog = custom({
            title: 'Unsaved changes',
            messageHtml: 'There are unsaved changes left. Do you really want to cancel the process? <br>All unsaved changes will be lost.',
            buttons: [{
                text: 'Back',
                type: 'normal',
                onClick: () => {
                    return false;
                },
            },
            {
                text: 'Cancel',
                type: 'danger',
                onClick: () => {
                    return true;
                },
            }],
        });

        return cancelDialog.show();
    }

    static deleteDialog() {
        const deleteDialog = custom({
            title: 'Delete entry',
            messageHtml: 'Do you really want to delete this entry?',
            buttons: [{
                text: 'Cancel',
                type: 'normal',
                onClick: () => {
                    return false;
                },
            },
            {
                text: 'Delete',
                type: 'danger',
                onClick: () => {
                    return true;
                },
            }],
        });

        return deleteDialog.show();
    }
}
