import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { TrackingEventComponent } from './tracking-event.component';

export const TRACKING_EVENT_ROUTE: Route = {
    path: 'tracking-events',
    component: TrackingEventComponent,
    data: {
        roles: [],
        pageTitle: 'trackingEvents.title',
    },
    canActivate: [EciAuthGuard],
};
