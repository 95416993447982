import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

// @ts-ignore
import { Account, activationRoute, EciPrincipalService, EciTenantSwitcher } from '@shared-components/shopsys-commons-ui';
import { ActiveSessionsService } from '../active-sessions/active-sessions.service';

/**
 * A simple app-specific component.
 */
@Component({
    selector: 'kep-home',
    templateUrl: './home.component.html',
    styleUrls: ['home.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

    account: Account;
    tenant: string;
    subscription: Subscription;

    constructor(
        private principal: EciPrincipalService,
        private tenantSwitcher: EciTenantSwitcher,
        private activeSessionsService: ActiveSessionsService,
    ) { }

    ngOnInit() {
        this.principal.getIdentityAsync().then(account => {
            this.account = account;
            this.activeSessionsService.saveCurrentSession();
        });

        this.subscription = this.tenantSwitcher.getUserTenant().subscribe(tenant => {
            this.tenant = tenant.selectedTenant;
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    isAuthenticated(): boolean {
        return this.principal.isAuthenticated();
    }

}
