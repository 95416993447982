import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { TrackingEventCodeMappingComponent } from './tracking-event-code-mapping.component';

export const TRACKING_EVENT_CODE_MAPPING_ROUTE: Route = {
    path: 'tracking-event-code-mappings',
    component: TrackingEventCodeMappingComponent,
    data: {
        roles: [],
        pageTitle: 'trackingEventCodeMappings.title',
    },
    canActivate: [EciAuthGuard],
};
