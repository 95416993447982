import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarrierPercentageComponent } from './carrier-percentage.component';

export const CARRIER_PERCENTAGE_ROUTE: Route = {
    path: 'carrier-determination/percentage',
    component: CarrierPercentageComponent,
    data: {
        roles: [],
        pageTitle: 'carrierDetermination.subtitle',
    },
    canActivate: [EciAuthGuard],
};
