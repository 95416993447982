import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { SurchargeOverviewComponent } from './surcharge-overview.component';

export const SURCHARGE_OVERVIEW_ROUTE: Route = {
    path: 'surcharge-overview',
    component: SurchargeOverviewComponent,
    data: {
        roles: [],
        pageTitle: 'parcelCosts.title',
    },
    canActivate: [EciAuthGuard],
};
