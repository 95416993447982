import { CommonView } from '../../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from 'src/app/shared/models/privilege.enum';
import { SendItLabelAndClosureConfiguration } from './send-it-label-and-closure-configuration.service';
import CustomStore from 'devextreme/data/custom_store';
import { CountriesService } from '../../countries/countries.service';

@Component({
    selector: 'kep-send-it-label-and-closure-config',
    templateUrl: './send-it-label-and-closure-configuration.component.html',
})

export class SendItLabelAndClosureConfigurationComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    datasource: DataSource;
    salesChannelStore: CustomStore;
    countriesStore: CustomStore;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    constructor(
        private sendItLabelAndClosureConfiguration: SendItLabelAndClosureConfiguration,
        private countriesService: CountriesService,
    ) {
        super();
    }

    ngOnInit() {
        this.datasource = this.sendItLabelAndClosureConfiguration.getSendItLabelAndClosureConfigurationData();
        this.salesChannelStore = this.sendItLabelAndClosureConfiguration.getSalesChannels();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['destinationCountry.id', filterOperation, filterValue];
    }

    rowUpdate(e): void {
        super.rowUpdate(e);
        super.resetFilter();
    }

}
