import { LoadOptions } from 'devextreme/data/';

export interface DxRowUpdateEvent<T = any> {
    oldData: T;
    newData: T;
}

export interface DxRowButtonClickEvent<T = any> {
    event: Event;
    row: {
        data: T;
    };
}

export interface DxLookupDataSourceOptions<TData> {
    cells: DxDataGridCell[];
    data: TData;
}

export interface DxDataGridCell<TValue = any> {
    column: DxDataGridColumn;
    component: DxDataGridComponent;
    rowType: string;
    value: TValue;
}

export interface DxDataGridComponent {
    getVisibleColumns(): DxDataGridColumn[];
}
export interface DxDataGridColumn {
    command: string;
    dataField: string;
    lookup?: DxDataGridColumnLookup;
}

export interface DxDataGridColumnLookup<TItem = any> {
    items: TItem[];
    valueExpr: string | ((item: TItem) => string);
}

export class DxUtil {
    static fillUpNewDataOnUpdate<T>(e: DxRowUpdateEvent<T>) {
        const newData = { ...e.oldData };

        if (e.newData != null) {
            Object.keys(e.newData).map(key => {
                newData[key] = e.newData[key];
            });
        }

        e.newData = newData;
    }

    /**
     * Fetch complete object of another column/dataField (which is based on a lookup)
     *
     * @example fetchLookupObject<CarrierProduct, Carrier>(options, 'carrierId')
     *
     * @param options Options provided by DevExtreme
     * @param dataField Data field to use as a reference for the nested entity
     *
     * @returns Complete object of requested dataField instead of id
     */
    static fetchLookupObject<TDataGridEntity, TLookupEntity>(
        options: DxLookupDataSourceOptions<TDataGridEntity>,
        dataField: keyof TDataGridEntity,
    ): TLookupEntity {
        let column: DxDataGridColumn = null;
        if (this.isInFormEdit(options)) {
            column = options.cells[0].component.getVisibleColumns().find(c => c.dataField === dataField);
        } else {
            column = options.cells.find((cell: DxDataGridCell<string>) => cell.column.dataField === dataField).column;
        }

        const resolveKey = item => typeof column.lookup.valueExpr === 'function'
            ? column.lookup.valueExpr(item)
            : column.lookup.valueExpr;

        return column != null
            ? column.lookup.items.find((item: TLookupEntity) => item[resolveKey(item)] === options.data[dataField])
            : null;
    }

    /**
     * Intercepts the "setCellValue" function and calls custom function before calling the original function.
     *
     * @param callback custom function to be called before original function by DevExtreme
     * @returns hooked function
     */
    static hookSetCellValue<TDataGridEntity>(callback: (rowData: TDataGridEntity) => void): Function {
        return function(newRowData: TDataGridEntity, value: unknown) {
            const that = <any> this; // Cell object

            callback(newRowData);

            that.defaultSetCellValue(newRowData, value);
        };
    }

    /**
     * Adds a flag to the load options object to load the complete dataset
     *
     * @param loadOptions DevExtreme load options
     * @returns DevExtreme load options with flag to load complete dataset
     */
    static loadAll(loadOptions: LoadOptions): any {
        // temporary fix until we find better solution/approach
        // DxService.query() does not support both filter and searchExpr, so we need to add search expr to filter
        if (!!loadOptions.filter && !!loadOptions.searchExpr) {
            // if dropdown has value
            if (!!loadOptions.searchValue) {
                const searchExprIndex = loadOptions.filter.findIndex(filter => filter[0] === loadOptions.searchExpr);
                // if filter already contains search expression update existing
                if (searchExprIndex !== -1) {
                    loadOptions.filter[searchExprIndex] = [loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue];
                } else {
                    loadOptions.filter.push('and');
                    loadOptions.filter.push([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]);
                }
            }
            loadOptions.searchExpr = null;
        }
        return { ...loadOptions, isLoadingAll: true };
    }

    private static isInFormEdit(options: DxLookupDataSourceOptions<unknown>): boolean {
        const cell = options.cells[0];
        return cell != null && cell.rowType === 'detail' && cell.column.command === 'detail';
    }
}
