import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarrierSurchargeRulesComponent } from './carrier-surcharge-rules.component';

export const CARRIER_SURCHARGE_RULES_ROUTE: Route = {
    path: 'carrier-surcharge-rules',
    component: CarrierSurchargeRulesComponent,
    data: {
        roles: [],
        pageTitle: 'carrierSurchargeRules.title',
    },
    canActivate: [EciAuthGuard],
};
