import { CronScheduleMode } from './cron-schedule-mode.enum';
import { CronScheduleWeekday } from './cron-schedule-weekday.enum';

const MINUTELY_REGEX = /0\s0\/(?<minutes>\d{1,2})\s\*\s\?\s\*\s\*\s\*/;
const HOURLY_REGEX = /0\s(?<minutes>\d{1,2})\s0\/(?<hours>\d{1,2})\s\?\s\*\s\*\s\*/;
const DAILY_REGEX = /0\s(?<minutes>\d{1,2})\s(?<hours>\d{1,2})\s1\/(?<days>\d{1,2})\s\*\s\?\s\*/;
const WEEKLY_REGEX = /0\s(?<minutes>\d{1,2})\s(?<hours>\d{1,2})\s\?\s\*\s(?<weekday>MON|TUE|WED|THU|FRI|SAT|SUN)\s\*/;
const MONTHLY_REGEX = /0\s(?<minutes>\d{1,2})\s(?<hours>\d{1,2})\s(?<day>\d{1,2})\s1\/(?<months>\d{1,2})\s\?\s\*/;

export abstract class CronSchedule {
    constructor(
        public mode: CronScheduleMode,
    ) { }

    static fromCronString(value: string): CronSchedule {
        const minutelyMatches = value.match(MINUTELY_REGEX);
        if (minutelyMatches != null && minutelyMatches.length > 0) {
            return new MinutelyCronSchedule(
                Number.parseInt(minutelyMatches.groups['minutes']),
            );
        }

        const hourlyMatches = value.match(HOURLY_REGEX);
        if (hourlyMatches != null) {
            if (hourlyMatches.length > 0) {
                return new HourlyCronSchedule(
                    Number.parseInt(hourlyMatches.groups['hours']),
                    Number.parseInt(hourlyMatches.groups['minutes']),
                );
            }
        }

        const dailyMatches = value.match(DAILY_REGEX);
        if (dailyMatches != null && dailyMatches.length > 0) {
            return new DailyCronSchedule(
                Number.parseInt(dailyMatches.groups['days']),
                Number.parseInt(dailyMatches.groups['hours']),
                Number.parseInt(dailyMatches.groups['minutes']),
            );
        }

        const weeklyMatches = value.match(WEEKLY_REGEX);
        if (weeklyMatches != null && weeklyMatches.length > 0) {
            return new WeeklyCronSchedule(
                weeklyMatches.groups['weekday'],
                Number.parseInt(weeklyMatches.groups['hours']),
                Number.parseInt(weeklyMatches.groups['minutes']),
            );
        }

        const monthlyMatches = value.match(MONTHLY_REGEX);
        if (monthlyMatches != null && monthlyMatches.length > 0) {
            return new MonthlyCronSchedule(
                Number.parseInt(monthlyMatches.groups['months']),
                Number.parseInt(monthlyMatches.groups['day']),
                Number.parseInt(monthlyMatches.groups['hours']),
                Number.parseInt(monthlyMatches.groups['minutes']),
            );
        }

        return new CustomCronSchedule(value);
    }

    static createDefaultInstanceByMode(mode: CronScheduleMode): CronSchedule {
        switch (mode) {
            case CronScheduleMode.Minutely:
                return new MinutelyCronSchedule(15);
            case CronScheduleMode.Hourly:
                return new HourlyCronSchedule(12, 0);
            case CronScheduleMode.Daily:
                return new DailyCronSchedule(3, 12, 0);
            case CronScheduleMode.Weekly:
                return new WeeklyCronSchedule(CronScheduleWeekday.Friday, 12, 0);
            case CronScheduleMode.Monthly:
                return new MonthlyCronSchedule(2, 1, 12, 0);
            case CronScheduleMode.Custom:
                return new CustomCronSchedule('0 0/10 0 ? * * *');
        }
    }

    abstract toCronString(): string;
}

export class MinutelyCronSchedule extends CronSchedule {
    constructor(
        public minutes: number,
    ) {
        super(CronScheduleMode.Minutely);
    }

    toCronString() {
        return `0 0/${this.minutes} * ? * * *`;
    }
}

export class HourlyCronSchedule extends CronSchedule {
    constructor(
        public hours: number,
        public minutes: number,
    ) {
        super(CronScheduleMode.Hourly);
    }

    toCronString() {
        return `0 ${this.minutes} 0/${this.hours} ? * * *`;
    }
}

export class DailyCronSchedule extends CronSchedule {
    constructor(
        public days: number,
        public hours: number,
        public minutes: number,
    ) {
        super(CronScheduleMode.Daily);
    }

    toCronString() {
        return `0 ${this.minutes} ${this.hours} 1/${this.days} * ? *`;
    }
}

export class WeeklyCronSchedule extends CronSchedule {
    constructor(
        public weekday: string,
        public hours: number,
        public minutes: number,
    ) {
        super(CronScheduleMode.Weekly);
    }

    toCronString() {
        return `0 ${this.minutes} ${this.hours} ? * ${this.weekday} *`;
    }
}

export class MonthlyCronSchedule extends CronSchedule {
    constructor(
        public months: number,
        public day: number,
        public hours: number,
        public minutes: number,
    ) {
        super(CronScheduleMode.Monthly);
    }

    toCronString() {
        return `0 ${this.minutes} ${this.hours} ${this.day} 1/${this.months} ? *`;
    }
}

export class CustomCronSchedule extends CronSchedule {
    constructor(
        public value: string,
    ) {
        super(CronScheduleMode.Custom);
    }

    toCronString() {
        return this.value;
    }
}
