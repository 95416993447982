import { Component, OnInit } from '@angular/core';
import { CommonView } from '../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { FallBackCarriersService } from './fall-back-carriers.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { CarriersService } from '../carriers/carriers.service';
import CustomStore from 'devextreme/data/custom_store';
import { CarrierDeterminationService } from '../carrier-determination/carrier-determination.service';
import { CountriesService } from '../countries/countries.service';
import { DeterminationRatio } from '../shared/models/determination-ratio.model';
import { Privilege } from '../shared/models/privilege.enum';
import { Country } from '../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';

@Component({
    selector: 'kep-fall-back-carriers',
    templateUrl: './fall-back-carriers.component.html',
})
export class FallBackCarriersComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    labelMode = 'floating';
    salesChannels: CustomStore;
    warehouseStore: CustomStore;
    countryStore: CustomStore;
    carrierStore: CustomStore;
    deliveryOptionStore: CustomStore;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    resetCarrierCell = DxUtil.hookSetCellValue<DeterminationRatio>(rowData => {
        rowData.carrierId = null;
    });

    constructor(
        private fallBackCarriersService: FallBackCarriersService,
        private countryService: CountriesService,
        private warehouseService: WarehouseService,
        private carrierService: CarriersService,
        private carrierDeterminationService: CarrierDeterminationService,

    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.fallBackCarriersService.getFallBackCarriers();
        this.countryStore = this.countryService.getCountriesAsCustomStore();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.deliveryOptionStore = this.carrierDeterminationService.getDeliveryOptions();
        this.salesChannels = this.carrierDeterminationService.getSalesChannels();
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    getFilteredCarrier = (options: DxLookupDataSourceOptions<DeterminationRatio>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<DeterminationRatio, Country>(options, 'countryId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
        );

        return {
            store: this.carrierStore,
            filter,
        };
    };

}
