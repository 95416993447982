import { SendItLabelAndClosureConfigurationComponent } from './send-it-label-and-closure-configuration.component';
import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';

export const SENDIT_LABEL_AND_CLOSURE_CONFIG: Route = {
    path: 'send-it-label-and-closure-config',
    component: SendItLabelAndClosureConfigurationComponent,
    data: {
        roles: [],
        pageTitle: 'sendItLabelAndClosureConfiguration.title',
    },
    canActivate: [EciAuthGuard],
};
