import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PostCodeListsService } from './post-code-lists.service';
import { PostCodeComponent } from './postcode/post-code.component';
import {
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxToolbarModule
} from 'devextreme-angular';
import { ShopsysCommonsModule } from '@shared-components/shopsys-commons-ui';

@NgModule({
    declarations: [PostCodeComponent],
    imports: [
        CommonModule,
        RouterModule,
        DxToolbarModule,
        DxFileUploaderModule,
        ShopsysCommonsModule,
        DxDataGridModule,
        DxCheckBoxModule,
        DxButtonModule,
    ],
    providers: [
        PostCodeListsService,
    ],
})
export class PostCodeListsModule {
}
