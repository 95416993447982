import { Component, OnInit } from '@angular/core';
import { CommonView } from '../../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../../shared/models/privilege.enum';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../../shared/util/dx.util';
import CustomStore from 'devextreme/data/custom_store';
import { CarrierPricesService } from './carrier-prices.service';
import { CarrierPrice } from '../../shared/models/carrier-price.model';
import { ShipmentDirection } from '../../shared/models/shipment-direction.model';
import { CountriesService } from '../../countries/countries.service';
import { WarehouseService } from '../../warehouse/warehouse.service';
import { CarriersService } from '../../carriers/carriers.service';
import { CommonService } from '../../shared/util/common.service';
import { Country } from '../../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { Carrier } from '../../shared/models/carrier.model';
import { CarrierProductsService } from '../../carrier-products/carrier-products.service';
import { Zone } from '../../shared/models/zone.model';
import { ZoneService } from '../../zone/zone.service';
import { ArticleBrickCodeRule } from '../../shared/models/article-brick-code-rule.model';

@Component({
    selector: 'kep-carrier-prices',
    templateUrl: './carrier-prices.component.html',
})
export class CarrierPricesComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    countryStore: CustomStore;
    carrierStore: CustomStore;
    carrierProductsStore: CustomStore;
    warehouseStore: CustomStore;
    shipmentDirections: ShipmentDirection[];
    rules: CustomStore;
    currencies: CustomStore;
    packageTypes: CustomStore;
    zonesStore: CustomStore;
    filteredZones: CustomStore;
    zones: Zone[];

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    resetCarrierProductCell = DxUtil.hookSetCellValue<CarrierPrice>(rowData => {
        rowData.carrierProductId = null;
    });

    constructor(
        private carrierPricesService: CarrierPricesService,
        private countriesService: CountriesService,
        private carrierService: CarriersService,
        private carrierProductService: CarrierProductsService,
        private warehouseService: WarehouseService,
        private commonService: CommonService,
        private zonesService: ZoneService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.carrierPricesService.getCarrierPrices();
        this.countryStore = this.countriesService.getCountriesAsCustomStore();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.carrierProductsStore = this.carrierProductService.getCarrierProductsAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.shipmentDirections = this.commonService.getShipmentDirection();
        this.currencies = this.carrierPricesService.getCustomCurrencies();
        this.packageTypes = this.carrierPricesService.getCustomPackageTypes();
        this.zonesStore = this.zonesService.getZonesAsCustomStore();
        this.filteredZones = this.zonesStore;
        this.zonesService.getZonesAsPromise().then(zones => this.zones = zones);
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierPrice>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }
    onEditorPreparing(e) {
        e.editorOptions.onValueChanged = data => {
            e.setValue(data.value);
            this.isDirty = true;
        };

        if (e.parentType === 'dataRow' && e.dataField === 'countryId') {
            if (!!e.value) {
                this.filteredZones = this.getZonesByCountryId(e.value);
            }
            e.editorOptions.onValueChanged = data => {
                e.setValue(data.value);
                this.isDirty = true;
                this.filteredZones = this.getZonesByCountryId(data.value);
            };
        }
    }

    getZonesByCountryId(countryId: string): CustomStore {
        if (!countryId) {
            return this.zonesStore;
        }
        return this.zonesService.getZonesByCountryAsCustomStore(countryId);
    }

    getZoneName(zoneId: string): string {
        if (!zoneId) {
            return null;
        }
        return this.zones.find( zone => zone.id === zoneId).name;
    }

    calculateZoneFilterExpression(filterValue: string): string[] {
        return ['zones', 'contains', filterValue];
    }

    getFilteredZones = (options: DxLookupDataSourceOptions<CarrierPrice>) => {
        if (options.data == null) {
            return { store: this.zonesStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierPrice, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.zonesStore,
            filter,
        };
    };

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    getFilteredCarriers = (options: DxLookupDataSourceOptions<CarrierPrice>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierPrice, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<CarrierPrice>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierPrice, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<CarrierPrice, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

}
