import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { AdditionalServicesComponent } from './additional-services.component';

export const ADDITIONAL_SERVICES_ROUTE: Route = {
    path: 'additional-services',
    component: AdditionalServicesComponent,
    data: {
        roles: [],
        pageTitle: 'additionalServices.title',
    },
    canActivate: [EciAuthGuard],
};
