import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PackageMaterialDefinitionService } from './package-material-definitions.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
      PackageMaterialDefinitionService,
  ],
})
export class PackageMaterialDefinitionsModule { }
