import { Component, OnInit } from '@angular/core';
import { CommonView } from '../../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../../shared/models/privilege.enum';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../../shared/util/dx.util';
import CustomStore from 'devextreme/data/custom_store';
import { SurchargeOverviewService } from './surcharge-overview.service';
import { CarrierPrice } from '../../shared/models/carrier-price.model';
import { ShipmentDirection } from '../../shared/models/shipment-direction.model';
import { CountriesService } from '../../countries/countries.service';
import { CarriersService } from '../../carriers/carriers.service';
import { CommonService } from '../../shared/util/common.service';
import { Country } from '../../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { Carrier } from '../../shared/models/carrier.model';
import { CarrierProductsService } from '../../carrier-products/carrier-products.service';
import { CarrierPricesService } from '../carrier-prices/carrier-prices.service';
import { ParcelCost } from '../../shared/models/parcel-cost';
import { WarehouseService } from '../../warehouse/warehouse.service';

@Component({
    selector: 'kep-surcharge-overview',
    templateUrl: './surcharge-overview.component.html',
})
export class SurchargeOverviewComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    countryStore: CustomStore;
    carrierStore: CustomStore;
    carrierProductsStore: CustomStore;
    warehouseStore: CustomStore;
    shipmentDirections: ShipmentDirection[];
    currencies: CustomStore;
    selectedCountries = [];
    selectedCarriers = [];
    selectedCarrierProducts = [];
    selectedWarehouses = [];
    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    resetCarrierProductCell = DxUtil.hookSetCellValue<ParcelCost>(rowData => {
        rowData.carrierProductId = null;
    });

    constructor(
        private parcelCostService: SurchargeOverviewService,
        private countriesService: CountriesService,
        private carrierService: CarriersService,
        private carrierProductService: CarrierProductsService,
        private carrierPricesService: CarrierPricesService,
        private warehouseService: WarehouseService,
        private commonService: CommonService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.parcelCostService.getParcelCosts();
        this.countryStore = this.countriesService.getCountriesAsCustomStore();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.carrierProductsStore = this.carrierProductService.getCarrierProductsAsCustomStore();
        this.shipmentDirections = this.commonService.getShipmentDirection();
        this.currencies = this.carrierPricesService.getCustomCurrencies();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierPrice>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculateLabelCreationDateFilterExpression(filterValue: string, filterOperation: string) {
        const date = new Date(filterValue).toISOString();
        return ['labelCreationDate', filterOperation, date];
    }

    getFilteredCarriers = (options: DxLookupDataSourceOptions<ParcelCost>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<ParcelCost, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<ParcelCost>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<ParcelCost, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<ParcelCost, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    getFilteredWarehouses = (options: DxLookupDataSourceOptions<ParcelCost>) => {
        if (options.data == null) {
            return { store: this.warehouseStore };
        }

        const warehouse = DxUtil.fetchLookupObject<ParcelCost, Country>(options, 'warehouseId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', warehouse ? warehouse.id : null),
        );

        return {
            store: this.warehouseStore,
            filter,
        };
    };

    resetFilter(): void {
        super.resetFilter();

        this.selectedCountries = [];
        this.selectedCarriers = [];
        this.selectedCarrierProducts = [];
        this.selectedWarehouses = [];
    }

    onFilterChange() {
        this.dataGrid.instance.clearFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('carrierProductId', 'filterValues', this.selectedCarrierProducts);
        this.dataGrid.instance.columnOption('carrierId', 'filterValues', this.selectedCarriers);
        this.dataGrid.instance.columnOption('warehouseId', 'filterValues', this.selectedWarehouses);
    }

}
