import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { HttpClient } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import { CommonService } from '../shared/util/common.service';
import { Observable } from 'rxjs';
import { Progress } from '../shared/models/progress.model';

@Injectable()
export class ArticleRulesService {

    private readonly resourceUrl = 'api/article-rules';

    private readonly articleResourceUrl = 'api/fetch-article-name';

    private readonly languageId = 'de_DE';

    constructor(
        private dxService: EciDxService,
        private http: HttpClient,
        private commonService: CommonService,
    ) { }

    getArticleRules(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getPossibleCountries(warehouseIds: string[], carrierIds: string[], carrierProductIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-countries?warehouseIds=` + warehouseIds.toString()
                + '&carrierIds=' + carrierIds.toString()
                + '&carrierProductIds=' + carrierProductIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getPossibleWarehouses(countryIds: string[], carrierIds: string[], carrierProductIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-warehouses?countryIds=` + countryIds.toString()
                + '&carrierIds=' + carrierIds.toString()
                + '&carrierProductIds=' + carrierProductIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getPossibleZones(countryIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-warehouses?countryIds=`
                + countryIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getPossibleCarriers(countryIds: string[], warehousesIds: string[], carrierProductIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-carriers?countryIds=` + countryIds.toString()
                + '&warehouseIds=' + warehousesIds.toString()
                + '&carrierProductIds=' + carrierProductIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getPossibleCarrierProducts(countryIds: string[], warehousesIds: string[], carrierIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-carrier-products?countryIds=` + countryIds.toString()
                + '&warehouseIds=' + warehousesIds.toString()
                + '&carrierIds=' + carrierIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getArticleName(articleNumber: string): Observable<Progress> {
        return this.http.get<Progress>(`${this.articleResourceUrl}/${articleNumber}/${this.languageId}`);
    }
}
