import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ActiveSessionsService } from './active-sessions.service';
import { CommonService } from '../shared/util/common.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
      ActiveSessionsService,
      CommonService,
  ],
})
export class ActiveSessionsModule { }
