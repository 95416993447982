import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { CommonService } from '../shared/util/common.service';
import CustomStore from 'devextreme/data/custom_store';

@Injectable()
export class GeneralDeterminationQuantityLimitsService {

    private readonly generalDeterminationQuantityLimitsResourceUrl = 'api/general-determination-quantity-limits';

    constructor(
        private http: HttpClient,
        private commonService: CommonService,
    ) {
    }

    getGeneralDeterminationQuantityLimits(): DataSource {
        return this.commonService.getDataSource(this.generalDeterminationQuantityLimitsResourceUrl);
    }

    getSalesChannels(): CustomStore {
        return this.commonService.getCustomSalesChannels();
    }

}
