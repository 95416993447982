import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { CommonService } from '../shared/util/common.service';
import CustomStore from 'devextreme/data/custom_store';

@Injectable()
export class PackagingMaterialAvailabilityService {

    private readonly packagingMaterialAvailabilityResourceUrl = 'api/packaging-material-availability';

    constructor(
        private http: HttpClient,
        private commonService: CommonService,
    ) {
    }

    getPackagingMaterialAvailability(): DataSource {
        return this.commonService.getDataSource(this.packagingMaterialAvailabilityResourceUrl);
    }

    getSalesChannels(): CustomStore {
        return this.commonService.getCustomSalesChannels();
    }

}
