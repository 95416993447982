import { Component, OnInit } from '@angular/core';
import { CommonView } from '../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../shared/models/privilege.enum';
import { DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import CustomStore from 'devextreme/data/custom_store';
import { PackagingMaterialsService } from './packaging-materials.service';
import { CarrierPrice } from '../shared/models/carrier-price.model';

@Component({
    selector: 'kep-packaging-materials',
    templateUrl: './packaging-materials.component.html',
})
export class PackagingMaterialsComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    labelMode = 'floating';
    packageTypes: CustomStore;
    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    constructor(
        private packagingMaterialService: PackagingMaterialsService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.packagingMaterialService.getPackagingMaterials();
        this.packageTypes = this.packagingMaterialService.getCustomPackageTypes();
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierPrice>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

}
