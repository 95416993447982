import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../shared/util/common.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxUtil } from '../shared/util/dx.util';
import { Observable } from 'rxjs';

@Injectable()
export class TrackingService {

    private readonly trackingEventResourceUrl = 'api/tracking-events';

    private readonly trackingEventCodeResourceUrl = 'api/tracking-event-code-mappings';

    constructor(
        private http: HttpClient,
        private translateService: EciTenantAwareLanguageService,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) {
    }

    getTrackingEvents(): DataSource {
        return this.commonService.getDataSource(this.trackingEventResourceUrl);
    }

    getTrackingEventCodeMappings(): DataSource {
        return this.commonService.getDataSource(this.trackingEventCodeResourceUrl);
    }

    getAllEvents(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.trackingEventResourceUrl}/events`).toPromise()
                .then(data => data.map(value => {
                    return { id: value, event: value };
                })),
        });
    }

    getAllSubEventsForEvent(event: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.trackingEventResourceUrl}/sub-events?events=` + event.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value, subEvent: value };
                })),
        });
    }

    getAllSubEvents(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.trackingEventResourceUrl}/sub-events/all`).toPromise()
                .then(data => data.map(value => {
                    return { id: value, subEvent: value };
                })),
        });
    }

    getTrackingEventsAsCustomStore(): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: loadOptions => this.dxService.query(this.trackingEventResourceUrl, DxUtil.loadAll(loadOptions))
                .then(x => x.data),
        });
    }

    getAllCarrierEventCodes(): Observable<string[]> {
        return this.http.get<string[]>(`${this.trackingEventCodeResourceUrl}/carrierEventCodes`);
    }
}
