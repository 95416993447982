import { CommonView } from '../../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import { Privilege } from 'src/app/shared/models/privilege.enum';
import { ActivatedRoute } from '@angular/router';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { CountriesService } from '../../countries/countries.service';
import notify from 'devextreme/ui/notify';
import { EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { Location } from '@angular/common';
import { PostCodeListsService } from '../post-code-lists.service';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';

@Component({
    selector: 'kep-search',
    templateUrl: './post-code.component.html',
})
export class PostCodeComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75];

    dataSource: DataSource;
    countriesStore: CustomStore;
    baseUrl = '/api/post-code/list/';
    uploadUrlParam = '/upload';

    parentListId: string;
    pageTitle = 'Postcode Whitelist';
    countryId: string;
    uploadUrl: string;
    backButtonOptions: any;

    constructor(
        private postCodeListsService: PostCodeListsService,
        private countriesService: CountriesService,
        private route: ActivatedRoute,
        private translateService: EciTenantAwareLanguageService,
        private location: Location) {
        super();
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.parentListId = params['listId'];
            this.pageTitle = params['title'];
            this.countryId = params['country'];
            this.uploadUrl = this.baseUrl + this.parentListId + this.uploadUrlParam;
            this.dataSource = this.postCodeListsService.getPostCodesForListId(this.parentListId);
        });
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();

        this.backButtonOptions = {
            type: 'back',
            onClick: () => {
                this.location.back();
            },
        };
    }

    initRow(e) {
        if (this.currentRowData === null) {
            e.data = {
                postCodeListId: this.parentListId,
                countryId: this.countryId,
            };
        } else {
            e.data = this.currentRowData;
        }
    }

    onFileUploaded() {
        this.dataGrid.instance.refresh();
        notify(
            {
                message: this.translateService.get('postalCodeLists.messages.postCode.uploadSuccess'),
                width: 230,
                animation: {
                    show: {
                        type: 'fade', duration: 400, from: 0, to: 1,
                    },
                    hide: {
                        type: 'fade', duration: 200, to: 0,
                    },
                },
                position: {
                    at: 'center',
                    my: 'top',
                    of: '#container',
                },
            },
            'success',
            2000,
        );
    }

    onFileUploadError(e) {
        const errorMessage = this.translateService.get(JSON.parse(e.error.responseText).errorKey);
        notify(
            {
                message: errorMessage,
                width: 500,
                animation: {
                    show: {
                        type: 'fade', duration: 400, from: 0, to: 1,
                    },
                    hide: {
                        type: 'fade', duration: 400, to: 0,
                    },
                },
                position: {
                    at: 'center',
                    my: 'top',
                    of: '#container',
                },
            },
            'error',
            4000,
        );
    }

    onExporting(e: ExportingEvent) {
        const workbook = new Workbook();

        exportDataGrid({
            component: e.component,
            worksheet: workbook.addWorksheet('Main sheet'),
            customizeCell: options => {
                options.excelCell.font = { name: 'Arial', size: 12 };
                options.excelCell.alignment = { horizontal: 'left' };
            },
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer: BlobPart) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PostCodes.xlsx');
                });
        });
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }
}
