import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarriersComponent } from './carriers.component';

export const CARRIERS_ROUTE: Route = {
    path: 'carriers',
    component: CarriersComponent,
    data: {
        roles: [],
        pageTitle: 'carriers.title',
    },
    canActivate: [EciAuthGuard],
};
