<div class="page px-5">

    <div class="page__headline" eciTranslate="trackingEvents.title">
        Tracking Events
    </div>

    <div class="page__filter mb-2">
        <div class="row justify-content-center">
            <div class="col-1 mt-2">
                <h5>Filters:</h5>
            </div>
            <div class="col-lg-3">
                <dx-tag-box [placeholder]="'trackingEvents.table.headers.event' | translate"
                            [dataSource]="eventCustomStore"
                            [valueExpr]="'event'"
                            [displayExpr]="'event'"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedEvents"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>

            <div class="col-lg-3">
                <dx-tag-box [placeholder]="'trackingEvents.table.headers.subEvent' | translate"
                            [dataSource]="subEventCustomStore"
                            [valueExpr]="'subEvent'"
                            [displayExpr]="'subEvent'"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedSubEvents"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
        </div>
    </div>

    <div class="row justify-content-center">

        <div class="col-md-10">
            <dx-data-grid (onRowUpdating)="onRowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: false, paging: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteTrackingEvent"
                    mode="form"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">
                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]="2">
                            <dxi-item dataField="event"></dxi-item>
                            <dxi-item dataField="subEvent"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1">
                            <dxi-item dataField="description"
                                      editorType="dxTextArea"
                                      [editorOptions]="{ height: 70 }">
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother" class="me-3"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary me-2"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary me-2"
                                            role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-right">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            [caption]="'trackingEvents.table.headers.id' | translate"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column dataField="event"
                            width="300"
                            [caption]="'trackingEvents.table.headers.event' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="subEvent"
                            alignment="right"
                            [caption]="'trackingEvents.table.headers.subEvent' | translate">
                </dxi-column>

                <dxi-column dataField="description"
                            [allowFiltering]="false"
                            width="500"
                            [caption]="'trackingEvents.table.headers.description' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

            </dx-data-grid>
        </div>
    </div>
</div>
