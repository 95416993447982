import { CommonView } from '../shared/util/common-view.util';
import { Component } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../shared/models/privilege.enum';
import { WarehouseService } from './warehouse.service';

@Component({
    selector: 'kep-warehouse',
    templateUrl: './warehouse.component.html',
})
export class WarehouseComponent extends CommonView {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    dataSource: DataSource;

    constructor(private warehouseService: WarehouseService) {
        super();
        this.dataSource = this.warehouseService.getWarehouses();
    }

    onInitNewRow(e) {
        e.data = this.currentRowData;
    }

}
