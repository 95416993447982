import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CurrencyExchangeRatesComponent } from './currency-exchange-rates.component';

export const CURRENCY_EXCHANGE_RATES_ROUTE: Route = {
    path: 'currency-exchange-rates',
    component: CurrencyExchangeRatesComponent,
    data: {
        roles: [],
        pageTitle: 'currencyExchangeRates.title',
    },
    canActivate: [EciAuthGuard],
};
