import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { DeliveryOptionsRecalculationComponent } from './delivery-options-recalculation.component';

export const DELIVERY_OPTIONS_RECALCULATION_ROUTE: Route = {
    path: 'delivery-options-recalculation',
    component: DeliveryOptionsRecalculationComponent,
    data: {
        roles: [],
        pageTitle: 'deliveryOptionsRecalculation.title',
    },
    canActivate: [EciAuthGuard],
};
