<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="generalDeterminationQuantityLimits.title">
        General Determination Quantity Limits
    </div>

    <div class="row justify-content-center">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="onRowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: false, paging: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">
                <dxo-column-chooser
                    [enabled]="true"
                    mode="dragAndDrop">
                </dxo-column-chooser>

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteGeneralDeterminationQuantityLimits"
                    mode="form"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">
                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="salesChannel"></dxi-item>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="warehouseId"></dxi-item>
                            <dxi-item dataField="deliveryOption"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item dataField="dailyGuidelineMinQuantity"></dxi-item>
                            <dxi-item dataField="dailyGuidelineMaxQuantity"></dxi-item>
                            <dxi-item dataField="dailyGuidelineMinShare"></dxi-item>
                            <dxi-item dataField="dailyGuidelineMaxShare"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother" class="me-3"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary me-2"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary me-2"
                                            role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-right">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            [caption]="'generalDeterminationQuantityLimits.table.headers.id' | translate"
                            [width]="120"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column [caption]="'generalDeterminationQuantityLimits.table.headers.salesChannel' | translate"
                            dataField="salesChannel">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="salesChannels"
                                displayExpr="value"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            dataField="countryId"
                            [setCellValue]="resetCarrierCell"
                            [caption]="'generalDeterminationQuantityLimits.table.headers.country' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countryStore"
                                valueExpr="id"
                                displayExpr="name">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateWarehouseFilterExpression"
                            dataField="warehouseId"
                            [caption]="'generalDeterminationQuantityLimits.table.headers.warehouse' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="warehouseStore"
                                valueExpr="id"
                                displayExpr="name">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'generalDeterminationQuantityLimits.table.headers.deliveryOption' | translate"
                            dataField="deliveryOption">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="deliveryOptionsStore"
                                displayExpr="value"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierFilterExpression"
                            dataField="carrierId"
                            [caption]="'generalDeterminationQuantityLimits.table.headers.carrier' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarrier"
                                valueExpr="id"
                                displayExpr="name">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="dailyGuidelineMinQuantity"
                            dataType="number"
                            [allowHeaderFiltering]="false"
                            [editorOptions]="{ format: 'fixedPoint', showClearButton: true, step: 0 }"
                            [caption]="'generalDeterminationQuantityLimits.table.headers.dailyGuidelineMinQuantity' | translate">
                    <dxi-validation-rule type="range" min="0" max="1000000" message="Out of range: 0 - 1,000,000"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="dailyGuidelineMaxQuantity"
                            dataType="number"
                            [allowHeaderFiltering]="false"
                            [editorOptions]="{ format: 'fixedPoint', showClearButton: true, step: 0 }"
                            [caption]="'generalDeterminationQuantityLimits.table.headers.dailyGuidelineMaxQuantity' | translate">
                    <dxi-validation-rule type="range" min="0" max="1000000" message="Out of range: 0 - 1,000,000"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="dailyGuidelineMinShare"
                            dataType="number"
                            [allowHeaderFiltering]="false"
                            [editorOptions]="{ format: 'fixedPoint', showClearButton: true, step: 0 }"
                            [caption]="'generalDeterminationQuantityLimits.table.headers.dailyGuidelineMinShare' | translate">
                    <dxi-validation-rule type="range" min="0" max="100" message="Out of range: 0 - 100"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="dailyGuidelineMaxShare"
                            dataType="number"
                            [allowHeaderFiltering]="false"
                            [editorOptions]="{ format: 'fixedPoint', showClearButton: true, step: 0 }"
                            [caption]="'generalDeterminationQuantityLimits.table.headers.dailyGuidelineMaxShare' | translate">
                    <dxi-validation-rule type="range" min="0" max="100" message="Out of range: 0 - 100"></dxi-validation-rule>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

            </dx-data-grid>
        </div>
    </div>
</div>
