import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { sendRequestFactory } from '../shared/util/ng-http-client-helper';
import devextremeAjax from 'devextreme/core/utils/ajax';
import CustomStore from 'devextreme/data/custom_store';
import { DxUtil } from '../shared/util/dx.util';

@Injectable()
export class PostCodeListsService {

    private readonly resourceUrl = '/api/post-code-list';

    constructor(
      private http: HttpClient,
      private dxService: EciDxService,
    ) {
        devextremeAjax.inject({ sendRequest: sendRequestFactory(http) });
    }

    getPostCodesForListId(listId: string) {
        return this.dxService.createEditableDataSource('/api/post-code/list/' + listId);
    }

    getPostCodeLists() {
        return this.dxService.createEditableDataSource(this.resourceUrl);
    }

    getPostcodeListsAsCustomStore(): CustomStore {
        return new CustomStore({
            key: 'id',
            load: loadOptions => this.dxService.query(this.resourceUrl, DxUtil.loadAll(loadOptions))
              .then(x => x.data),
            byKey: id => this.http.get(`${this.resourceUrl}/${id}`).toPromise(),
        });
    }

}
