<div class="page px-5">

    <div class="page__headline" eciTranslate="currencyExchangeRates.title">
        Carrier Prices
    </div>

    <div class="row justify-content-center">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="rowUpdate($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: false, paging: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>

                <div *dxTemplate="let data of 'gridHeader'">
                </div>

                <dxi-column dataField="id"
                            [caption]="'currencyExchangeRates.table.headers.id' | translate"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column [caption]="'currencyExchangeRates.table.headers.currencyFrom' | translate"
                            dataField="currencyFrom">
                </dxi-column>

                <dxi-column [caption]="'currencyExchangeRates.table.headers.currencyTo' | translate"
                            dataField="currencyTo">
                </dxi-column>

                <dxi-column [allowHeaderFiltering]="false"
                            [caption]="'currencyExchangeRates.table.headers.exchangeRate' | translate"
                            [editorOptions]="{ format: '#0.##', showClearButton: true, step: 0 }"
                            dataField="exchangeRate"
                            dataType="number">
                </dxi-column>

                <dxi-column [caption]="'currencyExchangeRates.table.headers.validFrom' | translate"
                            dataField="validFrom"
                            dataType="date"
                            format="yyyy-MM"
                            [editorOptions]="{ dateSerializationFormat: 'yyyy-MM' }"
                            sortOrder="desc">
                </dxi-column>

            </dx-data-grid>
        </div>
    </div>
</div>
