import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { $as } from './cron-schedule.helper';
import { CronSchedule } from './models/cron-schedule';
import { CronScheduleMode } from './models/cron-schedule-mode.enum';

@Component({
    selector: 'kep-cron-schedule',
    templateUrl: './cron-schedule.component.html',
})
export class CronScheduleComponent implements OnChanges {

    @Input() value: string;

    cronSchedule: CronSchedule;

    CronScheduleMode = CronScheduleMode;
    $as = $as;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['value']) {
            this.cronSchedule = CronSchedule.fromCronString(this.value);
        }
    }
}
