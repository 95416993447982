import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { EciDxService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { firstValueFrom } from 'rxjs';
import { CommonService } from '../shared/util/common.service';

@Injectable()
export class AdditionalServicesService {

    private readonly resourceUrl = 'api/additional-services';

    constructor(
        private http: HttpClient,
        private translateService: EciTenantAwareLanguageService,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) {
    }

    getAdditionalServices(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getForwardAdditionalServices(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>(`${this.resourceUrl}/forward-additional-services`))
                .then(data => data.map(value => {
                    const text = this.translateService.get(`additionalServices._forwardAdditionalServices.${value}`);
                    return { text, value };
                })),
        });
    }

    getReturnAdditionalServices(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>(`${this.resourceUrl}/return-additional-services`))
                .then(data => data.map(value => {
                    const text = this.translateService.get(`additionalServices._returnAdditionalServices.${value}`);
                    return { text, value };
                })),
        });
    }

}
