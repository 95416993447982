import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../../shared/util/common.service';

@Injectable()
export class ClosureJobsService {

    private readonly resourceUrl = 'api/closure-jobs';

    constructor(
        private dxService: EciDxService,
        private http: HttpClient,
        private commonService: CommonService,
    ) { }

    createDataSource(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    createDetailDataSource(jobId: string): DataSource {
        return this.dxService.createDataSource(`${this.resourceUrl}/${jobId}/executions`);
    }

    executeClosureJob(id: string): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}/${id}/execute`, {});
    }

}
