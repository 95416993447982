import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { PostCodeListsComponent } from './post-code-lists.component';

export const POST_CODE_LISTS_ROUTE: Route = {
    path: 'post-code-lists',
    component: PostCodeListsComponent,
    data: {
        roles: [],
        pageTitle: 'postalCodeLists.title',
    },
    canActivate: [EciAuthGuard],
};
