import home from './en/home.json';
import main from './en/main.json';
import carrierDetermination from './en/carrierDetermination.json';
import carriers from './en/carriers.json';
import carrierProducts from './en/carrierProducts.json';
import closureJobs from './en/closureJobs.json';
import carrierDeliveryOptions from './en/carrierDeliveryOptions.json';
import labelServiceConfig from './en/labelServiceConfig.json';
import sendItLabelAndClosureConfiguration from './en/sendItLabelAndClosureConfiguration.json';
import warehouse from './en/warehouse.json';
import countries from './en/countries.json';
import audits from './en/audits.json';
import cpt from './en/cpt.json';
import shared from './en/shared.json';
import articleRules from './en/articleRules.json';
import packageMaterialDefinitions from './en/packageMaterialDefinitions.json';
import carrierProductDistributionTimes from './en/carrierProductDistributionTimes.json';
import articleBrickCodeRules from './en/articleBrickCodeRules.json';
import articleServicesRules from './en/articleServicesRules.json';
import backupShippingTypeAddresses from './en/backupShippingTypeAddresses.json';
import additionServices from './en/additionalServices.json';
import carrierProductsAvailability from './en/carrierProductsAvailability.json';
import postalCodeLists from './en/postalCodeLists.json';
import postalCodeWhitelists from './en/postalCodeWhitelists.json';
import trackingEvents from './en/trackingEvents.json';
import trackingEventCodeMappings from './en/trackingEventCodeMappings.json';
import carrierDowntime from './en/carrierDowntime.json';
import carrierPrices from './en/carrierPrices.json';
import currencyExchangeRates from './en/currencyExchangeRates.json';
import activeSessions from './en/activeSessions.json';
import surchargeOverview from './en/surchargeOverview.json';
import packagingMaterials from './en/packagingMaterials.json';
import packagingMaterialAvailability from './en/packagingMaterialAvailability.json';
import determinationRatio from './en/determinationRatio.json';
import generalDeterminationQuantityLimits from './en/generalDeterminationQuantityLimits.json';
import fallBackCarriers from './en/fallBackCarriers.json';
import carrierSurcharges from './en/carrierSurcharges.json';
import carrierSurchargeRules from './en/carrierSurchargeRules.json';

export const en = [
    home,
    main,
    carrierDetermination,
    carriers,
    carrierProducts,
    closureJobs,
    carrierDeliveryOptions,
    labelServiceConfig,
    sendItLabelAndClosureConfiguration,
    warehouse,
    countries,
    cpt,
    shared,
    articleRules,
    packageMaterialDefinitions,
    carrierProductDistributionTimes,
    articleBrickCodeRules,
    articleServicesRules,
    audits,
    backupShippingTypeAddresses,
    additionServices,
    carrierProductsAvailability,
    postalCodeLists,
    postalCodeWhitelists,
    trackingEvents,
    trackingEventCodeMappings,
    carrierDowntime,
    carrierPrices,
    currencyExchangeRates,
    activeSessions,
    surchargeOverview,
    packagingMaterials,
    packagingMaterialAvailability,
    determinationRatio,
    generalDeterminationQuantityLimits,
    fallBackCarriers,
    carrierSurcharges,
    carrierSurchargeRules,
];
