import { Component, OnInit } from '@angular/core';
import { CommonView } from '../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../shared/models/privilege.enum';
import CustomStore from 'devextreme/data/custom_store';
import { CarrierProductsService } from '../carrier-products/carrier-products.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { CarriersService } from '../carriers/carriers.service';
import { CountriesService } from '../countries/countries.service';
import { AdditionalServicesService } from './additional-services.service';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { Country } from '../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { AdditionalService } from '../shared/models/additional-service.model';

@Component({
    selector: 'kep-additional-services',
    templateUrl: './additional-services.component.html',
})
export class AdditionalServicesComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    warehouseStore: CustomStore;
    carrierProductsStore: CustomStore;
    countriesStore: CustomStore;
    carrierStore: CustomStore;
    forwardAdditionalServiceStore: CustomStore;
    returnAdditionalServiceStore: CustomStore;

    selectedCountries = [];
    selectedWarehouses = [];
    selectedForwardAdditionalServices = [];

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    labelMode = 'floating';

    resetCarrierProductCell = DxUtil.hookSetCellValue<AdditionalService>(rowData => {
        rowData.carrierProductId = null;
    });

    constructor(
        private additionalService: AdditionalServicesService,
        private carrierService: CarriersService,
        private carrierProductsService: CarrierProductsService,
        private warehouseService: WarehouseService,
        private countriesService: CountriesService,
    ) {
        super();
    }

    resetFilter(): void {
        super.resetFilter();

        this.selectedCountries = [];
        this.selectedWarehouses = [];
        this.selectedForwardAdditionalServices = [];
    }

    ngOnInit() {
        this.dataSource = this.additionalService.getAdditionalServices();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.carrierProductsStore = this.carrierProductsService.getCarrierProductsAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.forwardAdditionalServiceStore = this.additionalService.getForwardAdditionalServices();
        this.returnAdditionalServiceStore = this.additionalService.getReturnAdditionalServices();
    }

    onRowUpdate(e: DxRowUpdateEvent<AdditionalService>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string): string[] {
        return ['warehouses', 'contains', filterValue];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.carrier.id', filterOperation, filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.id', filterOperation, filterValue];
    }

    calculateForwardServiceFilterExpression(filterValue: string): string[] {
        return ['forwardAdditionalServices', 'contains', filterValue];
    }

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<AdditionalService>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<AdditionalService, Country>(options, 'countryId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    onFilterChange() {
        this.dataGrid.instance.clearFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('warehouses', 'filterValues', this.selectedWarehouses);
        this.dataGrid.instance.columnOption('forwardAdditionalServices', 'filterValues', this.selectedForwardAdditionalServices);
    }
}
