
<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="label-service-config.title">
        Label Service Configurations
    </div>

    <div class="page__filter mb-2">
        <div class="row justify-content-center">
            <div class="col-lg-3">
                <div class="input-group">
                    <label class="col-form-label me-2">Country:</label>
                    <div class="flex-fill">
                        <dx-tag-box [dataSource]="countryStore"
                                    [valueExpr]="'id'"
                                    [displayExpr]="'name'"
                                    (onValueChanged)="onFilterChange()"
                                    [(value)]="selectedCountries"
                                    [searchEnabled]="true">
                        </dx-tag-box>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group">
                    <label class="col-form-label me-2">Warehouse:</label>
                    <div class="flex-fill">
                        <dx-tag-box [dataSource]="warehouseStore"
                                    [valueExpr]="'id'"
                                    [displayExpr]="'name'"
                                    (onValueChanged)="onFilterChange()"
                                    [(value)]="selectedWarehouses"
                                    [searchEnabled]="true">
                        </dx-tag-box>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group">
                    <label class="col-form-label me-2">Carrier:</label>
                    <div class="flex-fill">
                        <dx-tag-box [dataSource]="carrierStore"
                                    [valueExpr]="'id'"
                                    [displayExpr]="'name'"
                                    (onValueChanged)="onFilterChange()"
                                    [(value)]="selectedCarriers"
                                    [searchEnabled]="true">
                        </dx-tag-box>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="onRowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteLabelServiceConfig"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true"
                    [useIcons]="true"
                    mode="form">

                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="warehouseId"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item dataField="carrierProductId"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item dataField="labelService"></dxi-item>
                            <dxi-item dataField="shipmentDirection"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate" [(value)]="createAnother"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary" role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary" role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true" class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                            eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            caption="{{ 'label-service-config.table.headers.id' | translate }}"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            [setCellValue]="resetCarrierProductCell"
                            dataField="countryId"
                            caption="{{ 'label-service-config.table.headers.country' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countryStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateWarehouseFilterExpression"
                            dataField="warehouseId"
                            [setCellValue]="resetWarehouseCell"
                            [editorOptions]="{ showClearButton: true }"
                            caption="{{ 'label-service-config.table.headers.warehouse' | translate }}">
                    <dxo-lookup [dataSource]="warehouseStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierFilterExpression"
                            [setCellValue]="resetCarrierProductCell"
                            dataField="carrierId"
                            caption="{{ 'label-service-config.table.headers.carrier' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarriers"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierProductFilterExpression"
                            dataField="carrierProductId"
                            [editorOptions]="{ showClearButton: true }"
                            caption="{{ 'label-service-config.table.headers.carrierProduct' | translate }}">
                    <dxo-lookup [dataSource]="getFilteredCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="labelService"
                            caption="{{ 'label-service-config.table.headers.labelService' | translate }}">
                    <dxo-lookup [dataSource]="service" valueExpr="name" displayExpr="name"></dxo-lookup>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="shipmentDirection"
                            caption="{{ 'label-service-config.table.headers.shipmentDirection' | translate }}">
                    <dxo-lookup [dataSource]="shipmentDirection" valueExpr="name" displayExpr="name"></dxo-lookup>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>
