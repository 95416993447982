import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { Service } from '../../shared/models/label-service.model';
import { CommonService } from '../../shared/util/common.service';

const service: Service[] = [{
    'id': 3,
    'name': 'SENDIT_CLOUD',
},
{
    'id': 4,
    'name': 'CARRIER_LAYER',
},
{
    'id': 6,
    'name': 'CARTAGO',
}];

@Injectable()
export class LabelServiceConfig {

    constructor(private httpClient: HttpClient,
                private dxService: EciDxService,
                private commonService: CommonService,
    ) {
    }

    getLabelServiceConfigData(): DataSource {
        return this.commonService.getDataSource('/api/label-service-config');
    }

    getLabelService() {
        return service;
    }
}
