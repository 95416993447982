import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'kepPadStart',
})
export class PadStartPipe implements PipeTransform {
    transform(value: any, targetLength: number, padString = ' '): string {
        const stringifiedValue = value.toString() as string;
        return stringifiedValue.padStart(targetLength, padString);
    }
}
