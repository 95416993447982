<!-- Navbar component. -->
<eci-navbar>

    <!-- Here you can inject custom links for your app-specific components. -->
    <li *ngIf="principal.isAuthenticated()"
        class="nav-item pull-left"
        routerLinkActive="active"
        (click)="navbar.collapseNavbar()"
        [routerLinkActiveOptions]="{exact: true}">

        <a class="nav-link" routerLink="/">
            <i class="fas fa-home" aria-hidden="true"></i>
        </a>

    </li>

    <li *eciHasAnyAuthority="Privilege.ReadMasterData" class="nav-item pull-right dropdown pointer" routerLinkActive="active"
        ngbDropdown>

        <a class="nav-link dropdown-toggle" href="javascript:void(0);" ngbDropdownToggle>
            <span>
                <i class="fas fa-truck fa-flip-horizontal me-2" aria-hidden="true"></i>
                <span eciTranslate="app.menu.carriers.title">Carriers</span>
            </span>
        </a>

        <ul class="dropdown-menu" ngbDropdownMenu>
            <li>
                <a class="dropdown-item" routerLink="/carriers">
                    <i class="fas fa-truck fa-flip-horizontal me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.carriers">Carriers</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/carrier-products">
                    <i class="fas fa-box-open me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.carrier-products">Carrier products</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/carrier-products-availability">
                    <i class="fas fa-box-open me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.carrier-products-availability">Carrier products availability</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/additional-services">
                    <i class="fas fa-truck-fast me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.additional-services">Additional Services</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/carrier-product-distribution-times">
                    <i class="fas fa-clock me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.carrier-product-distribution-times">Carrier product distribution times</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/countries">
                    <i class="fas fa-globe-europe me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.countries">Countries</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/zone">
                    <i class="fas fa-globe-europe me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.zones">Zones</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/cpt">
                    <i class="fas fa-dolly-flatbed me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.cpt">CPT</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/post-code-lists">
                    <i class="fas fa-list-alt me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.postcode-lists">Postal code lists</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/warehouse">
                    <i class="fas fa-warehouse me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.warehouse">Warehouse</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/packaging-materials">
                    <i class="fas fa-cube me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.packaging-materials">Packaging Materials</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/packaging-material-availability">
                    <i class="fas fa-cube me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.packaging-material-availability">Packaging Material Availability</span>
                </a>
            </li>
        </ul>

    </li>

    <li *ngIf="principal.isAuthenticated()" class="nav-item pull-right dropdown pointer" routerLinkActive="active"
        ngbDropdown>

        <a class="nav-link dropdown-toggle" href="javascript:void(0);" ngbDropdownToggle>
            <span>
                <i class="fas fa-boxes me-2" aria-hidden="true"></i>
                <span eciTranslate="app.menu.carrier-determination.title">Carrier Determination</span>
            </span>
        </a>

        <ul class="dropdown-menu" ngbDropdownMenu>
            <li>
                <a class="dropdown-item" routerLink="/carrier-determination/percentage">
                    <i class="fas fa-percent me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carrier-determination.drop-down.percentage">Ratio</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/article-rules">
                    <i class="fas fa-pencil-ruler me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.master-data.drop-down.article-rules">Article Rules</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/article-brick-code-rules">
                    <i class="fas fa-ruler-combined me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.article-brick-code-rules">Article brick code rules</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/article-services-rules">
                    <i class="fas fa-ruler me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.article-services-rules">Article services rules</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/carrier-determination/postcodes">
                    <i class="fas fa-list-alt me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carrier-determination.drop-down.postcode-lists">Postal code Whitelist</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/determination-ratio">
                    <i class="fas fa-list-alt me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carrier-determination.drop-down.determination-ratios">Determination Ratios</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/carrier-determination/general-determination-quantity-limits">
                    <i class="fas fa-scale-balanced me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carrier-determination.drop-down.general-determination-quantity-limits">General Determination Quantity Limits</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/fall-back-carriers">
                    <i class="fas fa-truck fa-flip-horizontal me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carrier-determination.drop-down.fall-back-carriers">FallBack Carriers</span>
                </a>
            </li>
        </ul>
    </li>

    <li *ngIf="principal.isAuthenticated()" class="nav-item pull-right dropdown pointer" routerLinkActive="active"
        ngbDropdown>

        <a class="nav-link dropdown-toggle" href="javascript:void(0);" ngbDropdownToggle>
            <span>
                <i class="fas fa-cogs" aria-hidden="true"></i>
                <span eciTranslate="app.menu.configuration.title">Label & Closure</span>
            </span>
        </a>

        <ul class="dropdown-menu" ngbDropdownMenu>

            <li>
                <a class="dropdown-item" routerLink="/label-service-config">
                    <i class="fas fa-fw fa-sliders-h me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.configuration.drop-down.label-service-config">Label Service Configurations</span>
                </a>
            </li>

            <li>
                <a class="dropdown-item" routerLink="/send-it-label-and-closure-config">
                    <i class="fas fa-fw fa-list me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.configuration.drop-down.sendit-label-and-closure-config">SendIT Label & Closure Configuration</span>
                </a>
            </li>

            <li>
                <a class="dropdown-item" routerLink="/package-material-definitions">
                    <i class="fas fa-table me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.configuration.drop-down.package-material-definitions">Package Material Definitions</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/closure-jobs">
                    <i class="fas fa-calendar-check me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.carriers.drop-down.closure-jobs">Closure jobs</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/backup-shipping-type-addresses">
                    <i class="fas fa-calendar-check me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.configuration.drop-down.backup-shipping-type-addresses">Backup Shipping Type Addresses</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/carrier-downtime">
                    <i class="fas fa-calendar-check me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.configuration.drop-down.carrier-downtime">Carrier Downtime Configurations</span>
                </a>
            </li>

        </ul>

    </li>

    <li *ngIf="principal.isAuthenticated()" class="nav-item pull-left" routerLinkActive="active"
        ngbDropdown>

        <a class="nav-link dropdown-toggle" href="javascript:void(0);" ngbDropdownToggle>
            <span>
                <i class="fas fa-route me-2" aria-hidden="true"></i>
                <span eciTranslate="app.menu.tracking.title">Tracking</span>
            </span>
        </a>

        <ul class="dropdown-menu" ngbDropdownMenu>

            <li>
                <a class="dropdown-item" routerLink="/tracking-events">
                    <i class="fas fa-clipboard-list me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.tracking.drop-down.tracking-events">Tracking Events</span>
                </a>
            </li>

            <li>
                <a class="dropdown-item" routerLink="/tracking-event-code-mappings">
                    <i class="fas fa-sliders me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.tracking.drop-down.tracking-event-code-mapping">Tracking Event Code Mapping</span>
                </a>
            </li>
        </ul>

    </li>

    <li *ngIf="principal.isAuthenticated()" class="nav-item pull-left" routerLinkActive="active"
        ngbDropdown>

        <a class="nav-link dropdown-toggle" href="javascript:void(0);" ngbDropdownToggle>
            <span>
                <i class="fas fa-euro-sign me-2" aria-hidden="true"></i>
                <span eciTranslate="app.menu.costs.title">Parcel Costs</span>
            </span>
        </a>

        <ul class="dropdown-menu" ngbDropdownMenu>

            <li>
                <a class="dropdown-item" routerLink="/carrier-prices">
                    <i class="fas fa-box me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.costs.drop-down.carrier-prices">Carrier Prices</span>
                </a>
            </li>

            <li>
                <a class="dropdown-item" routerLink="/currency-exchange-rates">
                    <i class="fas fa-calculator me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.costs.drop-down.currency-calculation">Currency Calculation</span>
                </a>
            </li>

            <li>
                <a class="dropdown-item" routerLink="/surcharge-overview">
                    <i class="fas fa-search-dollar me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.costs.drop-down.parcel-costs">Surcharge overview</span>
                </a>
            </li>

            <li>
                <a class="dropdown-item" routerLink="/carrier-surcharges">
                    <i class="fas fa-search-dollar me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.costs.drop-down.carrier-surcharges">Carrier surcharges</span>
                </a>
            </li>

            <li>
                <a class="dropdown-item" routerLink="/carrier-surcharge-rules">
                    <i class="fas fa-pencil-ruler me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.costs.drop-down.carrier-surcharge-rules">Carrier surcharge rules</span>
                </a>
            </li>
        </ul>

    </li>

    <li *ngIf="principal.isAuthenticated()" class="nav-item pull-left" routerLinkActive="active"
        ngbDropdown>

        <a class="nav-link dropdown-toggle" href="javascript:void(0);" ngbDropdownToggle>
            <span>
                <i class="fas fa-history me-2" aria-hidden="true"></i>
                <span eciTranslate="app.menu.delivery-options.title">Delivery Options</span>
            </span>
        </a>

        <ul class="dropdown-menu" ngbDropdownMenu>

            <li>
                <a class="dropdown-item" routerLink="/delivery-options-recalculation">
                    <i class="fas fa-calculator me-2" aria-hidden="true"></i>
                    <span eciTranslate="app.menu.delivery-options.drop-down.recalculations">Recalculations</span>
                </a>
            </li>
        </ul>

    </li>

    <li *ngIf="principal.isAuthenticated()" class="nav-item pull-left" routerLinkActive="active"
        ngbDropdown>

        <a class="nav-link" routerLink="/custom-audits">
            <i class="fas fa-history" aria-hidden="true"></i>
            <span eciTranslate="app.menu.audit.title">Audits</span>
        </a>

    </li>

    <!-- Enable Active session when activated -->
<!--    <li *ngIf="principal.isAuthenticated()" class="nav-item pull-left" routerLinkActive="active"
        ngbDropdown>

        <a class="nav-link" routerLink="/active-sessions">
            <i class="fas fa-user" aria-hidden="true"></i>
            <span eciTranslate="app.menu.active-sessions.title">Active Sessions</span>
        </a>

    </li> -->

</eci-navbar>

<!-- Main router outlet area. Basic viewport for all routed components. -->
<div class="container-fluid">
    <div>
        <router-outlet></router-outlet>
    </div>
</div>

<!-- Footer component. -->
<eci-footer>

    <!-- Here you can inject custom footer elements / components. -->
    <span *ngIf="principal.isAuthenticated()"
          class="pull-left">Custom footer elements.
    </span>

</eci-footer>

<eci-toast-container class="top-center"></eci-toast-container>
