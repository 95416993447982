import { CommonView } from '../../shared/util/common-view.util';
import { Component } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { LabelServiceConfig } from './label-service-config.service';
import { CarriersService } from '../../carriers/carriers.service';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../../shared/util/dx.util';
import { CountriesService } from '../../countries/countries.service';
import { WarehouseService } from '../../warehouse/warehouse.service';
import { CarrierProductsService } from '../../carrier-products/carrier-products.service';
import { LabelServiceConfiguration } from '../../shared/models/label-service-configuration.model';
import { CarrierProduct } from '../../shared/models/carrier-product.model';
import { Carrier } from '../../shared/models/carrier.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { Country } from '../../shared/models/country.model';
import { Privilege } from 'src/app/shared/models/privilege.enum';
import { Service } from '../../shared/models/label-service.model';
import { CommonService } from '../../shared/util/common.service';
import { ShipmentDirection } from '../../shared/models/shipment-direction.model';

@Component({
    selector: 'kep-label-service-config',
    templateUrl: './label-service-config.component.html',
})
export class LabelServiceConfigComponent extends CommonView {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    dataSource: DataSource;
    service: Service[];
    shipmentDirection: ShipmentDirection[];

    carrierStore: CustomStore;
    carrierProductsStore: CustomStore;
    warehouseStore: CustomStore;
    countryStore: CustomStore;

    selectedCountries = [];
    selectedWarehouses = [];
    selectedCarriers = [];

    resetCarrierProductCell = DxUtil.hookSetCellValue<LabelServiceConfiguration>(rowData => {
        rowData.carrierProductId = null;
    });

    resetWarehouseCell = DxUtil.hookSetCellValue<LabelServiceConfiguration>(rowData => {
        rowData.warehouseId = null;
    });

    constructor(
        private labelServiceConfig: LabelServiceConfig,
        private countryService: CountriesService,
        private warehouseService: WarehouseService,
        private carriersService: CarriersService,
        private carrierProductsService: CarrierProductsService,
        private commonService: CommonService,
    ) {
        super();
        this.dataSource = this.labelServiceConfig.getLabelServiceConfigData();
        this.countryStore = this.countryService.getCountriesAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.carrierStore = this.carriersService.getCarriersAsCustomStore();
        this.service = this.labelServiceConfig.getLabelService();
        this.shipmentDirection = this.commonService.getShipmentDirection();
        this.carrierProductsStore = this.carrierProductsService.getCarrierProductsAsCustomStore();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierProduct>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<LabelServiceConfiguration>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<LabelServiceConfiguration, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<LabelServiceConfiguration, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    getFilteredCarriers = (options: DxLookupDataSourceOptions<LabelServiceConfiguration>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<LabelServiceConfiguration, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.carrier.id', filterOperation, filterValue];
    }

    resetFilter(): void {
        super.resetFilter();

        this.selectedCountries = [];
        this.selectedWarehouses = [];
        this.selectedCarriers = [];
    }

    onFilterChange() {
        this.dataGrid.instance.clearFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('warehouseId', 'filterValues', this.selectedWarehouses);
        this.dataGrid.instance.columnOption('carrierId', 'filterValues', this.selectedCarriers);
    }
}
