import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import CustomStore from 'devextreme/data/custom_store';
import { DxUtil } from '../shared/util/dx.util';
import { CommonService } from '../shared/util/common.service';
import { Zone } from '../shared/models/zone.model';
import { firstValueFrom, map } from 'rxjs';

@Injectable()
export class ZoneService {

    private readonly resourceUrl = 'api/zones';

    constructor(
        private http: HttpClient,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) { }

    getZones(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getZonesAsCustomStore(): CustomStore {
        return new CustomStore({
            key: 'id',
            load: loadOptions => this.dxService.query(this.resourceUrl, DxUtil.loadAll(loadOptions))
                .then(x => x.data),
            byKey: id => this.http.get(`${this.resourceUrl}/${id}`).toPromise(),
        });
    }

    getZonesByCountryAsCustomStore(countryId: string): CustomStore {
        return new CustomStore({
            key: 'id',
            load: loadOptions => this.dxService.query(`${this.resourceUrl}/country/${countryId}`, DxUtil.loadAll(loadOptions))
                .then(x => x.data),
        });
    }

    getZonesAsPromise(): Promise<Zone[]> {
        return firstValueFrom(
            this.http.get<Zone[]>(this.resourceUrl, { observe: 'response' })
                .pipe(
                    map(res => res.body),
                ),
        );
    }
}
