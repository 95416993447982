import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { PackageMaterialDefinitionsComponent } from './package-material-definitions.component';

export const PACKAGE_MATERIAL_DEFINITIONS_ROUTE: Route = {
    path: 'package-material-definitions',
    component: PackageMaterialDefinitionsComponent,
    data: {
        roles: [],
        pageTitle: 'packageMaterialDefinitions.title',
    },
    canActivate: [EciAuthGuard],
};
