import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommonService } from '../shared/util/common.service';
import { DeterminationRatioService } from './determination-ratio.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule,
    ],
    providers: [
        DeterminationRatioService,
        CommonService,
    ],
})
export class DeterminationRatioModule { }
