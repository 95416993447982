import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarrierPostCodeListComponent } from './carrier-post-code-list.component';

export const CARRIER_POST_CODE_LIST_ROUTE: Route = {
    path: 'carrier-determination/postcodes',
    component: CarrierPostCodeListComponent,
    data: {
        roles: [],
        pageTitle: 'postalCodeWhitelists.title',
    },
    canActivate: [EciAuthGuard],
};
