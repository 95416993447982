import { Component, OnInit } from '@angular/core';
import { CommonView } from '../../shared/util/common-view.util';
import { Privilege } from 'src/app/shared/models/privilege.enum';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { CarriersService } from '../../carriers/carriers.service';
import { CarrierDowntimeService } from './carrier-downtime.service';
import { DxUtil } from '../../shared/util/dx.util';
import { CarrierDowntime } from '../../shared/models/carrier-downtime.model';

@Component({
    selector: 'kep-carrier-downtime',
    templateUrl: './carrier-downtime.component.html',
})

export class CarrierDowntimeComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    datasource: DataSource;
    carrierStore: CustomStore;
    editable = false;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    resetEndDateCell = DxUtil.hookSetCellValue<CarrierDowntime>(rowData => {
        rowData.endDate = null;
    });

    constructor(
        private carriersService: CarriersService,
        private carrierDowntimeService: CarrierDowntimeService,
    ) {
        super();
    }

    ngOnInit() {
        this.datasource = this.carrierDowntimeService.getCarrierDowntimes();
        this.carrierStore = this.carriersService.getCarriersAsCustomStore();
    }

    initNewRow(e) {
        this.editable = true;
        e.data = this.currentRowData;
    }

    editRow(e) {
        this.editable = false;
    }

    startInTheFuture(e) {
        // Date is used in backend and other services without timezone, so it needs to be treated as UTC
        const dateNowUtc = Date.now() + new Date().getTimezoneOffset() * 60 * 1000;
        return e.value > dateNowUtc;

    }

    endAfterStart(e) {
        return e.value > Date.parse(e.data.startDate);
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

}
