import { CommonView } from '../../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Privilege } from '../../shared/models/privilege.enum';
import { Service } from '../../shared/models/label-service.model';
import { DeliveryOptionsRecalculationService } from './delivery-options-recalculation.service';

@Component({
    selector: 'delivery-options-recalculation',
    templateUrl: './delivery-options-recalculation.component.html',
})
export class DeliveryOptionsRecalculationComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    dataSource: DataSource;

    carrierStore: CustomStore;

    constructor(
        private deliveryOptionsRecalculationService: DeliveryOptionsRecalculationService,
    ) {
        super();
     }

    ngOnInit() {
        this.dataSource = this.deliveryOptionsRecalculationService.createDataSource();
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

}
