import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { ActiveSessionsComponent } from './active-sessions.component';

export const ACTIVE_SESSIONS_ROUTE: Route = {
    path: 'active-sessions',
    component: ActiveSessionsComponent,
    data: {
        roles: [],
        pageTitle: 'activeSessions.title',
    },
    canActivate: [EciAuthGuard],
};
