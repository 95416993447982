<div style="display: inline">
    <ng-container *ngIf="cronSchedule.mode === CronScheduleMode.Minutely">
        <span>{{ 'shared.cronSchedule.texts.every' | translate }} <strong>{{ $as.minutely(cronSchedule).minutes }}</strong> {{ 'shared.cronSchedule.texts.minutes' | translate }}</span>
    </ng-container>

    <ng-container *ngIf="cronSchedule.mode === CronScheduleMode.Hourly">
        <span>{{ 'shared.cronSchedule.texts.every' | translate }} <strong>{{ $as.hourly(cronSchedule).hours }}</strong> {{ 'shared.cronSchedule.texts.hoursAtMinute' | translate }} <strong>{{ $as.hourly(cronSchedule).minutes }}</strong></span>
    </ng-container>

    <ng-container *ngIf="cronSchedule.mode === CronScheduleMode.Daily">
        <span>{{ 'shared.cronSchedule.texts.every' | translate }} <strong>{{ $as.daily(cronSchedule).days }}</strong> {{ 'shared.cronSchedule.texts.daysAt' | translate }} <strong>{{ $as.daily(cronSchedule).hours | kepPadStart:2:'0' }}:{{ $as.daily(cronSchedule).minutes | kepPadStart:2:'0' }}</strong></span>
    </ng-container>

    <ng-container *ngIf="cronSchedule.mode === CronScheduleMode.Weekly">
        <span>{{ 'shared.cronSchedule.texts.every' | translate }} <strong>{{ ('shared.cronSchedule._weekdays.' + $as.weekly(cronSchedule).weekday) | translate }}</strong> {{ 'shared.cronSchedule.texts.at' | translate }} <strong>{{ $as.weekly(cronSchedule).hours | kepPadStart:2:'0' }}:{{ $as.weekly(cronSchedule).minutes | kepPadStart:2:'0' }}</strong></span>
    </ng-container>

    <ng-container *ngIf="cronSchedule.mode === CronScheduleMode.Monthly">
        <span>{{ 'shared.cronSchedule.texts.every' | translate }} <strong>{{ $as.monthly(cronSchedule).months }}</strong> {{ 'shared.cronSchedule.texts.monthsOnThe' | translate }} <strong>{{ $as.monthly(cronSchedule).day }}.</strong> {{ 'shared.cronSchedule.texts.at' | translate }} <strong>{{ $as.monthly(cronSchedule).hours | kepPadStart:2:'0' }}:{{ $as.monthly(cronSchedule).minutes | kepPadStart:2:'0' }}</strong></span>
    </ng-container>

    <ng-container *ngIf="cronSchedule.mode === CronScheduleMode.Custom">
        <span>{{ 'shared.cronSchedule.texts.custom' | translate }} (<strong>{{ $as.custom(cronSchedule).value }}</strong>)</span>
    </ng-container>
</div>
