import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CptComponent } from './cpt.component';

export const CPT_ROUTE: Route = {
    path: 'cpt',
    component: CptComponent,
    data: {
        roles: [],
        pageTitle: 'cpt.title',
    },
    canActivate: [EciAuthGuard],
};
