<div class="page px-5">

    <div class="page__headline"
         eciTranslate="articleRules.title">
        Article Rules
    </div>

    <div class="page__filter mb-2">
        <div class="row">
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                    eciTranslate="articleBrickCodeRules.table.headers.country">Countries</label>
                <dx-tag-box [dataSource]="possibleCountries" (onValueChanged)="onFilterChange()" [(value)]="selectedCountries"
                    [valueExpr]="'id'" [displayExpr]="'name'" [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                    eciTranslate="articleBrickCodeRules.table.headers.warehouse">Warehouses</label>
                <dx-tag-box [dataSource]="possibleWarehouses" (onValueChanged)="onFilterChange()" [(value)]="selectedWarehouses"
                    [valueExpr]="'id'" [displayExpr]="'name'" [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                    eciTranslate="articleBrickCodeRules.table.headers.carrier">Carriers</label>
                <dx-tag-box [dataSource]="possibleCarriers" (onValueChanged)="onFilterChange()" [(value)]="selectedCarriers"
                    [valueExpr]="'id'" [displayExpr]="'name'" [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <label class="col-form-label me-2" eciTranslate="articleBrickCodeRules.table.headers.carrierProduct">Carrier
                    Products</label>
                <dx-tag-box [dataSource]="possibleCarrierProducts" (onValueChanged)="onFilterChange()"
                    [(value)]="selectedCarrierProducts" [valueExpr]="'id'" [displayExpr]="'name'" [searchEnabled]="true">
                </dx-tag-box>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="onRowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: true, paging: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="false"
                          [errorRowEnabled]="false"
                          [filterSyncEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop">
                </dxo-column-chooser>
                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing *eciHasAnyAuthority="Privilege.WriteArticleRule"
                             [allowUpdating]="true"
                             [allowDeleting]="true"
                             [allowAdding]="true"
                             [useIcons]="true"
                             mode="form">

                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="articleNumber"></dxi-item>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="zones"></dxi-item>
                            <dxi-item dataField="warehouseId"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item dataField="carrierProductId"></dxi-item>
                            <dxi-item dataField="articleName"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother" class="me-3"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary me-2"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary me-2" role="button"
                                            type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            caption="{{ 'articleRules.table.headers.id' | translate }}"
                            [allowEditing]="false"
                            [visible]="false">
                    <!-- True while in development - can be disabled later-->
                </dxi-column>

                <dxi-column dataField="articleNumber"
                            [editorOptions]="{ showClearButton: true }"
                            caption="{{ 'articleRules.table.headers.articleNumber' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule
                        type="pattern"
                        [pattern]="articleNumberPattern" message="Article number must be digits only"
                    ></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="articleName"
                            [allowEditing]="false"
                            [visible]="false"
                            caption="{{ 'articleRules.table.headers.articleName' | translate }}">
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            [setCellValue]="resetCarrierProductAndZoneCell"
                            dataField="countryId"
                            sortOrder="asc"
                            calculateSortValue="countryName"
                            caption="{{ 'articleRules.table.headers.country' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countriesStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateZoneFilterExpression"
                            [caption]="'articleRules.table.headers.zones' | translate"
                            editCellTemplate="zoneTagBoxEditor"
                            cellTemplate="zonesCellTemplate"
                            dataField="zones">
                    <dxo-lookup [dataSource]="getFilteredZones"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateWarehouseFilterExpression"
                            dataField="warehouseId"
                            [setCellValue]="resetWarehouseCell"
                            [editorOptions]="{ showClearButton: true }"
                            caption="{{ 'articleRules.table.headers.warehouse' | translate }}">
                    <dxo-lookup [dataSource]="warehouseStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierFilterExpression"
                            [setCellValue]="resetCarrierProductCell"
                            dataField="carrierId"
                            caption="{{ 'articleRules.table.headers.carrier' | translate }}">
                    <dxo-lookup [dataSource]="getFilteredCarriers"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierProductFilterExpression"
                            dataField="carrierProductId"
                            caption="{{ 'articleRules.table.headers.carrierProduct' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>
                <div *dxTemplate="let cellInfo of 'zoneTagBoxEditor'">
                    <dx-tag-box
                        [placeholder]="'articleBrickCodeRules.table.headers.zones' | translate"
                        [dataSource]="filteredZones"
                        [showSelectionControls]="true"
                        [value]="cellInfo.value"
                        [valueExpr]="'id'"
                        [displayExpr]="'name'"
                        (onValueChanged)="cellInfo.setValue($event.value)"
                        applyValueMode="useButtons"
                        [searchEnabled]="true">
                    </dx-tag-box>
                </div>

                <div *dxTemplate="let cellInfo of 'zonesCellTemplate'">
                    <ul style="list-style-type: none;margin: 0">
                        <li *ngFor="let item of cellInfo.data['zones']">
                            <h5 style="margin: 0;font-size: 15px"><span class="badge rounded-pill bg-secondary">{{ getZoneName(item) }}</span></h5>
                        </li>
                    </ul>
                </div>
            </dx-data-grid>

        </div>

    </div>

</div>
