<div class="page px-5">

    <div class="page__headline"
         eciTranslate="deliveryOptionsRecalculation.title">
        Delivery Options Recalculation
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ paging: false, filtering: false, sorting: false }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [wordWrapEnabled]="true">

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop"></dxo-column-chooser>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                    </div>
                </div>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.countryCode' | translate"
                            dataField="countryCode">
                </dxi-column>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.triggerEntityId' | translate"
                            dataField="triggerEntityId"
                            [visible]="false">
                </dxi-column>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.triggerEntityName' | translate"
                            dataField="triggerEntityName">
                </dxi-column>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.triggerEntityType' | translate"
                            dataField="triggerEntityType">
                </dxi-column>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.triggerEvent' | translate"
                            dataField="triggerEvent">
                </dxi-column>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.totalArticleCount' | translate"
                            dataField="totalArticleCount">
                </dxi-column>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.processedArticleCount' | translate"
                            dataField="processedArticleCount">
                </dxi-column>

                <dxi-column [caption]="'Progress'" cellTemplate="progressBar"/>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.startTime' | translate"
                            dataField="startTime"
                            dataType="date"
                            format="dd.MM.yyyy - HH:mm:ss">
                </dxi-column>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.endTime' | translate"
                            dataField="endTime"
                            dataType="date"
                            format="dd.MM.yyyy - HH:mm:ss">
                </dxi-column>

                <dxi-column [caption]="'deliveryOptionsRecalculation.table.headers.aborted' | translate"
                            dataField="aborted">
                </dxi-column>

                <div *dxTemplate="let cellInfo of 'progressBar'">
                    <dx-progress-bar
                        [min]="0"
                        [max]="cellInfo.data.totalArticleCount"
                        [value]="cellInfo.data.processedArticleCount">
                    </dx-progress-bar>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
