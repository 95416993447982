export enum CronScheduleWeekday {
    Monday = 'MON',
    Tuesday = 'TUE',
    Wednesday = 'WED',
    Thursday = 'THU',
    Friday = 'FRI',
    Saturday = 'SAT',
    Sunday = 'SUN',
}

export const CRON_SCHEDULE_WEEKDAYS = [
    CronScheduleWeekday.Monday,
    CronScheduleWeekday.Tuesday,
    CronScheduleWeekday.Wednesday,
    CronScheduleWeekday.Thursday,
    CronScheduleWeekday.Friday,
    CronScheduleWeekday.Saturday,
    CronScheduleWeekday.Sunday,
];
