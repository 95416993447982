import { CommonView } from '../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../shared/models/privilege.enum';
import { CountriesService } from './countries.service';

@Component({
    selector: 'kep-countries',
    templateUrl: './countries.component.html',
})
export class CountriesComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    dataSource: DataSource;

    constructor(private countryService: CountriesService) {
        super();
        this.dataSource = this.countryService.getCountries();
    }

    ngOnInit() {
    }

}
