import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarrierProductsAvailabilityComponent } from './carrier-products-availability.component';

export const CARRIER_PRODUCTS_AVAILABILITY_ROUTE: Route = {
    path: 'carrier-products-availability',
    component: CarrierProductsAvailabilityComponent,
    data: {
        roles: [],
        pageTitle: 'carrierProductsAvailability.title',
    },
    canActivate: [EciAuthGuard],
};
