import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../../shared/util/common.service';
import CustomStore from 'devextreme/data/custom_store';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class CarrierPricesService {

    private readonly carrierPricesResourceUrl = 'api/carrier-prices';

    constructor(
        private http: HttpClient,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) {
    }

    getCarrierPrices(): DataSource {
        return this.commonService.getDataSource(this.carrierPricesResourceUrl);
    }

    getCustomCurrencies(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>('/api/carrier-prices/currencies'))
                .then(data => data.map(value => {
                    return { value };
                })),
        });
    }

    getCustomPackageTypes(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>('/api/carrier-prices/package-types'))
                .then(data => data.map(value => {
                    return { value };
                })),
        });
    }
}
