import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommonService } from '../shared/util/common.service';
import { FallBackCarriersService } from './fall-back-carriers.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule,
    ],
    providers: [
        FallBackCarriersService,
        CommonService,
    ],
})
export class FallBackCarriersModule { }
