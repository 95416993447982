<div class="page px-5">

    <div class="page__headline"
         eciTranslate="articleServicesRules.title">
        Article Services Rules
    </div>

    <div class="page__filter mb-2">
        <div class="row">
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                    eciTranslate="articleBrickCodeRules.table.headers.country">Countries</label>
                <dx-tag-box [dataSource]="possibleCountries" (onValueChanged)="onFilterChange()" [(value)]="selectedCountries"
                    [valueExpr]="'id'" [displayExpr]="'name'" [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                    eciTranslate="articleBrickCodeRules.table.headers.warehouse">Warehouses</label>
                <dx-tag-box [dataSource]="possibleWarehouses" (onValueChanged)="onFilterChange()" [(value)]="selectedWarehouses"
                    [valueExpr]="'id'" [displayExpr]="'name'" [searchEnabled]="true">
                </dx-tag-box>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="rowUpdate($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [wordWrapEnabled]="true">

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop">
                </dxo-column-chooser>

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing *eciHasAnyAuthority="Privilege.WriteArticleServicesRules"
                             [allowUpdating]="true"
                             [allowDeleting]="true"
                             [allowAdding]="true"
                             [useIcons]="true"
                             mode="form">

                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="articleNumber"></dxi-item>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="warehouseId"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item dataField="forwardShipment"></dxi-item>
                            <dxi-item dataField="returnShipment"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate" [(value)]="createAnother"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary" role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary" role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true" class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                            eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <dx-button (onClick)="resetFilter()" text="{{ 'shared.buttons.resetFilter' | translate }}">
                    </dx-button>
                </div>

                <dxi-column dataField="id"
                            caption="{{ 'articleServicesRules.table.headers.id' | translate }}"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column dataField="articleNumber"
                            caption="{{ 'articleServicesRules.table.headers.articleNumber' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule
                        type="pattern"
                        [pattern]="articleNumberPattern" message="Article number must be digits only"
                    ></dxi-validation-rule>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            dataField="countryId"
                            sortOrder="asc"
                            calculateSortValue="countryName"
                            caption="{{ 'articleServicesRules.table.headers.country' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countriesStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateWarehouseFilterExpression"
                            dataField="warehouseId"
                            [setCellValue]="resetWarehouseCell"
                            [editorOptions]="{ showClearButton: true }"
                            caption="{{ 'articleServicesRules.table.headers.warehouse' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="warehouseStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierFilterExpression"
                            dataField="carrierId"
                            caption="{{ 'articleServicesRules.table.headers.carrier' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarriers"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'articleServicesRules.table.headers.forwardShipment' | translate"
                            dataField="forwardShipment"
                            dataType="boolean">
                </dxi-column>

                <dxi-column [caption]="'articleServicesRules.table.headers.returnShipment' | translate"
                            dataField="returnShipment"
                            dataType="boolean">
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>
