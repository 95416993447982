<div class="page">

    <div class="page__headline"
         eciTranslate="carrierProducts.title">
        Carrier products
    </div>

    <div class="page__filter mb-2">
        <div class="row">
            <div class="col-lg-3">
                <div class="input-group">
                    <label class="col-form-label me-2">Country:</label>
                    <div class="flex-fill">
                        <dx-tag-box [dataSource]="countriesStore"
                                    [valueExpr]="'id'"
                                    [displayExpr]="'name'"
                                    (onValueChanged)="onFilterChange()"
                                    [(value)]="selectedCountries"
                                    [searchEnabled]="true">
                        </dx-tag-box>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="input-group">
                    <label class="col-form-label me-2">Carrier:</label>
                    <div class="flex-fill">
                        <dx-tag-box [dataSource]="carrierStore"
                                    [valueExpr]="'id'"
                                    [displayExpr]="'name'"
                                    (onValueChanged)="onFilterChange()"
                                    [(value)]="selectedCarriers"
                                    [searchEnabled]="true">
                        </dx-tag-box>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onInitNewRow)="onInitNewRow($event)"
                          (onRowUpdating)="onRowUpdate($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [columnAutoWidth]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: true, paging: true }"
                          [rowAlternationEnabled]="false"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [errorRowEnabled]="false"
                          [filterSyncEnabled]="false"
                          [wordWrapEnabled]="true">
                <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop"></dxo-column-chooser>
                <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
                <dxo-header-filter [visible]="true"
                                   [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true"
                           [allowedPageSizes]="allowedPageSizes" [visible]="true">
                </dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing *eciHasAnyAuthority="Privilege.WriteCarrierProduct"
                             [allowUpdating]="true"
                             [allowDeleting]="true"
                             [allowAdding]="true"
                             [useIcons]="true"
                             mode="form">
                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="zones"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item dataField="name"></dxi-item>
                            <dxi-item dataField="forwardDeliveryOption"></dxi-item>
                            <dxi-item dataField="returnDeliveryOption"></dxi-item>
                            <dxi-item dataField="maxLength"></dxi-item>
                            <dxi-item dataField="maxWidth"></dxi-item>
                            <dxi-item dataField="maxHeight"></dxi-item>
                            <dxi-item dataField="maxGirth"></dxi-item>
                            <dxi-item dataField="girthLength"></dxi-item>
                            <dxi-item dataField="girthWidth"></dxi-item>
                            <dxi-item dataField="girthHeight"></dxi-item>
                            <dxi-item dataField="maxGrossWeight"></dxi-item>
                            <dxi-item dataField="maxVolume"></dxi-item>
                            <dxi-item dataField="carrierSapId"></dxi-item>
                            <dxi-item dataField="kepCarrierId"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item dataField="carrierPudoLocation"></dxi-item>
                            <dxi-item dataField="ageVerification"></dxi-item>
                            <dxi-item dataField="cashOnDelivery"></dxi-item>
                            <dxi-item dataField="forwardShipment"></dxi-item>
                            <dxi-item dataField="returnShipment"></dxi-item>
                            <dxi-item dataField="multiPackage"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother" class="me-3"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary me-2"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary me-2" role="button"
                                            type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>

                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <!-- Visible while in development - can be disabled later-->
                <dxi-column [allowEditing]="false"
                            [allowHeaderFiltering]="false"
                            [caption]="'carrierProducts.table.headers.id' | translate"
                            [visible]="false"
                            dataField="id">
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            [setCellValue]="resetCarrierAndZonesCell"
                            [fixed]="true"
                            [width]="150"
                            sortOrder="asc"
                            calculateSortValue="countryName"
                            [caption]="'carrierProducts.table.headers.destinationCountry' | translate"
                            dataField="countryId">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-header-filter [dataSource]="countriesStore.key">
                    </dxo-header-filter>
                    <dxo-lookup [dataSource]="countriesStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateZoneFilterExpression"
                            [caption]="'carrierProducts.table.headers.zones' | translate"
                            editCellTemplate="zoneTagBoxEditor"
                            cellTemplate="zonesCellTemplate"
                            dataField="zones">
                    <dxo-lookup [dataSource]="getFilteredZones"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'carrierProducts.table.headers.carrier' | translate"
                            [calculateFilterExpression]="calculateCarrierFilterExpression"
                            [fixed]="true"
                            [width]="170"
                            [setCellValue]="resetKepId"
                            calculateSortValue="carrierName"
                            sortOrder="asc"
                            dataField="carrierId">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-header-filter [dataSource]="carrierStore.key"></dxo-header-filter>
                    <dxo-lookup [dataSource]="getFilteredCarriers"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [allowHeaderFiltering]="false"
                            [caption]="'carrierProducts.table.headers.name' | translate"
                            [width]="250"
                            dataField="name">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [caption]="'carrierProducts.table.headers.deliveryOptions' | translate"
                            alignment="center">

                    <dxi-column [caption]="'carrierProducts.table.headers.forwardDeliveryOption' | translate"
                                [editorOptions]="{ showClearButton: true }"
                                [width]="140"
                                dataField="forwardDeliveryOption">
                        <dxi-validation-rule
                            [message]="'carrierProducts.table.validation.forwardDeliveryOptionRequired' | translate"
                            [reevaluate]="true"
                            [validationCallback]="validateForwardDeliveryOption"
                            type="custom">
                        </dxi-validation-rule>
                        <dxo-header-filter [dataSource]="forwardDeliveryOptionsStore"></dxo-header-filter>
                        <dxo-lookup [dataSource]="forwardDeliveryOptionsStore"
                                    displayExpr="text"
                                    valueExpr="value">
                        </dxo-lookup>
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.returnDeliveryOption' | translate"
                                [editorOptions]="{ showClearButton: true }"
                                alignment="right"
                                dataField="returnDeliveryOption">
                        <dxi-validation-rule
                            [message]="'carrierProducts.table.validation.returnDeliveryOptionsRequired' | translate"
                            [reevaluate]="true"
                            [validationCallback]="validateReturnDeliveryOption"
                            type="custom">
                        </dxi-validation-rule>
                        <dxo-header-filter [dataSource]="returnDeliveryOptionsStore"></dxo-header-filter>
                        <dxo-lookup [dataSource]="returnDeliveryOptionsStore"
                                    displayExpr="text"
                                    valueExpr="value">
                        </dxo-lookup>
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.forwardShipment' | translate"
                                dataField="forwardShipment"
                                dataType="boolean">
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.returnShipment' | translate"
                                dataField="returnShipment"
                                dataType="boolean">
                    </dxi-column>
                </dxi-column>

                <dxi-column [caption]="'carrierProducts.table.headers.geoData' | translate"
                            alignment="center">
                    <dxi-column [caption]="'carrierProducts.table.headers.maxLength' | translate"
                                dataField="maxLength"
                                alignment="left"
                                [editorOptions]="{ step: 0 }"
                                [calculateDisplayValue]="millimeterToCentimeter"
                                [allowHeaderFiltering]="false"
                                editCellTemplate="dimensionTemplate"
                                dataType="number">
                        <dxo-header-filter [groupInterval]="500"></dxo-header-filter>
                    </dxi-column>


                    <dxi-column [caption]="'carrierProducts.table.headers.maxWidth' | translate"
                                dataField="maxWidth"
                                [editorOptions]="{ step: 0 }"
                                [calculateDisplayValue]="millimeterToCentimeter"
                                [allowHeaderFiltering]="false"
                                editCellTemplate="dimensionTemplate"
                                dataType="number">
                        <dxo-header-filter [groupInterval]="500"></dxo-header-filter>
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.maxHeight' | translate"
                                dataField="maxHeight"
                                [editorOptions]="{ step: 0 }"
                                [calculateDisplayValue]="millimeterToCentimeter"
                                [allowHeaderFiltering]="false"
                                editCellTemplate="dimensionTemplate"
                                dataType="number">
                        <dxo-header-filter [groupInterval]="500"></dxo-header-filter>
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.maxGirth' | translate"
                                dataField="maxGirth"
                                [editorOptions]="{ step: 0 }"
                                [calculateDisplayValue]="millimeterToCentimeter"
                                [allowHeaderFiltering]="false"
                                editCellTemplate="dimensionTemplate"
                                dataType="number">
                        <dxo-header-filter [groupInterval]="500"></dxo-header-filter>
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.girthLength' | translate"
                                [editorOptions]="{ format: 'fixedPoint', showClearButton: true, step: 0 }"
                                [allowHeaderFiltering]="false"
                                dataField="girthLength"
                                dataType="number">
                        <dxi-validation-rule type="range" min="1" max="2"
                                             message="Girth Length must be between 1 and 2"></dxi-validation-rule>
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.girthWidth' | translate"
                                [editorOptions]="{ format: 'fixedPoint', showClearButton: true, step: 0 }"
                                [allowHeaderFiltering]="false"
                                dataField="girthWidth"
                                dataType="number">
                        <dxi-validation-rule type="range" min="1" max="2"
                                             message="Girth Width must be between 1 and 2"></dxi-validation-rule>
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.girthHeight' | translate"
                                [editorOptions]="{ format: 'fixedPoint', showClearButton: true, step: 0 }"
                                [allowHeaderFiltering]="false"
                                dataField="girthHeight"
                                dataType="number">
                        <dxi-validation-rule type="range" min="1" max="2"
                                             message="Girth Height must be between 1 and 2"></dxi-validation-rule>
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.maxGrossWeight' | translate"
                                [calculateDisplayValue]="gramToKilogram"
                                [allowHeaderFiltering]="false"
                                [editorOptions]="{ step: 0 }"
                                dataField="maxGrossWeight"
                                editCellTemplate="weightTemplate"
                                dataType="number">
                    </dxi-column>

                    <dxi-column [caption]="'carrierProducts.table.headers.maxVolume' | translate"
                                [editorOptions]="{ format: 'fixedPoint', step: 0 }"
                                [allowHeaderFiltering]="false"
                                dataField="maxVolume"
                                dataType="number">
                        <dxo-header-filter [groupInterval]="1000"></dxo-header-filter>
                    </dxi-column>
                </dxi-column>

                <dxi-column [caption]="'carrierProducts.table.headers.carrierPudoLocation' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            dataField="carrierPudoLocation">
                    <dxo-lookup [dataSource]="carrierPudoLocationsStore"
                                displayExpr="text"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'carrierProducts.table.headers.ageVerification' | translate"
                            [width]="100"
                            dataField="ageVerification"
                            dataType="boolean">
                </dxi-column>

                <dxi-column [caption]="'carrierProducts.table.headers.cashOnDelivery' | translate"
                            [width]="100"
                            dataField="cashOnDelivery"
                            dataType="boolean">
                </dxi-column>

                <dxi-column [caption]="'carrierProducts.table.headers.multiPackage' | translate"
                            [width]="100"
                            dataField="multiPackage"
                            dataType="boolean">
                </dxi-column>

                <dxi-column dataField="carrierSapId"
                            [width]="150"
                            caption="{{ 'carrierProducts.table.headers.carrierSapId' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="kepCarrierId"
                            [width]="150"
                            [calculateDisplayValue]="calculateCellValue"
                            caption="{{ 'carrierProducts.table.headers.kepCarrierId' | translate }}">
                    <dxo-lookup [dataSource]="getFilteredKepId"
                                displayExpr="kepId"
                                valueExpr="kepId">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [width]="100"
                            type="buttons">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button cssClass="fas fa-pencil-alt" name="edit"></dxi-button>
                    <dxi-button cssClass="fas fa-trash-alt" name="delete" [onClick]="delete"></dxi-button>
                </dxi-column>
                <div *dxTemplate="let cell of 'dimensionTemplate'">
                    <dx-number-box
                        [value]='(cell.value/10)'
                        (onValueChanged)="cell.setValue($event.value*10)"
                    >
                        </dx-number-box>
                </div>

                <div *dxTemplate="let cell of 'weightTemplate'">
                    <dx-number-box
                        [value]='(cell.value/1000)'
                        (onValueChanged)="cell.setValue($event.value*1000)"
                    >
                    </dx-number-box>
                </div>

                <div *dxTemplate="let cellInfo of 'zoneTagBoxEditor'">
                    <dx-tag-box
                        [placeholder]="'carrierProducts.table.headers.zones' | translate"
                        [dataSource]="filteredZones"
                        [showSelectionControls]="true"
                        [value]="cellInfo.value"
                        [valueExpr]="'id'"
                        [displayExpr]="'name'"
                        (onValueChanged)="cellInfo.setValue($event.value)"
                        applyValueMode="useButtons"
                        [searchEnabled]="true">
                    </dx-tag-box>
                </div>

                <div *dxTemplate="let cellInfo of 'zonesCellTemplate'">
                    <ul style="list-style-type: none;margin: 0">
                        <li *ngFor="let item of cellInfo.data['zones']">
                            <h5 style="margin: 0;font-size: 15px"><span class="badge rounded-pill bg-secondary">{{ getZoneName(item) }}</span></h5>
                        </li>
                    </ul>
                </div>

            </dx-data-grid>
        </div>

    </div>

</div>
