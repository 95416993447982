import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { PackagingMaterialsComponent } from './packaging-materials.component';

export const PACKAGING_MATERIAL_ROUTE: Route = {
    path: 'packaging-materials',
    component: PackagingMaterialsComponent,
    data: {
        roles: [],
        pageTitle: 'packagingMaterials.title',
    },
    canActivate: [EciAuthGuard],
};
