import { CommonView } from '../../shared/util/common-view.util';
import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { PackageMaterialDefinitionService } from './package-material-definitions.service';
import { DxRowUpdateEvent, DxUtil } from '../../shared/util/dx.util';
import { PackageMaterialDefinition } from '../../shared/models/package-material-definition.model';
import { Privilege } from '../../shared/models/privilege.enum';

@Component({
    selector: 'kep-package-material-definitions',
    templateUrl: './package-material-definitions.component.html',
})
export class PackageMaterialDefinitionsComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    dataSource: DataSource;

    constructor(
        private packageMaterialDefinitionService: PackageMaterialDefinitionService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.packageMaterialDefinitionService.getPackageMaterialDefinitions();
    }

    onRowUpdate(e: DxRowUpdateEvent<PackageMaterialDefinition>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }
}
