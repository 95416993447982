import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EciDxService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../shared/util/common.service';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { firstValueFrom } from 'rxjs';
import { DxUtil } from '../shared/util/dx.util';

@Injectable()
export class CarrierSurchargeRulesService {

    private readonly resourceUrl = 'api/carrier-surcharge-rules';

    constructor(
        private http: HttpClient,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) {
    }

    getCarrierSurchargeRules(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getCarrierSurchargeRulesAsCustomStore(): CustomStore {
        return new CustomStore({
            key: 'id',
            load: loadOptions => this.dxService.query(this.resourceUrl, DxUtil.loadAll(loadOptions))
                .then(x => x.data),
            byKey: id => firstValueFrom(this.http.get(`${this.resourceUrl}/${id}`)),
        });
    }

    getSurchargeTypes(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>(`${this.resourceUrl}/types`))
                .then(data => data.map(value => {
                    return { value };
                })),
        });
    }

}
