import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { CommonService } from '../../shared/util/common.service';

@Injectable()
export class SurchargeOverviewService {

    private readonly parcelCostResourceUrl = 'api/surcharge-overview';

    constructor(
        private commonService: CommonService,
    ) {
    }

    getParcelCosts(): DataSource {
        return this.commonService.getDataSource(this.parcelCostResourceUrl);
    }

}
