import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { ArticleRulesComponent } from './article-rules.component';

export const ARTICLE_RULES_ROUTE: Route = {
    path: 'article-rules',
    component: ArticleRulesComponent,
    data: {
        roles: [],
        pageTitle: 'articleRules.title',
    },
    canActivate: [EciAuthGuard],
};
