import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';

@Injectable()
export class PackageMaterialDefinitionService {

    private readonly resourceUrl = 'api/package-material-definitions';

    constructor(
        private dxService: EciDxService,
    ) { }

    getPackageMaterialDefinitions(): DataSource {
        return this.dxService.createEditableDataSource(this.resourceUrl);
    }

}
