import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommonService } from '../shared/util/common.service';
import { DeliveryOptionsRecalculationService } from './delivery-options-recalculation/delivery-options-recalculation.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
      DeliveryOptionsRecalculationService,
      CommonService,
  ],
})
export class DeliveryOptionsModule { }
