import { CronSchedule, CustomCronSchedule, DailyCronSchedule, HourlyCronSchedule, MinutelyCronSchedule, MonthlyCronSchedule, WeeklyCronSchedule } from './models/cron-schedule';

export const $as = {
    minutely: (cronSchedule: CronSchedule) => cronSchedule as MinutelyCronSchedule,
    hourly: (cronSchedule: CronSchedule) => cronSchedule as HourlyCronSchedule,
    daily: (cronSchedule: CronSchedule) => cronSchedule as DailyCronSchedule,
    weekly: (cronSchedule: CronSchedule) => cronSchedule as WeeklyCronSchedule,
    monthly: (cronSchedule: CronSchedule) => cronSchedule as MonthlyCronSchedule,
    custom: (cronSchedule: CronSchedule) => cronSchedule as CustomCronSchedule,
};
