import { Route } from '@angular/router';

import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { CarrierProductDistributionTimesComponent } from './carrier-product-distribution-times.component';

export const CARRIER_PRODUCT_DISTRIBUTION_TIMES_ROUTE: Route = {
    path: 'carrier-product-distribution-times',
    component: CarrierProductDistributionTimesComponent,
    data: {
        roles: [],
        pageTitle: 'carrierProductDistributionTimes.title',
    },
    canActivate: [EciAuthGuard],
};
