import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { CarriersService } from '../carriers/carriers.service';
import { CarrierProductDistributionTimesService } from './carrier-product-distribution-times.service';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../shared/util/dx.util';
import { CarrierProduct } from '../shared/models/carrier-product.model';
import { Privilege } from '../shared/models/privilege.enum';
import { CountriesService } from '../countries/countries.service';
import { CarrierProductsService } from '../carrier-products/carrier-products.service';
import { WarehouseService } from '../warehouse/warehouse.service';
import { Country } from '../shared/models/country.model';
import { Carrier } from '../shared/models/carrier.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { CarrierProductDistributionTimeModel } from '../shared/models/carrier-product-distribution-time.model';
import { CommonView } from '../shared/util/common-view.util';
import { PostCodeListsService } from '../postcodelists/post-code-lists.service';
import { Warehouse } from '../shared/models/warehouse.model';

@Component({
    selector: 'kep-carrier-product-distribution-time',
    templateUrl: './carrier-product-distribution-times.component.html',
})
export class CarrierProductDistributionTimesComponent extends CommonView implements OnInit {

    Privilege = Privilege;

    dataSource: DataSource;

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    carrierStore: CustomStore;
    carrierProductsStore: CustomStore;
    warehouseStore: CustomStore;
    countriesStore: CustomStore;
    postCodeListStore: CustomStore;

    selectedCountries = [];
    selectedWarehouses = [];
    selectedCarriers = [];

    resetCarrierProductPostCodeListCell = DxUtil.hookSetCellValue<CarrierProductDistributionTimeModel>(rowData => {
        rowData.postCodeListId = null;
        rowData.carrierProductId = null;
    });

    resetCarrierCarrierProductPostCodeListCell = DxUtil.hookSetCellValue<CarrierProductDistributionTimeModel>(rowData => {
        rowData.carrierId = null;
        rowData.postCodeListId = null;
        rowData.carrierProductId = null;
    });

    resetPostCodeListCell = DxUtil.hookSetCellValue<CarrierProductDistributionTimeModel>(rowData => {
        rowData.postCodeListId = null;
    });

    constructor(
        private carrierProductDistributionTimesService: CarrierProductDistributionTimesService,
        private carrierService: CarriersService,
        private carrierProductsService: CarrierProductsService,
        private warehouseService: WarehouseService,
        private countriesService: CountriesService,
        private postCodeListService: PostCodeListsService,
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource = this.carrierProductDistributionTimesService.getDistributionTimes();

        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.carrierProductsStore = this.carrierProductsService.getCarrierProductsAsCustomStore();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.countriesStore = this.countriesService.getCountriesAsCustomStore();
        this.postCodeListStore = this.postCodeListService.getPostcodeListsAsCustomStore();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierProduct>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    getFilteredCarrierProducts = (options: DxLookupDataSourceOptions<CarrierProductDistributionTimeModel>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierProductDistributionTimeModel, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<CarrierProductDistributionTimeModel, Carrier>(options, 'carrierId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    getFilteredCarriers = (options: DxLookupDataSourceOptions<CarrierProductDistributionTimeModel>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierProductDistributionTimeModel, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    getFilteredPostCodeLists = (options: DxLookupDataSourceOptions<CarrierProductDistributionTimeModel>) => {
        if (options.data == null) {
            return { store: this.postCodeListStore };
        }

        const country = DxUtil.fetchLookupObject<CarrierProductDistributionTimeModel, Country>(options, 'countryId');
        const carrier = DxUtil.fetchLookupObject<CarrierProductDistributionTimeModel, Carrier>(options, 'carrierId');
        const warehouse = DxUtil.fetchLookupObject<CarrierProductDistributionTimeModel, Warehouse>(options, 'warehouseId');

        const filter = DxFilter.and(
            DxFilter.equals('country.id', country ? country.id : null),
            DxFilter.or(
                carrier ? DxFilter.equals('carrier.id', carrier.id) : DxFilter.isNull('carrier.id'),
                DxFilter.isNull('carrier.id'),
            ),
            DxFilter.or(
                warehouse ? DxFilter.equals('warehouse.id', warehouse.id) : DxFilter.isNull('warehouse.id'),
                DxFilter.isNull('warehouse.id'),
            ),
        );

        return {
            store: this.postCodeListStore,
            filter,
        };
    };

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.id', filterOperation, filterValue];
    }

    calculatePostCodeListFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierPostCodeList.id', filterOperation, filterValue];
    }

    resetFilter(): void {
        super.resetFilter();

        this.selectedCountries = [];
        this.selectedWarehouses = [];
        this.selectedCarriers = [];
    }

    onFilterChange() {
        this.dataGrid.instance.clearFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('warehouseId', 'filterValues', this.selectedWarehouses);
        this.dataGrid.instance.columnOption('carrierId', 'filterValues', this.selectedCarriers);
    }
}
