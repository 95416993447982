import { Injectable } from '@angular/core';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../shared/util/common.service';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class CarrierProductsAvailabilityService {

    private readonly resourceUrl = 'api/carrier-product-availability';

    constructor(
        private dxService: EciDxService,
        private commonService: CommonService,
    ) { }

    getCarrierProductsAvailability(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

}
