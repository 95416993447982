<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline"
         eciTranslate="cpt.title">
        CPT
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="rowUpdate($event)"
                          (onRowInserting)="rowInsert($event)"
                          (onInitNewRow)="initNewRow($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [wordWrapEnabled]="true">

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop"></dxo-column-chooser>
                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing *eciHasAnyAuthority="Privilege.WriteCpt"
                             [allowUpdating]="true"
                             [allowDeleting]="true"
                             [allowAdding]="true"
                             [useIcons]="true"
                             mode="form">

                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="warehouseId"></dxi-item>
                            <dxi-item dataField="countryId"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item dataField="carrierProductId"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item dataField="cptMonday"></dxi-item>
                            <dxi-item dataField="cptTuesday"></dxi-item>
                            <dxi-item dataField="cptWednesday"></dxi-item>
                            <dxi-item dataField="cptThursday"></dxi-item>
                            <dxi-item dataField="cptFriday"></dxi-item>
                            <dxi-item dataField="cptSaturday"></dxi-item>
                            <dxi-item dataField="cptSunday"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item dataField="comment"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate" [(value)]="createAnother"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary" role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary" role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true" class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                            eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            [caption]="'cpt.table.headers.id' | translate"
                            [allowEditing]="false"
                            [allowHeaderFiltering]="false"
                            sortOrder="asc"
                            [visible]="false">
                </dxi-column>

                <dxi-column dataField="warehouseId"
                            [caption]="'cpt.table.headers.warehouse' | translate"
                            [calculateFilterExpression]="calculateWarehouseFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="warehouseStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="countryId"
                            sortOrder="asc"
                            calculateSortValue="countryName"
                            [setCellValue]="resetCarrierProductCell"
                            [caption]="'cpt.table.headers.country' | translate"
                            [calculateFilterExpression]="calculateCountryFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="countryStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="carrierId"
                            [setCellValue]="resetCarrierProductCell"
                            [caption]="'cpt.table.headers.carrier' | translate"
                            [calculateFilterExpression]="calculateCarrierFilterExpression">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarriers"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="carrierProductId"
                            [caption]="'cpt.table.headers.carrierProduct' | translate"
                            [setCellValue]="resetCarrierProductCell"
                            [editorOptions]="{ format: 'fixedPoint', showClearButton: true }"
                            [calculateFilterExpression]="calculateCarrierProductFilterExpression">
                    <dxo-lookup [dataSource]="getFilteredCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="cptMonday" editCellTemplate="timeTemplate" dataType="time"
                            [caption]="'cpt.table.headers.cptMonday' | translate">
                </dxi-column>

                <dxi-column dataField="cptTuesday" editCellTemplate="timeTemplate" dataType="time"
                            [caption]="'cpt.table.headers.cptTuesday' | translate">
                </dxi-column>

                <dxi-column dataField="cptWednesday" editCellTemplate="timeTemplate" dataType="time"
                            [caption]="'cpt.table.headers.cptWednesday' | translate">
                </dxi-column>

                <dxi-column dataField="cptThursday" editCellTemplate="timeTemplate" dataType="time"
                            [caption]="'cpt.table.headers.cptThursday' | translate">
                </dxi-column>

                <dxi-column dataField="cptFriday" editCellTemplate="timeTemplate" dataType="time"
                            [caption]="'cpt.table.headers.cptFriday' | translate">
                </dxi-column>

                <dxi-column dataField="cptSaturday" editCellTemplate="timeTemplate" dataType="time"
                            [caption]="'cpt.table.headers.cptSaturday' | translate">
                </dxi-column>

                <dxi-column dataField="cptSunday" editCellTemplate="timeTemplate" dataType="time"
                            [caption]="'cpt.table.headers.cptSunday' | translate">
                </dxi-column>

                <div *dxTemplate="let cell of 'timeTemplate'">
                    <dx-date-box
                            [(value)]=cell.value
                            type="time"
                            displayFormat="HH:mm:ss"
                            pickerType="native"
                            (onValueChanged)="cell.setValue($event.value)">
                    </dx-date-box>
                </div>

                <dxi-column dataField="comment"
                            [caption]="'cpt.table.headers.comment' | translate">
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>
