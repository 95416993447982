import { CommonView } from '../../shared/util/common-view.util';
import { Component } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { BackupShippingTypeAddressesService } from './backup-shipping-type-addresses.service';
import { CarriersService } from '../../carriers/carriers.service';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../../shared/util/dx.util';
import { CountriesService } from '../../countries/countries.service';
import { WarehouseService } from '../../warehouse/warehouse.service';
import { CarrierProductsService } from '../../carrier-products/carrier-products.service';
import { CarrierProduct } from '../../shared/models/carrier-product.model';
import { Carrier } from '../../shared/models/carrier.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { Privilege } from 'src/app/shared/models/privilege.enum';
import { BackupShippingTypeAddress } from '../../shared/models/backup-shipping-type-address.model';

@Component({
    selector: 'kep-backup-shipping-type-addresses',
    templateUrl: './backup-shipping-type-addresses.component.html',
})
export class BackupShippingTypeAddressesComponent extends CommonView {

    Privilege = Privilege;

    readonly allowedPageSizes = [10, 20, 50, 75, 100];

    dataSource: DataSource;

    carrierStore: CustomStore;
    carrierProductsStore: CustomStore;
    warehouseStore: CustomStore;

    resetOriginalCarrierProductCell = DxUtil.hookSetCellValue<BackupShippingTypeAddress>(rowData => {
        rowData.originalCarrierProductId = null;
    });

    resetBackupCarrierProductCell = DxUtil.hookSetCellValue<BackupShippingTypeAddress>(rowData => {
        rowData.backupCarrierProductId = null;
    });

    resetWarehouseCell = DxUtil.hookSetCellValue<BackupShippingTypeAddress>(rowData => {
        rowData.warehouseId = null;
    });

    constructor(
        private backupShippingTypeAddressesService: BackupShippingTypeAddressesService,
        private countryService: CountriesService,
        private warehouseService: WarehouseService,
        private carriersService: CarriersService,
        private carrierProductsService: CarrierProductsService,
    ) {
        super();
        this.dataSource = this.backupShippingTypeAddressesService.createDataSource();
        this.warehouseStore = this.warehouseService.getWarehouseAsCustomStore();
        this.carrierStore = this.carriersService.getCarriersAsCustomStore();
        this.carrierProductsStore = this.carrierProductsService.getCarrierProductsAsCustomStore();
    }

    initNewRow(e) {
        e.data = this.currentRowData;
    }

    onRowUpdate(e: DxRowUpdateEvent<CarrierProduct>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    getFilteredOriginalCarrierProducts = (options: DxLookupDataSourceOptions<BackupShippingTypeAddress>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const carrier = DxUtil.fetchLookupObject<BackupShippingTypeAddress, Carrier>(options, 'originalCarrierId');

        const filter = DxFilter.and(
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    getFilteredBackupCarrierProducts = (options: DxLookupDataSourceOptions<BackupShippingTypeAddress>) => {
        if (options.data == null) {
            return { store: this.carrierProductsStore };
        }

        const carrier = DxUtil.fetchLookupObject<BackupShippingTypeAddress, Carrier>(options, 'backupCarrierId');

        const filter = DxFilter.and(
            DxFilter.equals('carrier.id', carrier ? carrier.id : null),
        );

        return {
            store: this.carrierProductsStore,
            filter,
        };
    };

    calculateWarehouseFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['warehouse.id', filterOperation, filterValue];
    }

    calculateOriginalCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateOriginalCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.carrier.id', filterOperation, filterValue];
    }

    calculateBackupCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateBackupCarrierProductFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrierProduct.carrier.id', filterOperation, filterValue];
    }
}
